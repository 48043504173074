.tab_menu {
  margin-bottom: 2.3rem;
  padding-right: 2rem;
}
.tab_menu ul {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  flex-wrap: wrap;
}
.tab_menu ul li {
  min-width: 7.2rem;
  padding: 0 10px;
  height: 4rem;
  line-height: 3.8rem;
  border-radius: 2.2rem;
  border: 1px solid #dedede;
  text-align: center;
  font-weight: 500;
  color: #999;
  cursor: pointer;
}
.tab_menu ul li.on {
  background: #3e21d9;
  border-color: #3e21d9;
  color: #fff;
  font-weight: bold;
}
.tab_menu ul.align_left {
  justify-content: flex-start;
}
.tab_menu ul.border_st li.on {
  border-color: #3e21d9;
  background: #fff;
  color: #3e21d9;
  font-weight: bold;
}
@media (max-width: 959px) {
  .tab_menu {
    margin-bottom: 1.6rem;
  }
  .tab_menu ul li {
    height: 2.5rem;
    line-height: 2.3rem;
    font-size: 1.4rem;
    min-width: 5rem;
  }
}