.kegel_container {
  position: relative;
  width: 100%;
  min-width: initial;
  min-height: calc(100vh - 80px);
  margin: 0 auto;
  padding-top: 14.3rem;
}
.kegel_container .flex_row {
  display: flex;
  align-items: center;
  gap: 2rem;
}
.kegel_container .desc_s {
  font-size: 1.4rem;
  color: #999;
  line-height: 2.2rem;
}
.kegel_container .desc_list {
  font-size: 1.4rem;
  line-height: 2.2rem;
}
.kegel_container .desc_list li {
  white-space: normal;
  padding-left: 8px;
  position: relative;
}
.kegel_container .desc_list li::before {
  content: "";
  display: block;
  width: 3px;
  height: 3px;
  background: #000;
  border-radius: 50%;
  margin-right: 5px;
  position: absolute;
  top: 9px;
  left: 0;
}
.kegel_container .desc_list.gray {
  color: #999;
}
.kegel_container .desc_list.gray li::before {
  background: #999;
}
.kegel_container .desc_list.footer_st {
  margin-top: 3rem;
}
.kegel_container .desc_list.footer_st b {
  color: #000;
  display: block;
  margin-bottom: 1rem;
}
.kegel_container .info_title {
  margin-bottom: 2rem;
  line-height: 2.2rem;
}
.kegel_container .detail_table_wrap table tr {
  cursor: default;
}
.kegel_container .detail_table_wrap table tr th,
.kegel_container .detail_table_wrap table tr td {
  overflow: initial;
  text-overflow: initial;
  padding: 5px 20px;
}
.kegel_container .detail_table_wrap table tr th {
  padding: 2rem 0 0 2rem;
}
.kegel_container .table_img_box {
  width: 27rem;
  height: 16.8rem;
}
.kegel_container .table_img_box img {
  width: 100%;
  height: 100%;
}
.kegel_container .btn_bottom_wrap {
  margin-top: 3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}
.kegel_container .btn_bottom_wrap .right {
  display: flex;
  gap: 1rem;
  margin-left: auto;
}
.kegel_container .thumbnail_box {
  display: flex;
  align-items: flex-end;
  gap: 1rem;
  margin-bottom: 1rem;
}
.kegel_container .thumbnail_box img {
  width: 14rem;
  height: 8.5rem;
  -o-object-fit: cover;
     object-fit: cover;
}
.kegel_container .kegel_sub_nav {
  padding: 0.8rem 1.6rem 0;
  border-bottom: 1px solid #dedede;
  position: fixed;
  top: 4.3rem;
  left: 0;
  background: #fff;
  z-index: 10;
  width: 100%;
}
.kegel_container .kegel_sub_nav h3 {
  margin: 2rem 0 0.4rem;
  font-size: 2rem;
}
.kegel_container .kegel_sub_nav .depth_1_link {
  height: 4.2rem;
}
.kegel_container .kegel_sub_nav .depth_1_link a {
  padding-top: 1.6rem;
  padding: 16px 4px 0;
  margin-right: 1.2rem;
  height: 100%;
  display: block;
  color: #999;
}
.kegel_container .kegel_sub_nav .depth_1_link :global .active {
  color: #000;
  font-weight: bold;
  border-bottom: 2px solid #000;
}
.kegel_container .kegel_sub_nav .depth_1 {
  position: relative;
  height: 4.2rem;
}
.kegel_container .kegel_sub_nav .depth_1 a {
  padding-top: 1.6rem;
  padding: 16px 4px 0;
  margin-right: 1.2rem;
  height: 100%;
  display: block;
  color: #999;
}
.kegel_container .kegel_sub_nav .depth_1 .on {
  font-weight: bold;
  color: #000;
  border-bottom: 2px solid #000;
}
.kegel_container .kegel_sub_nav .depth_1 :global .active {
  font-weight: bold;
  color: #000;
  border-bottom: 2px solid #000;
}
.kegel_container .kegel_sub_nav .depth_2 {
  display: none;
  z-index: 1000;
  position: absolute;
  width: 100%;
  top: 9.9rem;
  left: 0;
  background: #f7f9fa;
  height: 50px;
  overflow: hidden;
  transition: all 0.1s;
  border-bottom: 1px solid #dedede;
}
.kegel_container .kegel_sub_nav .depth_2::after {
  content: "";
  display: block;
  position: absolute;
  width: 10px;
  height: 10px;
  top: 2rem;
  right: 2rem;
  background: url(../../assets/images/icon_dropdown_arrow.svg) no-repeat center;
  transition: all 0.1s;
}
.kegel_container .kegel_sub_nav .depth_2 li a {
  padding: 0 2rem;
  height: 5rem;
  line-height: 5rem;
  display: block;
  width: 100%;
  height: 100%;
}
.kegel_container .kegel_sub_nav .depth_2.on {
  display: block;
}
.kegel_container .kegel_sub_nav .depth_2.open {
  height: 150px;
}
.kegel_container .kegel_sub_nav .depth_2.open::after {
  transform: rotate(180deg);
}
@media (max-width: 530px) {
  .kegel_container .kegel_sub_nav :global .header_sub_nav {
    width: 50rem;
  }
}
.kegel_container .kegel_sub_nav :global .slick-track {
  margin-left: 0;
  margin-right: 0;
}
.kegel_container section .kegel_contents {
  width: 100%;
  height: 100%;
  position: relative;
}
.kegel_container .contents_wrap {
  width: 100%;
  padding: 0 0 15rem 0;
}
.kegel_container .contents_wrap .contents_title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 2rem;
}
.kegel_container .contents_wrap .contents_title h3 {
  font-size: 2.4rem;
}
.kegel_container .contents_wrap .contents_title .search_wrap {
  width: 100%;
  position: relative;
}
.kegel_container .contents_wrap .contents_title .search_wrap input {
  height: 5rem;
  padding: 0 5rem 0 2rem;
  background: #f7f9fa;
  background-position: center right 24px;
  border-top: 0;
  border-left: 0;
  border-right: 0;
}
.kegel_container .contents_wrap .contents_title .search_wrap button {
  position: absolute;
  top: 12px;
  right: 24px;
}
.kegel_container .contents_wrap .contents_title .select_wrap {
  width: 100%;
  position: relative;
}
.kegel_container .contents_wrap .contents_title .select_wrap > div::after {
  top: 2rem;
}
.kegel_container .contents_wrap .contents_title .select_wrap p {
  height: 5rem;
  line-height: 5rem;
  padding: 0 5rem 0 2rem;
  background: #f7f9fa;
  background-position: center right 24px;
  border: none !important;
}
.kegel_container .contents_wrap .contents_title .select_wrap ul {
  top: 4.9rem;
}
.kegel_container .contents_wrap .contents_title .select_wrap ul li {
  height: 5rem;
  line-height: 5rem;
  padding: 0 2rem;
  background: #f7f9fa;
}
.kegel_container .contents_wrap .contents_title .desc {
  width: 100%;
  margin-top: 1rem;
}
.kegel_container .contents_wrap.depth_2_st {
  padding-top: 5rem;
}
.kegel_container .contents_wrap .card_board {
  width: 100%;
  padding: 0 1.6rem;
}
.kegel_container .contents_wrap .card_board .card {
  width: 100%;
  margin-bottom: 2rem;
  cursor: pointer;
}
.kegel_container .contents_wrap .card_board .card .img_box {
  width: 100%;
  height: 20rem;
}
.kegel_container .contents_wrap .card_board .card .img_box img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.kegel_container .contents_wrap .card_board .card .text_box {
  padding-top: 1.2rem;
}
.kegel_container .contents_wrap .card_board .card .text_box h5 {
  font-size: 1.2rem;
  color: #e60013;
  font-weight: 600;
  margin-bottom: 4px;
}
.kegel_container .contents_wrap .card_board .card .text_box h4 {
  font-size: 1.8rem;
  margin-bottom: 8px;
}
.kegel_container .contents_wrap .contents_detail .contents_title {
  margin-bottom: 0;
}
.kegel_container .contents_wrap .contents_detail .contents_title .img_box {
  width: 100%;
  height: 100%;
  aspect-ratio: 1/1;
}
.kegel_container .contents_wrap .contents_detail .contents_title .img_box img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.kegel_container .contents_wrap .contents_detail .contents_title .contents_info {
  width: 100%;
  height: 100%;
  padding: 2rem 1.6rem;
}
.kegel_container .contents_wrap .contents_detail .contents_title .contents_info .title {
  width: 100%;
  border-top: 1px solid #000;
  border-bottom: 1px solid #dedede;
  padding: 2rem 0.2rem;
}
.kegel_container .contents_wrap .contents_detail .contents_title .contents_info .title .en {
  font-size: 1.2rem;
  color: #e60013;
  font-weight: 600;
  margin-bottom: 4px;
}
.kegel_container .contents_wrap .contents_detail .contents_title .contents_info .title h3 {
  font-size: 2.4rem;
}
.kegel_container .contents_wrap .contents_detail .contents_title .contents_info .contents {
  padding: 2rem 0 0;
  line-height: 2.6rem;
  width: 100%;
}
.kegel_container .contents_wrap .contents_detail .contents_title .contents_info .contents li {
  padding-left: 7px;
  position: relative;
}
.kegel_container .contents_wrap .contents_detail .contents_title .contents_info .contents li::before {
  content: "";
  display: block;
  width: 2px;
  height: 2px;
  border-radius: 50%;
  background: #000;
  position: absolute;
  top: 1.1rem;
  left: 0;
}
.kegel_container .contents_wrap .contents_detail .detail_box {
  width: 100%;
  text-align: center;
  padding: 0 1.6rem;
}
.kegel_container .contents_wrap .contents_detail .btn_wrap {
  text-align: center;
  margin-top: 2rem;
}
.kegel_container .contents_wrap .view_content {
  padding: 0 1.6rem;
}
.kegel_container .contents_wrap .view_content .title {
  border-bottom: 1px solid #000;
  padding: 2rem 0.4rem;
}
.kegel_container .contents_wrap .view_content .title h4 {
  font-size: 1.6rem;
  font-weight: 600;
  margin-bottom: 4px;
}
.kegel_container .contents_wrap .view_content .title .date {
  font-size: 1.2rem;
  color: #999;
  font-weight: 500;
}
.kegel_container .contents_wrap .view_content .content {
  padding: 2.4rem 0.4rem;
  border-bottom: 1px solid #dedede;
}
.kegel_container .contents_wrap .view_content .content .text_box {
  padding-top: 3rem;
}
.kegel_container .contents_wrap .view_content .list_wrap {
  margin-bottom: 3rem;
}
.kegel_container .contents_wrap .view_content .list_wrap li {
  border-bottom: 1px solid #dedede;
  min-height: 6.9rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1.6rem 1.6rem 1.6rem 8.2rem;
  cursor: pointer;
  position: relative;
}
.kegel_container .contents_wrap .view_content .list_wrap li i {
  width: 6rem;
  background: url(../../assets/images/icon_dropdown_arrow.svg) no-repeat center right;
  position: absolute;
  top: 50%;
  left: 4px;
  transform: translateY(-50%);
}
.kegel_container .contents_wrap .view_content .list_wrap li span {
  width: 100%;
  margin-bottom: 4px;
  font-weight: 500;
}
.kegel_container .contents_wrap .view_content .list_wrap li em {
  font-size: 1.2rem;
  font-weight: 500;
  color: #999;
}
.kegel_container .contents_wrap .view_content .list_wrap li.prev i {
  background: url(../../assets/images/icon_dropdown_close.svg) no-repeat center right;
}
.kegel_container .contents_wrap .view_content .btn_wrap {
  text-align: right;
}
.kegel_container .contents_wrap .gallery_board {
  padding: 0 1.6rem 2.4rem;
}
.kegel_container .contents_wrap .gallery_board .box {
  width: 100%;
  cursor: pointer;
  margin-top: 2rem;
}
.kegel_container .contents_wrap .gallery_board .box .thumbnail {
  width: 100%;
  height: 20rem;
}
.kegel_container .contents_wrap .gallery_board .box .thumbnail img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.kegel_container .contents_wrap .gallery_board .box p {
  margin-top: 1.2rem;
  font-size: 1.6rem;
  font-weight: 600;
}
.kegel_container .contents_wrap .bottom_center_btn {
  text-align: center;
  margin-top: 2rem;
  padding: 0 1.6rem;
}
.kegel_container .contents_wrap .bottom_center_btn button {
  width: 100%;
  text-align: center;
  background-position: 52vw;
  padding: 0;
  padding-right: 3rem;
}
.kegel_container .mobile_table_wrap table {
  border-top: none;
}
.kegel_container .mobile_table_wrap table thead,
.kegel_container .mobile_table_wrap table colgroup {
  display: none;
}
.kegel_container .mobile_table_wrap table tr {
  border-bottom: 1px solid #dedede;
  display: block;
  padding: 2rem;
  position: relative;
}
.kegel_container .mobile_table_wrap table td {
  display: block;
  border-bottom: none;
  padding: 0;
  padding-left: 3rem;
  height: initial;
  line-height: 1.8rem;
}
.kegel_container .mobile_table_wrap table td:first-child {
  position: absolute;
  padding-left: 0rem;
  left: 2rem;
}
.kegel_container .mobile_table_wrap table td:nth-child(2) {
  font-size: 1.6rem;
  font-weight: 500;
  text-overflow: initial;
  overflow: initial;
  white-space: wrap;
  margin-bottom: 8px;
}
.kegel_container .mobile_table_wrap table td:nth-child(3) {
  font-size: 1.2rem;
  color: #999;
}
.kegel_container .mobile_table_wrap .bottom_center_btn {
  padding: 0 1.6rem;
}
.kegel_container .empty_data {
  padding: 13rem 0 0.5rem 0;
  text-align: center;
}
.kegel_container .empty_data p {
  padding: 11rem 0 1.6rem;
  font-size: 1.6rem;
  font-weight: bold;
  background: url(../../assets/images/ed_icon_07.svg) no-repeat center top;
}
.kegel_container .empty_data.event_st p {
  background: url(../../assets/images/ed_icon_08.svg) no-repeat center top;
}
.kegel_container .empty_data.cs_st p {
  background: url(../../assets/images/ed_icon_09.svg) no-repeat center top;
}
.kegel_container .empty_data.as_st p {
  background: url(../../assets/images/ed_icon_10.svg) no-repeat center top;
}
.kegel_container .tab_menu {
  width: 100%;
  display: flex;
  margin-bottom: 2rem;
}
.kegel_container .tab_menu li {
  width: 50%;
  height: 5rem;
  line-height: 5rem;
  background: #f7f9fa;
  color: #999;
  font-weight: 500;
  text-align: center;
  cursor: pointer;
}
.kegel_container .tab_menu li.on {
  background: #e60013;
  color: #fff;
  font-weight: bold;
}