.view_location_wrap .map_wrap {
  width: 100%;
  height: 34rem;
  background: rgba(0, 0, 0, 0.3);
}
.view_location_wrap .info_wrap h3 {
  margin: 3rem 0 1rem;
  text-align: left !important;
}
@media (max-width: 960px) {
  .view_location_wrap .info_wrap table colgroup {
    display: none;
  }
  .view_location_wrap .info_wrap table tbody tr {
    display: block;
    padding: 8px 0;
    border-bottom: 1px solid #dedede;
  }
  .view_location_wrap .info_wrap table tbody th,
  .view_location_wrap .info_wrap table tbody td {
    width: 100%;
    display: block;
    height: initial !important;
    line-height: 2.2rem;
    padding: 0 2rem;
    border-bottom: 0;
    font-size: 1.4rem;
    font-weight: 500;
    text-overflow: initial;
    overflow: initial;
    white-space: initial;
  }
  .view_location_wrap .info_wrap table tbody th {
    font-size: 1.2rem;
    color: #999;
    margin-bottom: 4px;
  }
}