.control {
    min-width: 8.5rem;
    position: relative;
}
.control input {
    height: 3.9rem;
    width: 100%;
    padding: 0 1.5rem;
    border: 1px solid #dedede;
    font-size: 1.6rem;
}
.control input:focus {
    outline: none;
}
.control input::-moz-placeholder {
    color: #999;
}
.control input::placeholder {
    color: #999;
}
.control input:-moz-read-only {
    color: #999;
    background: #f7f9fa;
    border-color: #dedede;
}
.control input:read-only {
    color: #999;
    background: #f7f9fa;
    border-color: #dedede;
}
.control.invalid input {
    border-color: red;
    background: #fbdada;
}
.control.sm {
    width: 8.5rem;
}
.control.h35 input {
    height: 3.5rem;
}
.control.h45 input {
    height: 4.5rem;
}
.control.xs {
    min-width: 2.7rem;
    width: 2.7rem;
    height: 2.7rem !important;
}
.control.xs input {
    height: 2.7rem !important;
    padding: 0;
    text-align: center;
    font-size: 1.4rem;
}
.control.search_input input {
    height: 6.5rem;
    width: 100%;
    background: #f7f9fa;
    border-color: #dedede;
    border-radius: 3rem;
    padding: 0 2.5rem;
    padding-right: 6rem;
}
.control.search_input_sm input {
    background: url(../../../assets/images/icon_search.svg) no-repeat;
    background-position: top 5px right 15px;
    padding-right: 4rem;
}
.control.admin_st input {
    height: 4.5rem;
}
.control.admin_st_mid {
    width: 77rem;
}
.control.w_50 {
    width: 50%;
}
.control.admin_st_sm {
    position: relative;
    width: 24rem;
}
.control.admin_st_sm input {
    height: 4.5rem;
    padding-right: 3rem;
}
.control.admin_st_sm label {
    position: absolute;
    right: 1.5rem;
    top: 1rem;
}
.control.admin_login {
    display: flex;
    flex-direction: column-reverse;
}
.control.text_center input {
    text-align: center;
}
@media (max-width: 959px) {
    .control input {
        font-size: 1.4rem;
    }
    .control.search_input input {
        height: 4.1rem;
        width: 100%;
        background: #f7f9fa;
        border-color: #dedede;
        border-radius: 3rem;
        padding: 0 2.5rem;
        padding-right: 5rem;
    }
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
