.tournament_main_wrap {
  width: 100%;
  min-width: inherit;
  padding: 0;
}
.tournament_main_wrap article {
  padding: 6rem 0;
}
.tournament_main_wrap article h2 {
  font-size: 2rem;
  font-weight: 800;
  margin-bottom: 1.2rem;
  padding-left: 1.6rem;
}
.tournament_main_wrap article.dark {
  background: #000;
  color: #fff;
}
.tournament_main_wrap article.dark .contents_wrap {
  gap: 4rem;
}
.tournament_main_wrap article.blue {
  background: #f7f9fa;
}
.tournament_main_wrap article.blue .tournament_main_table_wrap h2 button {
  background: url(../../assets/images/add_circle_b.svg) no-repeat center;
  background-size: 2.4rem;
}
.tournament_main_wrap article.blue .list {
  border-color: #000;
}
.tournament_main_wrap article.blue .list li {
  border-bottom: 1px solid #dedede;
}
.tournament_main_wrap .contents_wrap {
  max-width: 1200px;
  margin: 0 auto;
}
.tournament_main_wrap .tournament_main {
  width: 100%;
  aspect-ratio: 36/40;
  position: relative;
}
.tournament_main_wrap .tournament_main .visual_wrap {
  padding: 0;
  background: #3e21d9;
  width: 100%;
  height: auto;
}
.tournament_main_wrap .tournament_main .visual_wrap .main_img img {
  width: 100%;
  height: auto;
  -o-object-fit: cover;
     object-fit: cover;
}
.tournament_main_wrap .tournament_main .login_wrap {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  width: 100%;
  background: rgba(23, 18, 47, 0.5);
  height: 17rem;
  -webkit-backdrop-filter: blur(8.9px);
  backdrop-filter: blur(8.9px);
  padding: 0;
}
.tournament_main_wrap .tournament_main .login_wrap > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.tournament_main_wrap .tournament_main .login_wrap .login {
  width: 86rem;
  height: 17rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: auto;
}
.tournament_main_wrap .tournament_main .login_wrap .login input {
  width: 29.5rem;
}
@media (max-width: 1500px) {
  .tournament_main_wrap .tournament_main .login_wrap .login {
    justify-content: space-around;
  }
}
@media (max-width: 1200px) {
  .tournament_main_wrap .tournament_main .login_wrap .login {
    justify-content: flex-start;
    padding-left: 2rem;
  }
  .tournament_main_wrap .tournament_main .login_wrap .login input {
    width: 18rem;
  }
}
.tournament_main_wrap .tournament_main .login_wrap .my_page_wrap {
  height: 17rem;
  display: flex;
  width: 36%;
  background: #3e21d9;
}
.tournament_main_wrap .tournament_main .login_wrap .my_page_wrap p {
  width: 17rem;
  text-align: center;
  color: #fff;
  font-size: 1.6rem;
  font-weight: bold;
  padding-top: 10.8rem;
  cursor: pointer;
}
.tournament_main_wrap .tournament_main .login_wrap .my_page_wrap p.my_page {
  background: #2f10b1 url(../../assets/images/Icon_main_my_page.svg) no-repeat center top 4.8rem;
}
.tournament_main_wrap .tournament_main .login_wrap .my_page_wrap p.my_history {
  background: url(../../assets/images/Icon_main_my_history.svg) no-repeat center top 4.8rem;
}
.tournament_main_wrap .tournament_list {
  border-top: 1px solid #dedede;
}
.tournament_main_wrap .tournament_list li {
  display: block;
  padding: 2.4rem 2rem;
  font-size: 1.4rem;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  border-bottom: 1px solid #dedede;
}
.tournament_main_wrap .tournament_list li.type_a .title {
  color: #cbb300;
}
.tournament_main_wrap .tournament_list li.type_b .title {
  color: #7fb1f5;
}
.tournament_main_wrap .tournament_list li.type_c .title {
  color: #d37a39;
}
.tournament_main_wrap .tournament_list li.type_d .title {
  color: #45b752;
}
.tournament_main_wrap .tournament_list li .title {
  font-weight: 500;
}
.tournament_main_wrap .tournament_list li .name {
  font-size: 1.6rem;
  font-weight: 600;
  margin: 1.2rem 0 0.8rem;
}
.tournament_main_wrap .tournament_list li .location {
  font-weight: 400;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: center;
}
.tournament_main_wrap .tournament_list li .location i {
  display: inline-block;
  margin-right: 1rem;
  font-size: 1.2rem;
  font-weight: 600;
  min-width: 4rem;
  height: 2.2rem;
  line-height: 2rem;
  border: 1px solid #000;
  border-radius: 3px;
  padding: 0 0.5rem;
  text-align: center;
}
.tournament_main_wrap .tournament_list li .title,
.tournament_main_wrap .tournament_list li .name {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.tournament_main_wrap .tournament_main_table_wrap {
  flex: 1;
  padding: 0 1.6rem;
}
.tournament_main_wrap .tournament_main_table_wrap h2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 0;
}
.tournament_main_wrap .tournament_main_table_wrap h2 button {
  background-size: 2.4rem;
}
.tournament_main_wrap .tournament_main_table_wrap .list {
  border-top: 2px solid #fff;
}
.tournament_main_wrap .tournament_main_table_wrap .list li {
  padding: 2rem 0.4rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
  font-size: 1.6rem;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
}
.tournament_main_wrap .tournament_main_table_wrap .list li span {
  display: block;
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}
.tournament_main_wrap .tournament_main_table_wrap .list li span.type_a {
  color: #cbb300;
}
.tournament_main_wrap .tournament_main_table_wrap .list li span.type_b {
  color: #528ee0;
}
.tournament_main_wrap .tournament_main_table_wrap .list li span.type_c {
  color: #d37a39;
}
.tournament_main_wrap .tournament_main_table_wrap .list li span.type_d {
  color: #45b752;
}
.tournament_main_wrap .contents_slider {
  width: 100%;
  overflow: hidden;
}
.tournament_main_wrap .contents_slider .content {
  padding: 0 10px;
}
.tournament_main_wrap .contents_slider .content img {
  width: 100%;
  height: 20rem;
  background: lightgray;
}
.tournament_main_wrap .contents_slider .content span {
  margin-top: 1.2rem;
  display: block;
  font-size: 1.2rem;
  font-weight: bold;
  color: #3e21d9;
}
.tournament_main_wrap .contents_slider .content p {
  margin-top: 4px;
  font-size: 1.6rem;
  font-weight: 600;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.tournament_main_wrap .contents_slider :global .slick-slider {
  width: 1040px;
}
.tournament_main_wrap .contents_slider :global .slick-prev,
.tournament_main_wrap .contents_slider :global .slick-next {
  display: none;
}
.tournament_main_wrap .contents_slider :global .slick-prev::before,
.tournament_main_wrap .contents_slider :global .slick-next::before {
  display: none;
}
.tournament_main_wrap .tournament_main_slider {
  width: 100%;
}
.tournament_main_wrap .tournament_main_slider .main_img {
  width: 100%;
  height: auto;
  overflow: hidden;
}
.tournament_main_wrap .tournament_main_slider :global .slick-dots {
  max-width: 100%;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  bottom: 1.6rem;
}
.tournament_main_wrap .tournament_main_slider :global .slick-dots li {
  width: 10px;
}
.tournament_main_wrap .tournament_main_slider :global .slick-dots li button::before {
  content: "";
  border: 1px solid #fff;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  opacity: 1;
}
.tournament_main_wrap .tournament_main_slider :global .slick-dots li.slick-active {
  width: 28px;
}
.tournament_main_wrap .tournament_main_slider :global .slick-dots li.slick-active button::before {
  content: "";
  border: 1px solid #fff;
  background: #fff;
  width: 24px;
  height: 8px;
  border-radius: 5px;
}
.tournament_main_wrap .faq_wrap {
  padding: 0 1.6rem;
}
.tournament_main_wrap .faq_wrap h2 {
  padding-left: 0;
}