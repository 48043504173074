.terms_wrap .sub_header {
  width: 100%;
  height: 5.5rem;
  line-height: 5.5rem;
  background: #000;
}
.terms_wrap .sub_header h2 {
  color: #fff;
  font-size: 1.8rem;
  max-width: 1920px;
  padding-left: 3rem;
  margin: 0 auto;
}
.terms_wrap .inner {
  max-width: 1200px;
  margin: 0 auto;
  padding: 6rem 0 10rem;
}
.terms_wrap .inner .revision_date {
  display: flex;
  gap: 2.5rem;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 3rem;
}
.terms_wrap .inner .revision_date p {
  font-weight: 500;
}
@media (max-width: 1240px) {
  .terms_wrap {
    padding: 8rem 2rem;
    min-width: initial;
  }
}
@media (max-width: 959px) {
  .terms_wrap {
    padding: 8rem 1.6rem 0;
  }
  .terms_wrap .sub_header {
    background: none;
    padding: 0;
  }
  .terms_wrap .sub_header h2 {
    color: #000;
  }
  .terms_wrap .inner {
    padding-top: 0;
  }
  .terms_wrap .inner .revision_date {
    justify-content: flex-start;
  }
  .terms_wrap .inner .terms_box article {
    margin-bottom: 2.4rem;
  }
  .terms_wrap .inner .terms_box article h3 {
    font-size: 1.6rem;
    margin-bottom: 8px;
  }
  .terms_wrap .inner .terms_box article .box {
    padding: 2rem 1.6rem;
  }
}

.terms_box .info {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2.2rem;
  margin-bottom: 5rem;
}
.terms_box article {
  margin-bottom: 3rem;
}
.terms_box article h3 {
  font-size: 1.8rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
}
.terms_box article .box {
  background: #f7f9fa;
  padding: 2.5rem 3rem;
  line-height: 2.5rem;
}
.terms_box article .box ol {
  padding-left: 2rem;
}
.terms_box article .box ol li {
  list-style: decimal;
}
.terms_box article .box ol li ul {
  padding-left: 1.5rem;
}
.terms_box article .box ol li ul li {
  list-style-type: "- ";
}
.terms_box article .box dl {
  margin-bottom: 0.6rem;
}
.terms_box article .box dl dt {
  font-weight: 500;
  min-width: 3.5rem;
}
.terms_box article .box dl dt span {
  margin-right: 2rem;
}
.terms_box article .box dl dd {
  padding-left: 5.5rem;
}
.terms_box article .terms_table {
  margin: 1rem 0;
}
.terms_box article .terms_table th,
.terms_box article .terms_table td {
  text-align: center;
  height: initial;
  padding: 0.5rem 1rem;
  font-size: 1.4rem;
  border-left: 1px solid #dedede;
}
.terms_box article .terms_table th:first-child,
.terms_box article .terms_table td:first-child {
  border-left: none;
}
@media (max-width: 959px) {
  .terms_box article {
    margin-bottom: 2.4rem;
  }
  .terms_box article h3 {
    font-size: 1.6rem;
    margin-bottom: 8px;
  }
  .terms_box article .box {
    padding: 2rem 1.6rem;
  }
}