.alert {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 100;
}
.alert section {
  width: 24.5rem !important;
  min-width: 24.5rem !important;
  min-height: 15rem !important;
  height: initial;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  border-top: 5px solid #000;
  padding: 3.5rem 3rem 3rem !important;
  font-weight: 600;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.alert section .close_x {
  display: none;
}
.alert section .modal_body {
  line-height: 2.4rem;
}
.alert section footer {
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  gap: 5px;
}
.alert section footer button {
  padding: 0 1.7rem !important;
}
.alert.admin_st section {
  width: 26rem;
  padding: 3.2rem 2.5rem 3rem;
  border-top: 0;
  border-radius: 1.5rem;
  position: relative;
}
.alert.admin_st section .close_x {
  width: 1.6rem;
  height: 1.6rem;
  display: block;
  text-indent: -9999px;
  position: absolute;
  top: 2rem;
  right: 2rem;
  cursor: pointer;
  background: url(../../../assets/images/pop_close_16_b.svg) no-repeat center;
}
.alert.admin_st section .modal_body {
  padding-top: 1rem;
}
.alert.admin_st.msg_send .modal_body {
  padding-top: 6rem;
  background: url(../../../assets/images/bg_msg.svg) no-repeat center top;
}
.alert.admin_st.msg_complete .modal_body {
  padding-top: 6rem;
  background: url(../../../assets/images/bg_msg_complete.svg) no-repeat center top;
}