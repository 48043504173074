.system_check_wrap {
  text-align: center;
  padding: 4.5rem 0 4.5rem;
}
.system_check_wrap h3 {
  padding-top: 13rem;
  position: relative;
  font-size: 2.4rem;
  margin-bottom: 3rem;
}
.system_check_wrap h3::before {
  content: "";
  display: block;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  width: 10rem;
  height: 10rem;
  background: #000 url(../../assets/images/icon_as.svg) no-repeat center;
  border-radius: 50%;
  box-sizing: border-box;
}
.system_check_wrap div {
  line-height: 2.2rem;
}
.system_check_wrap div.box {
  display: inline-block;
  width: auto;
  min-width: 40rem;
  margin-top: 3rem;
  padding: 2rem 3rem;
  background: #f7f9fa;
}
.system_check_wrap div.box b {
  margin-right: 2rem;
  display: inline-block;
}
.system_check_wrap .today_close {
  position: absolute;
  bottom: 3rem;
  left: 2rem;
}
.system_check_wrap .today_close label {
  font-size: 1.6rem;
  font-weight: 600;
  text-shadow: 1px 1px 1px white;
  padding-left: 2.5rem;
}