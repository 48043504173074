.tournament_sub_nav {
  padding: 0.8rem 1.6rem 0;
  border-bottom: 1px solid #dedede;
  position: fixed;
  top: 4.3rem;
  left: 0;
  background: #fff;
  z-index: 10;
  width: 100vw;
}
.tournament_sub_nav .none_slide {
  display: flex;
  width: 100%;
}
.tournament_sub_nav .depth_1_link {
  height: 4.2rem;
}
.tournament_sub_nav .depth_1_link a {
  padding-top: 1.6rem;
  padding: 16px 4px 0;
  margin-right: 1.2rem;
  height: 100%;
  display: block;
  color: #999;
}
.tournament_sub_nav .depth_1_link :global .active {
  color: #000;
  font-weight: bold;
  border-bottom: 2px solid #000;
}
.tournament_sub_nav nav ul {
  display: flex;
  gap: 1.2rem;
  width: 100%;
  position: relative;
}
.tournament_sub_nav nav li {
  height: 4.2rem;
  color: #999;
  padding-top: 1.6rem;
}
.tournament_sub_nav nav li a {
  display: block;
  padding: 0 4px;
  height: 100%;
}
.tournament_sub_nav nav :global .active {
  color: #000;
  font-weight: bold;
  border-bottom: 2px solid #000;
}
.tournament_sub_nav :global .header_sub_nav {
  width: 51rem;
}