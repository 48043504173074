.drop_zone_wrap .btn_wrap {
  display: flex;
  gap: 1rem;
  align-items: center;
}
.drop_zone_wrap .btn_wrap .desc {
  font-size: 1.4rem;
  color: #3e21d9;
}
.drop_zone_wrap .zone_box {
  margin-top: 1rem;
  border: 2px dashed #dedede;
  padding: 2rem;
  min-height: 10rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.drop_zone_wrap .zone_box .text {
  color: #999;
  text-align: center;
}
.drop_zone_wrap .zone_box .preview_wrap ul {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}
.drop_zone_wrap .zone_box .preview_wrap ul li {
  padding-top: 2rem;
}
.drop_zone_wrap .zone_box .preview_wrap ul .file_item .preview_image {
  max-width: 20rem;
  height: auto;
  max-height: 20rem;
  border-radius: 1rem;
}
.drop_zone_wrap .zone_box .preview_wrap ul .file_info {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;
}
.drop_zone_wrap .zone_box .preview_wrap ul .file_info span {
  max-width: 17rem;
}
.drop_zone_wrap .zone_box .preview_wrap ul .file_info .remove_button {
  text-indent: -9999px;
  width: 20px;
  height: 20px;
  background: url(../../../assets/images/icon_tf_delete.svg) no-repeat center;
  background-size: 20px;
}