@charset "UTF-8";
.tournament_container {
  width: 100%;
  min-width: initial;
  padding-top: 9.2rem;
  padding: 9.2rem 0 10rem;
}
.tournament_container .type_a {
  color: #cbb300 !important;
}
.tournament_container .type_b {
  color: #528ee0 !important;
}
.tournament_container .type_c {
  color: #d37a39 !important;
}
.tournament_container .type_d {
  color: #45b752 !important;
}
.tournament_container .desc {
  font-size: 1.2rem;
  color: #999;
  font-weight: 400;
  margin-top: 8px;
}
.tournament_container .more_btn {
  margin-top: 2rem;
  text-align: center;
}
.tournament_container .more_btn button {
  font-size: 1.4rem;
  font-weight: 500;
  width: 100%;
  text-align: center;
  padding-right: 4rem;
  background-position: center right calc(50% - 30px);
  background-size: 1.5rem auto;
}
.tournament_container .mobile_board_table table {
  border-top: none;
}
.tournament_container .mobile_board_table table thead,
.tournament_container .mobile_board_table table colgroup {
  display: none;
}
.tournament_container .mobile_board_table table tr {
  display: block;
  border-bottom: 1px solid #dedede;
  padding: 2rem;
  position: relative;
}
.tournament_container .mobile_board_table table tr td {
  display: block;
  border-bottom: none;
  padding: 0;
  height: initial;
  line-height: initial;
  white-space: break-spaces;
}
.tournament_container .mobile_board_table .more_btn {
  padding: 0 1.6rem;
}
.tournament_container .mobile_board_table.notice_type tr {
  position: relative;
  padding-left: 5.9rem;
}
.tournament_container .mobile_board_table.notice_type tr td {
  font-weight: 500;
  font-size: 1.2rem;
}
.tournament_container .mobile_board_table.notice_type tr td b {
  font-weight: 500;
  color: #999;
}
.tournament_container .mobile_board_table.notice_type tr td:nth-child(1) {
  position: absolute;
  left: 2rem;
  top: 2rem;
  font-size: 1.4rem;
}
.tournament_container .mobile_board_table.notice_type tr td:nth-child(3) {
  margin-top: 8px;
  font-size: 1.6rem;
}
.tournament_container .mobile_board_table.notice_type tr td:nth-child(4) {
  position: absolute;
  right: 2rem;
  top: 2rem;
  color: #999;
}
.tournament_container .mobile_board_table.apply_type table tr td:nth-child(1) {
  font-size: 1.2rem;
  font-weight: 500;
}
.tournament_container .mobile_board_table.apply_type table tr td:nth-child(2) {
  font-size: 1.6rem;
  font-weight: 600;
  margin: 1.2rem 0;
}
.tournament_container .mobile_board_table.apply_type table tr td:nth-child(3) {
  margin-bottom: 4px;
}
.tournament_container .mobile_board_table.apply_type table tr td:nth-child(3)::before {
  display: inline-block;
  padding-right: 0.8rem;
}
.tournament_container .mobile_board_table.apply_type table tr td:nth-child(4), .tournament_container .mobile_board_table.apply_type table tr td:nth-child(5) {
  display: inline-block;
  font-weight: 600;
  padding-right: 0.8rem;
}
.tournament_container .mobile_board_table.apply_type table tr td:nth-child(4) br, .tournament_container .mobile_board_table.apply_type table tr td:nth-child(5) br {
  display: none;
}
.tournament_container .mobile_board_table.apply_type table tr td:nth-child(6) {
  position: absolute;
  top: 2rem;
  right: 2rem;
  font-size: 1.2rem;
  border: 1px solid;
  padding: 0 4px;
  border-radius: 4px;
  height: 1.9rem;
  line-height: 1.7rem;
}
.tournament_container .mobile_board_table.apply_type table tr td:nth-child(7) {
  margin-top: 1.2rem;
}
.tournament_container .mobile_board_table.apply_type table tr td:nth-child(8) {
  position: absolute;
  left: 10.8rem;
  bottom: 2rem;
}
.tournament_container .mobile_modal_table table colgroup {
  display: none;
}
.tournament_container .mobile_modal_table table tr {
  display: block;
  border-bottom: 1px solid #dedede;
  padding: 8px;
}
.tournament_container .mobile_modal_table table th,
.tournament_container .mobile_modal_table table td {
  display: block;
  padding: 0;
  height: initial !important;
  line-height: 2rem !important;
  border-bottom: none;
  font-weight: 500;
  text-overflow: initial;
  overflow: initial;
}
.tournament_container .mobile_modal_table table th {
  font-size: 1.2rem;
  color: #999;
  margin-bottom: 4px;
}
.tournament_container .applyForm {
  margin-bottom: 3rem;
}
.tournament_container .applyForm .input_group {
  display: flex;
  align-items: center;
}
.tournament_container .applyForm .input_group span {
  display: block;
  margin: 0 0.5rem;
}
.tournament_container .applyForm .error_text {
  color: #e60013;
  font-size: 1.4rem;
  padding-left: 1.7rem;
  margin-top: 0.5rem;
  background: url(../../assets/images/icon_error.svg) no-repeat left center;
}
.tournament_container .applyForm .terms_agree_wrap {
  margin-top: 1rem;
}
.tournament_container .applyForm .terms_agree_wrap .terms_agree {
  margin-bottom: 1rem;
}
.tournament_container .applyForm .terms_agree_wrap .terms_agree span {
  font-size: 1.4rem;
  color: #999999;
}
.tournament_container .complete_wrap {
  padding: 0 2rem 3rem;
}
.tournament_container .complete_wrap h3 {
  font-size: 2rem;
  font-weight: bold;
  padding-top: 8.4rem;
  background: url(../../assets/images/icon_application_completed.svg) no-repeat center top 8px;
  background-size: 6.4rem auto;
}
.tournament_container .complete_wrap h3 span {
  font-size: 2rem;
  margin-right: 0.3rem;
}
.tournament_container .complete_wrap p {
  margin-top: 1rem;
  font-size: 1.4rem;
  line-height: 2.2rem;
  margin-bottom: 2.4rem;
  text-align: center;
}
.tournament_container .complete_wrap p span {
  color: #3e21d9;
  font-weight: 600;
}
.tournament_container .reception_status_wrap .search_box {
  margin-bottom: 1.6rem;
}
.tournament_container .view_location_wrap .map_wrap {
  width: 100%;
  height: 30rem;
  background: rgba(0, 0, 0, 0.3);
}
.tournament_container .view_location_wrap .info_wrap h3 {
  margin: 3rem 0 1rem;
  text-align: left !important;
}
.tournament_container .nav_tab {
  width: 100%;
  display: flex;
  margin: 2rem 0 1.6rem;
}
.tournament_container .nav_tab li {
  flex: 1;
  height: 4rem;
  line-height: 4rem;
  background: #f7f9fa;
  color: #999;
  font-weight: 500;
  text-align: center;
}
.tournament_container .nav_tab li.on {
  background: #3e21d9;
  color: #fff;
  font-weight: bold;
}
.tournament_container .tournament_img_header {
  height: 15.5rem;
  position: relative;
  color: #fff;
}
.tournament_container .tournament_img_header::before {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
}
.tournament_container .tournament_img_header img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.tournament_container .tournament_img_header .title {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  padding: 4rem 3rem;
}
.tournament_container .tournament_img_header .title h2 {
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 8px;
  line-height: 2.5rem;
}
.tournament_container .tournament_img_header .title p {
  font-weight: 500;
}
.tournament_container section .search_wrap {
  width: 100%;
  position: relative;
}
.tournament_container section .search_wrap .thanks {
  font-size: 1.6rem;
  font-weight: 600;
  text-align: center;
  margin-top: 2.4rem;
}
.tournament_container section .search_wrap input {
  height: 5rem;
  padding: 0 5rem 0 1.6rem;
  background: #f7f9fa;
  border-top: 0;
  border-left: 0;
  border-right: 0;
}
.tournament_container section .search_wrap button {
  position: absolute;
  top: 1.3rem;
  right: 2.4rem;
}
.tournament_container section .search_wrap > div {
  width: 100%;
  text-align: right;
}
.tournament_container section .search_wrap > div p {
  border-top: none;
  border-left: none;
  border-right: none;
  background: #f7f9fa;
  padding-right: 4rem;
  height: 5rem;
  line-height: 5rem;
}
.tournament_container section .search_wrap > div ul {
  background: #f7f9fa;
  padding-right: 4rem;
}
.tournament_container section .search_wrap > div ul li {
  padding: 0;
}
.tournament_container section .search_wrap > div::after {
  top: 2rem;
}
.tournament_container section .search_wrap .search_box {
  padding: 1.6rem 2rem;
  display: flex;
  justify-content: center;
}
.tournament_container section .search_wrap .search_box input {
  height: 4.1rem;
  width: 100%;
  background: #f7f9fa;
  border: 1px solid #dedede;
  border-radius: 3rem;
  padding: 0 2.5rem;
  padding-right: 5rem;
}
.tournament_container section .search_wrap .search_box p {
  padding: 0;
  height: initial;
  line-height: initial;
  padding-left: 2.7rem;
  background: url(../../assets/images/icon_error.svg) no-repeat left 1rem center;
}
.tournament_container .mobile_detail_table {
  padding-top: 1.2rem;
}
.tournament_container .mobile_detail_table table {
  border-top: none;
}
.tournament_container .mobile_detail_table table colgroup {
  display: none;
}
.tournament_container .mobile_detail_table table tr {
  cursor: default;
  display: block;
  border-bottom: 1px solid #dedede;
  padding: 1.2rem 0.8rem;
}
.tournament_container .mobile_detail_table table th,
.tournament_container .mobile_detail_table table td {
  display: block;
  padding: 0;
  height: initial;
  line-height: inherit;
  border-bottom: none;
  font-weight: 500;
  white-space: break-spaces;
}
.tournament_container .mobile_detail_table table th {
  color: #999;
  margin-bottom: 8px;
  font-size: 1.2rem;
}
.tournament_container .mobile_detail_table table td br {
  height: 8px;
}
.tournament_container .view_content {
  padding: 0 1.6rem 5rem;
}
.tournament_container .view_content b {
  font-weight: 500;
  font-size: 1.2rem;
}
.tournament_container .view_content b.type_a {
  color: #cbb300;
}
.tournament_container .view_content b.type_b {
  color: #528ee0;
}
.tournament_container .view_content b.type_c {
  color: #d37a39;
}
.tournament_container .view_content b.type_d {
  color: #45b752;
}
.tournament_container .view_content .title {
  border-bottom: 1px solid #000;
  padding: 2.3rem 0.4rem;
  position: relative;
}
.tournament_container .view_content .title b {
  margin-bottom: 1rem;
  display: block;
}
.tournament_container .view_content .title h3 {
  font-size: 1.6rem;
  font-weight: 600;
}
.tournament_container .view_content .title em {
  font-size: 1.2rem;
  font-weight: 500;
}
.tournament_container .view_content .title em,
.tournament_container .view_content .title button {
  position: absolute;
  top: 2.5rem;
  right: 2rem;
}
.tournament_container .view_content .content_wrap {
  padding: 2.4rem 0.4rem;
}
.tournament_container .view_content .content_wrap .download {
  font-size: 1.4rem;
  color: #3e21d9;
  text-decoration: underline;
  text-underline-position: under;
  margin-bottom: 3rem;
  font-weight: 500;
  display: inline-block;
  padding-left: 1.5rem;
  background: url(../../assets/images/icon_download.svg) no-repeat left top 0.3rem;
  word-break: break-all;
}
.tournament_container .view_content .content_wrap .content {
  line-height: 2.5rem;
}
.tournament_container .view_content .content_wrap .inner_table ul {
  margin-bottom: 1.2rem;
}
.tournament_container .view_content .content_wrap .inner_table ul li {
  height: 3.3rem;
  line-height: 3.3rem;
  padding: 0 2rem;
  font-size: 1.4rem;
  font-weight: 500;
}
.tournament_container .view_content .content_wrap .inner_table ul li span {
  color: #3e21d9;
  margin-right: 1.5rem;
}
.tournament_container .view_content .content_wrap .inner_table ul li.rest {
  background: #e5f7f5 !important;
}
.tournament_container .view_content .content_wrap .inner_table ul li:nth-child(2n+1) {
  background: #f7f9fa;
}
.tournament_container .view_content .content_wrap .inner_table ul li:nth-child(1) {
  font-weight: bold;
  color: #fff;
  background: #3e21d9;
}
.tournament_container .view_content .content_wrap .inner_table ul:nth-child(2) li {
  background: #fff;
}
.tournament_container .view_content .content_wrap .inner_table ul:nth-child(2) li:nth-child(2n) {
  background: #f7f9fa;
}
.tournament_container .view_content .content_wrap .inner_table ul:nth-child(2) li:nth-child(1) {
  background: #02a496;
}
.tournament_container .view_content .content_wrap .inner_table ul:nth-child(2) li span {
  color: #02a496;
}
.tournament_container .view_content .content_wrap.table_st {
  padding: 0;
}
.tournament_container .view_content .content_wrap.table_st table {
  border-top: 0;
  margin-bottom: 3rem;
}
.tournament_container .view_content .content_wrap.table_st table tr {
  cursor: default;
}
.tournament_container .view_content .content_wrap.table_st table th,
.tournament_container .view_content .content_wrap.table_st table td {
  padding: 1.6rem 2rem;
}
.tournament_container .view_content .content_wrap.search_result_table h5 {
  font-size: 1.6rem;
  font-weight: 500;
  margin-bottom: 1rem;
}
.tournament_container .view_content .content_wrap.search_result_table h5 span {
  font-weight: 500;
  margin-left: 1.5rem;
}
.tournament_container .view_content .content_wrap.search_result_table table {
  margin-bottom: 5rem;
}
.tournament_container .view_content .content_wrap.search_result_table table span {
  font-weight: 500;
}
.tournament_container .view_content .list_wrap {
  border-top: 1px solid #dedede;
  margin-bottom: 3rem;
}
.tournament_container .view_content .list_wrap li {
  border-bottom: 1px solid #dedede;
  height: 6.9rem;
  display: flex;
  align-items: flex-start;
  padding: 0 4px;
  cursor: pointer;
  position: relative;
  gap: 2rem;
}
.tournament_container .view_content .list_wrap li i {
  padding-top: 2.6rem;
  width: 6rem;
  background: url(../../assets/images/icon_dropdown_arrow.svg) no-repeat right top 2.9rem;
}
.tournament_container .view_content .list_wrap li b {
  position: absolute;
  top: 1.6rem;
  left: 8.2rem;
}
.tournament_container .view_content .list_wrap li span {
  flex: 1;
  margin-top: 3.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 500;
}
.tournament_container .view_content .list_wrap li.prev i {
  background: url(../../assets/images/icon_dropdown_close.svg) no-repeat right top 2.9rem;
}
.tournament_container .view_content .list_wrap li em {
  font-size: 1.2rem;
  font-weight: 500;
  color: #999;
  position: absolute;
  top: 1.6rem;
  right: 4px;
}
.tournament_container .view_content .btn_wrap {
  margin-top: 2.4rem;
  display: flex;
  gap: 1rem;
  justify-content: center;
}
.tournament_container .no_data {
  padding: 20rem 0;
}
.tournament_container .no_data p {
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  padding-top: 8.4rem;
  background: url(../../assets/images/no_data_mo.svg) no-repeat center top;
}
.tournament_container .table_btn_st table tr td {
  padding: 0 0.5rem;
  line-height: 2.2rem;
}
.tournament_container .table_btn_st table tr td:nth-child(7), .tournament_container .table_btn_st table tr td:nth-child(8) {
  padding: 1.05rem 0;
  text-overflow: initial;
}
.tournament_container .card_list_wrap {
  padding: 2.4rem 1.6rem 0;
}
.tournament_container .card_list_wrap .card {
  margin-bottom: 1.5rem;
  padding: 1.6rem;
  position: relative;
  background: #f7f9fa;
  cursor: pointer;
}
.tournament_container .card_list_wrap .card button {
  margin-top: 1.2rem;
}
.tournament_container .card_list_wrap .card .title {
  border-bottom: 1px solid #dedede;
  padding-bottom: 1.6rem;
}
.tournament_container .card_list_wrap .card .title b {
  display: block;
  margin-bottom: 1rem;
  font-size: 1.2rem;
  font-weight: 500;
}
.tournament_container .card_list_wrap .card .title h3 {
  font-size: 1.6rem;
  font-weight: 600;
}
.tournament_container .card_list_wrap .card .title button {
  position: absolute;
  top: 8.2rem;
  right: 5.1rem;
}
.tournament_container .card_list_wrap .card .title .state {
  font-size: 1.2rem;
  position: absolute;
  height: 1.9rem;
  line-height: 1.9rem;
  top: 1.6rem;
  right: 1.6rem;
  color: #fff;
  padding: 0 4px;
  border-radius: 4px;
}
.tournament_container .card_list_wrap .card .title .state.ing {
  background: #3e21d9;
}
.tournament_container .card_list_wrap .card .title .state.to_be {
  background: #02a496;
}
.tournament_container .card_list_wrap .card .title .state.end {
  background: #999999;
}
.tournament_container .card_list_wrap .card .info {
  margin-top: 1.6rem;
  line-height: 1.9rem;
}
.tournament_container .card_list_wrap .card .info li {
  font-weight: 500;
  margin-bottom: 1.2rem;
}
.tournament_container .card_list_wrap .card .info li span {
  font-size: 1.2rem;
  color: #999;
  display: block;
}
.tournament_container .card_list_wrap .card .info li:last-child {
  margin-bottom: 0;
}
.tournament_container .card_list_wrap .btn_wrap_center {
  display: flex;
  justify-content: center;
  padding-top: 1.5rem;
}
.tournament_container .img_type {
  margin-top: 0;
}
.tournament_container .img_type .view_content h3 {
  font-size: 2.4rem;
  padding-bottom: 2rem;
  border-bottom: 3px solid #000;
}
.tournament_container .img_type .view_content h4 {
  font-size: 2rem;
  padding-bottom: 2rem;
}
.tournament_container .result_detail_st .view_content {
  padding: 0;
}
.tournament_container .result_detail_st .view_content .inner {
  padding: 0 1.6rem;
  overflow: hidden;
}
.tournament_container .result_detail_st .view_content .search_box button {
  top: 50%;
  transform: translateY(-50%);
}
.tournament_container .result_detail_st .content_wrap .inner_header > div {
  width: 100%;
  margin-bottom: 2rem;
}
.tournament_container .result_gallery_wrap {
  margin-bottom: 2.4rem;
}
.tournament_container .result_gallery_wrap h4 {
  font-size: 2rem !important;
  padding-bottom: 1.2rem !important;
}
.tournament_container .result_gallery_wrap .result_gallery .content {
  height: 16.8rem;
}
.tournament_container .result_gallery_wrap .result_gallery .content img {
  background: lightblue;
  width: 24rem;
  height: 16.8rem;
  -o-object-fit: cover;
     object-fit: cover;
}
.tournament_container .result_gallery_wrap .result_gallery :global .gallery_slide {
  width: 504px;
}
.tournament_container .result_gallery_wrap .result_gallery :global .slick-prev,
.tournament_container .result_gallery_wrap .result_gallery :global .slick-next {
  width: 5rem;
  height: 5rem;
  top: 17.5rem;
}
.tournament_container .result_gallery_wrap .result_gallery :global .slick-prev::before,
.tournament_container .result_gallery_wrap .result_gallery :global .slick-next::before {
  display: none;
}
.tournament_container .result_gallery_wrap .result_gallery :global .slick-prev {
  background: url(../../assets/images/btn_default_l.svg) no-repeat;
  left: -7rem !important;
}
.tournament_container .result_gallery_wrap .result_gallery :global .slick-next {
  background: url(../../assets/images/btn_default_r.svg) no-repeat;
  right: -7rem !important;
}
.tournament_container .mobile_result_table table {
  border-top: 2px solid #000 !important;
  margin-bottom: 1.2rem !important;
}
.tournament_container .mobile_result_table table thead th {
  height: 4rem;
  padding: 0 !important;
  border-bottom: 1px solid #dedede;
}
.tournament_container .mobile_result_table table thead th:nth-child(1) {
  text-align: center;
}
.tournament_container .mobile_result_table table tbody th,
.tournament_container .mobile_result_table table tbody td {
  height: 3.3rem;
  line-height: 3.1rem;
  padding: 0 !important;
  text-align: center;
  border-bottom: none;
  font-weight: 400;
}
.tournament_container .mobile_result_table table tbody td {
  font-weight: 500;
  border-bottom: 1px solid #dedede;
}
.tournament_container .x_scroll {
  width: 100%;
  overflow-x: auto;
  margin-bottom: 2rem;
}
.tournament_container .x_scroll .mobile_result_table {
  min-width: 600px;
}
.tournament_container .x_scroll .mobile_result_table table {
  width: 100%;
}
.tournament_container .x_scroll .mobile_result_table thead th {
  text-align: center;
}

.bottom_btn_wrap {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
  max-width: 1200px;
  margin: 2rem auto 15rem;
  padding: 0 1.6rem;
}

.btn_wrap_center {
  display: flex;
  justify-content: center;
}

.mobile_board_table .mobile_table_2 table colgroup {
  display: table-column-group;
}
.mobile_board_table .mobile_table_2 table thead {
  display: table-header-group;
}
.mobile_board_table .mobile_table_2 table tr {
  padding: 0;
  border-bottom: 0;
  display: table-row;
}
.mobile_board_table .mobile_table_2 table tr th {
  padding: 1.05rem 0;
}
.mobile_board_table .mobile_table_2 table tr td {
  display: table-cell !important;
  border-bottom: 1px solid #dedede;
  padding: 0 !important;
  height: inherit;
  line-height: inherit;
  overflow: initial;
  text-overflow: initial;
  white-space: initial;
  word-break: break-all;
}
.mobile_board_table .mobile_table_2 table tr td:nth-child(2) {
  font-size: 1.4rem !important;
}
.mobile_board_table .mobile_table_2 table tr td::before {
  display: none !important;
}