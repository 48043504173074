.products_container {
  position: relative;
  width: 100%;
  max-width: 1920px;
  min-height: calc(100vh - 80px);
  margin: 0 auto;
  padding-top: 8rem;
  padding-left: 26rem;
}
.products_container .flex_row {
  display: flex;
  align-items: center;
  gap: 2rem;
}
.products_container .desc_s {
  font-size: 1.4rem;
  color: #999;
  line-height: 2.2rem;
}
.products_container .desc_list {
  font-size: 1.4rem;
  line-height: 2.2rem;
}
.products_container .desc_list li {
  white-space: normal;
  padding-left: 8px;
  position: relative;
}
.products_container .desc_list li::before {
  content: "";
  display: block;
  width: 3px;
  height: 3px;
  background: #000;
  border-radius: 50%;
  margin-right: 5px;
  position: absolute;
  top: 9px;
  left: 0;
}
.products_container .desc_list.gray {
  color: #999;
}
.products_container .desc_list.gray li::before {
  background: #999;
}
.products_container .desc_list.footer_st {
  margin-top: 3rem;
}
.products_container .desc_list.footer_st b {
  color: #000;
  display: block;
  margin-bottom: 1rem;
}
.products_container .info_title {
  margin-bottom: 2rem;
  line-height: 2.2rem;
}
.products_container .birth_wrap {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.products_container .birth_wrap span {
  margin-right: 1rem;
}
.products_container .img_file_box {
  display: inline-block;
  margin: 1.5rem 1rem 2rem 0;
}
.products_container .img_file_box input[type=file] {
  display: none;
}
.products_container .img_file_box label {
  display: inline-block;
  height: 4rem;
  line-height: 3.8rem;
  font-size: 1.6rem;
  min-width: 7.8rem;
  padding: 0 1.7rem;
  color: #000;
  border: 1px solid #000;
  text-align: center;
  border-radius: 3.5rem;
  background: #fff;
  font-weight: 500;
}
.products_container .detail_table_wrap table tr {
  cursor: default;
}
.products_container .detail_table_wrap table tr th,
.products_container .detail_table_wrap table tr td {
  overflow: initial;
  text-overflow: initial;
  padding: 5px 20px;
}
.products_container .detail_table_wrap table tr th {
  padding: 2rem 0 0 2rem;
}
.products_container .radio_wrap {
  display: flex;
  align-items: center;
  gap: 3rem;
}
.products_container .phone_wrap {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.products_container .address_wrap {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.products_container .table_img_box {
  width: 27rem;
  height: 16.8rem;
}
.products_container .table_img_box img {
  width: 100%;
  height: 100%;
}
.products_container .btn_bottom_wrap {
  margin-top: 3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}
.products_container .btn_bottom_wrap .right {
  margin-top: 3rem;
  display: flex;
  gap: 1rem;
  margin-left: auto;
}
.products_container .right {
  margin-top: 3rem;
  display: flex;
  gap: 1rem;
  margin-left: auto;
}
.products_container .products_sub_nav {
  /* min-height: calc(100vh - 80px);
  height: auto; */
  
  position: absolute;
  background: #fff;
  width: 26rem;
  top: 8rem;
  left: 0;
  /* 20240115 추가영역 */
  max-height: calc(100% - 80px);
  overflow-y: auto;
  overflow-x: hidden;
}
.products_container .products_sub_nav h3 {
  height: 5.5rem;
  line-height: 5.5rem;
  background: #000;
  color: #fff;
  font-size: 1.8rem;
  padding-left: 2.5rem;
}
.products_container .products_sub_nav nav {
  margin-bottom: 2rem;
}
.products_container .products_sub_nav nav li {
  color: #999;
}
.products_container .products_sub_nav nav li span {
  display: block;
  cursor: pointer;
}
.products_container .products_sub_nav nav .depth_1 > li > span {
  height: 5rem;
  line-height: 4.9rem;
  background: url(../../assets/images/lnb_arrow_gray.svg) no-repeat center right 2rem;
  transition: all 0.2s;
  border-bottom: 1px solid #dedede;
  padding-left: 2rem;
}
.products_container .products_sub_nav nav .depth_1 .depth_2 {
  display: none;
}
.products_container .products_sub_nav nav .depth_1 .depth_2 li span {
  height: 3.6rem;
  line-height: 3.6rem;
  padding-left: 3rem;
  background: url(../../assets/images/lnb_depth.svg) no-repeat center left 2rem;
}
.products_container .products_sub_nav nav .depth_1 .depth_2 li span i {
  display: inline-block;
  height: 1.6rem;
  line-height: 1.6rem;
  font-size: 1.1rem;
  color: #999;
  background: #edf0f0;
  padding: 0 5px;
  border-radius: 8px;
  margin-left: 5px;
  font-weight: 600;
  vertical-align: text-top;
}
.products_container .products_sub_nav nav .depth_1 .depth_2 li.on span {
  font-weight: bold;
  background: #edf0f0 url(../../assets/images/lnb_depth.svg) no-repeat center left 2rem;
}
.products_container .products_sub_nav nav .depth_1 .depth_2 li.on span i {
  background: #e60013;
  color: #fff;
  font-weight: 600;
}
.products_container .products_sub_nav nav .depth_1 > .on > span {
  font-weight: bold;
  color: #000;
  background: #f8fafa url(../../assets/images/lnb_arrow_black.svg) no-repeat center right 2rem;
  border-bottom: none;
}
.products_container .products_sub_nav nav .depth_1 > .on > .depth_2 {
  display: block;
  background: #f8fafa;
}
.products_container .products_sub_nav nav .depth_1 > .on > .depth_2 li {
  border-bottom: none;
  color: #000;
}
.products_container .products_sub_nav nav .depth_1 > .on > .depth_2 li a {
  padding-left: 2.9rem;
  background-image: url(../../assets/images/lnb_depth.svg);
  background-repeat: no-repeat;
  background-position: left 2rem center;
}
.products_container .products_sub_nav nav .depth_1 > .on > .depth_2 li:last-child {
  border-bottom: 1px solid #dedede;
}
.products_container .products_sub_nav .smart_filter_wrap h4 {
  color: #fff;
  background: #e60013;
  height: 3.1rem;
  line-height: 3.1rem;
  padding-left: 2.5rem;
  font-size: 1.4rem;
  font-weight: 500;
}
.products_container .products_sub_nav .smart_filter_wrap ul li {
  cursor: pointer;
}
.products_container .products_sub_nav .smart_filter_wrap ul li span {
  display: block;
  height: 5rem;
  line-height: 4.9rem;
  padding-left: 2rem;
  font-weight: 500;
  background: url(../../assets/images/lnb_open.svg) no-repeat center right 2rem;
  border-bottom: 1px solid #dedede;
}
.products_container .products_sub_nav .smart_filter_wrap ul li .depth_2_wrap {
  max-height: 25rem;
  overflow-y: auto;
  padding: 0 20px;
  display: none;
}
.products_container .products_sub_nav .smart_filter_wrap ul li .depth_2 p {
  line-height: 2.5rem;
}
.products_container .products_sub_nav .smart_filter_wrap ul li hr {
  border-style: dashed;
  border-bottom: 0;
  border-color: #868686;
}
.products_container .products_sub_nav .smart_filter_wrap ul li.on {
  border-bottom: 1px solid #dedede;
  padding: 0 5px 10px 0;
}
.products_container .products_sub_nav .smart_filter_wrap ul li.on span {
  background: url(../../assets/images/lnb_close.svg) no-repeat center right 2rem;
  border-bottom: 0;
}
.products_container .products_sub_nav .smart_filter_wrap ul li.on .depth_2_wrap {
  display: block;
}
.products_container .products_sub_nav .btn_wrap {
  display: flex;
}
.products_container .products_sub_nav .btn_wrap button {
  flex: 1;
  border-radius: 0;
}
.products_container .products_sub_nav .btn_wrap button:first-child {
  background: #999;
  color: #fff;
  border-color: #999;
}
.products_container .products_sub_nav .btn_wrap button:first-child:hover {
  border-color: #999;
}
.products_container section .products_contents {
  width: 100%;
  height: 100%;
  position: relative;
  border-left: 1px solid #dedede;
}
.products_container .contents_wrap {
  max-width: 130rem;
  width: 100%;
  padding: 10rem 0 15rem 10rem;
  position: relative;
}
@media (max-width: 1560px) {
  .products_container .contents_wrap {
    padding: 5vw 2vw 7.813vw 2vw;
  }
}
.products_container .contents_wrap .tag_wrap {
  position: absolute;
  top: 1.5rem;
  left: 2rem;
}
.products_container .contents_wrap .tag_wrap ul {
  display: flex;
  gap: 5px;
}
.products_container .contents_wrap .tag_wrap ul li {
  height: 2.5rem;
  line-height: 2.5rem;
  padding: 0 1rem;
  background: #edf0f0;
  font-size: 1.4rem;
}
.products_container .contents_wrap .tag_wrap ul li button {
  margin-left: 6px;
  background-size: 10px 10px;
  width: 1rem;
  height: 1rem;
}
.products_container .contents_wrap .contents_title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 2rem;
}
.products_container .contents_wrap .contents_title h3 {
  font-size: 2.4rem;
  position: relative;
}
.products_container .contents_wrap .contents_title h3 .count {
  position: absolute;
  right: -31px;
  top: -4px;
  font-size: 1.1rem;
  font-weight: 600;
  color: #fff;
  height: 1.6rem;
  line-height: 1.8rem;
  padding: 0 5px;
  border-radius: 8px;
  background: #e60013;
}
.products_container .contents_wrap .contents_title div {
  width: 29.5rem;
}
.products_container .contents_wrap .contents_title .desc {
  width: 100%;
  margin-top: 1rem;
}
.products_container .contents_wrap .contents_title .search_wrap {
  display: flex;
  justify-content: flex-end;
  position: relative;
  margin-left: auto;
}
.products_container .contents_wrap .contents_title .search_wrap input {
  width: 29.5rem;
  padding-right: 4rem;
}
.products_container .contents_wrap .contents_title .search_wrap button {
  position: absolute;
  top: 0.7rem;
  right: 1.5rem;
}
.products_container .contents_wrap .contents_detail .contents_title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 60rem;
  margin-bottom: 8rem;
}
.products_container .contents_wrap .contents_detail .contents_title .img_box {
  height: 100%;
  display: flex;
  max-width: 72.3rem;
  width: 60%;
  gap: 1.5rem;
}
.products_container .contents_wrap .contents_detail .contents_title .img_box .main_img {
  max-width: 60rem;
  width: 82%;
  height: auto;
  max-height: 60rem;
  padding: 0 10rem;
}
.products_container .contents_wrap .contents_detail .contents_title .img_box .main_img img {
  width: 40rem;
  margin: 0 auto;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.products_container .contents_wrap .contents_detail .contents_title .img_box .thumbnail_box {
  max-width: 10.8rem;
  width: 15%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.products_container .contents_wrap .contents_detail .contents_title .img_box .thumbnail_box .thumb_img {
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.products_container .contents_wrap .contents_detail .contents_title .contents_info {
  max-width: 40rem;
  width: 35%;
  height: 100%;
  padding: 2rem 0 0 0;
}
.products_container .contents_wrap .contents_detail .contents_title .contents_info .logo_wrap {
  margin-bottom: 3rem;
  width: 100%;
}
.products_container .contents_wrap .contents_detail .contents_title .contents_info .logo_wrap ul {
  display: flex;
  gap: 1rem;
  align-items: flex-end;
}
.products_container .contents_wrap .contents_detail .contents_title .contents_info .logo_wrap ul li {
  height: 10rem;
  width: 10rem;
}
.products_container .contents_wrap .contents_detail .contents_title .contents_info .logo_wrap ul img {
  height: 100%;
  width: 10rem !important;
  -o-object-fit: contain;
     object-fit: contain;
}
.products_container .contents_wrap .contents_detail .contents_title .contents_info .title {
  width: 100%;
  border-top: 2px solid #000;
  border-bottom: 1px solid #dedede;
  padding: 2.4rem 5rem 2.4rem 1.5rem;
  position: relative;
}
.products_container .contents_wrap .contents_detail .contents_title .contents_info .title .en {
  font-size: 1.4rem;
  color: #e60013;
  font-weight: 600;
  margin-bottom: 4px;
}
.products_container .contents_wrap .contents_detail .contents_title .contents_info .title h3 {
  font-size: 3rem;
  word-wrap: break-word;
}
.products_container .contents_wrap .contents_detail .contents_title .contents_info .title .share_wrap {
  position: absolute;
  top: 3.2rem;
  right: 1.5rem;
  width: 3rem;
}
.products_container .contents_wrap .contents_detail .contents_title .contents_info .title .share_wrap .share_pop {
  display: none;
  position: absolute;
  width: 29.4rem;
  height: 17rem;
  background: #fff;
  box-shadow: 5px 10px 20px 0 rgba(0, 0, 0, 0.08);
  border: solid 1px #dedede;
  border-top: 5px solid #000;
  right: -1.5rem;
  z-index: 10;
}
.products_container .contents_wrap .contents_detail .contents_title .contents_info .title .share_wrap .share_pop .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem 2rem 0;
}
.products_container .contents_wrap .contents_detail .contents_title .contents_info .title .share_wrap .share_pop .header h5 {
  font-size: 2rem;
}
.products_container .contents_wrap .contents_detail .contents_title .contents_info .title .share_wrap .share_pop .list_wrap {
  padding: 2.5rem 2rem;
}
.products_container .contents_wrap .contents_detail .contents_title .contents_info .title .share_wrap .share_pop .list_wrap ul {
  display: flex;
  justify-content: space-around;
}
.products_container .contents_wrap .contents_detail .contents_title .contents_info .title .share_wrap .share_pop .list_wrap ul li {
  color: #999;
  padding-top: 6rem;
  cursor: pointer;
  font-size: 1.4rem;
  text-align: center;
  min-width: 5rem;
  font-weight: 500;
}
.products_container .contents_wrap .contents_detail .contents_title .contents_info .title .share_wrap .share_pop .list_wrap ul li.kakao {
  background: url(../../assets/images/icon_kakao.png) no-repeat center top;
  background-size: 5rem 5rem;
}
.products_container .contents_wrap .contents_detail .contents_title .contents_info .title .share_wrap .share_pop .list_wrap ul li.facebook {
  background: url(../../assets/images/icon_facebook.png) no-repeat center top;
  background-size: 5rem 5rem;
}
.products_container .contents_wrap .contents_detail .contents_title .contents_info .title .share_wrap .share_pop .list_wrap ul li.link {
  background: url(../../assets/images/icon_link.png) no-repeat center top;
  background-size: 5rem 5rem;
}
.products_container .contents_wrap .contents_detail .contents_title .contents_info .title .share_wrap .share_pop .toast {
  height: 4.7rem;
  line-height: 4.7rem;
  width: 23rem;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  font-size: 1.4rem;
  font-weight: 500;
  text-align: center;
  border-radius: 10px;
  margin: 1rem auto;
}
.products_container .contents_wrap .contents_detail .contents_title .contents_info .title .share_wrap .share_pop.on {
  display: block;
}
.products_container .contents_wrap .contents_detail .contents_title .contents_info .contents {
  padding: 3rem 1.5rem;
  line-height: 2.6rem;
  width: 100%;
}
.products_container .contents_wrap .contents_detail .contents_title .contents_info .contents li {
  padding-left: 7px;
  position: relative;
}
.products_container .contents_wrap .contents_detail .contents_title .contents_info .contents li::before {
  content: "";
  display: block;
  width: 2px;
  height: 2px;
  border-radius: 50%;
  background: #000;
  position: absolute;
  top: 1.1rem;
  left: 0;
}
.products_container .contents_wrap .contents_detail .contents_title .contents_info .contents button {
  margin-top: 5rem;
}
@media (max-width: 1440px) {
  .products_container .contents_wrap .contents_detail .contents_title {
    align-items: flex-start;
  }
}
@media (max-width: 1200px) {
  .products_container .contents_wrap .contents_detail .contents_title {
    margin-bottom: 2rem;
    align-items: center;
  }
  .products_container .contents_wrap .contents_detail .contents_title .img_box {
    width: 50%;
  }
  .products_container .contents_wrap .contents_detail .contents_title .contents_info {
    width: 46%;
    padding-top: 0;
  }
  .products_container .contents_wrap .contents_detail .contents_title .contents_info .logo_wrap {
    margin-bottom: 1rem;
  }
}
.products_container .contents_wrap .contents_detail .detail_box {
  width: 100%;
  border-top: 2px solid #000;
  border-bottom: 1px solid #dedede;
  padding: 8rem 0;
  text-align: center;
}
.products_container .contents_wrap .view_content .title {
  border-top: 3px solid #000;
  border-bottom: 1px solid #000;
  padding: 2.5rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.products_container .contents_wrap .view_content .content {
  padding: 3.5rem 2rem;
  border-bottom: 1px solid #dedede;
}
.products_container .contents_wrap .view_content .content .text_box {
  padding-top: 3rem;
}
.products_container .contents_wrap .view_content .list_wrap {
  margin-bottom: 3rem;
}
.products_container .contents_wrap .view_content .list_wrap li {
  border-bottom: 1px solid #dedede;
  height: 6.9rem;
  display: flex;
  align-items: center;
  padding: 0 2rem;
  cursor: pointer;
}
.products_container .contents_wrap .view_content .list_wrap li i {
  width: 6rem;
  background: url(../../assets/images/icon_dropdown_arrow.svg) no-repeat center right;
}
.products_container .contents_wrap .view_content .list_wrap li span {
  flex: 1;
  padding: 0 4rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.products_container .contents_wrap .view_content .list_wrap li.prev i {
  background: url(../../assets/images/icon_dropdown_close.svg) no-repeat center right;
}
.products_container .contents_wrap .view_content .btn_wrap {
  text-align: right;
}
.products_container .contents_wrap .display_wrap {
  display: flex;
  flex-wrap: wrap;
  gap: 4rem 1.3rem;
  margin-bottom: 6rem;
}
.products_container .contents_wrap .display_wrap .box {
  width: 29rem;
  min-height: 33rem;
  cursor: pointer;
}
.products_container .contents_wrap .display_wrap .box .img_box {
  width: 18rem;
  height: 18rem;
  margin: 0 auto;
}
.products_container .contents_wrap .display_wrap .box .img_box img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.products_container .contents_wrap .display_wrap .box p {
  margin-top: 5rem;
  text-align: center;
  word-break: break-all;
}
@media (max-width: 1560px) {
  .products_container .contents_wrap .display_wrap .box {
    flex: 23%;
    min-height: initial;
  }
  .products_container .contents_wrap .display_wrap .box .img_box {
    height: auto;
  }
  .products_container .contents_wrap .display_wrap .box p {
    font-size: 1.4rem;
  }
}
.products_container .contents_wrap .bottom_center_btn {
  text-align: center;
}
.products_container .empty_data {
  padding: 17rem 0;
  text-align: center;
}
.products_container .empty_data p {
  padding: 11rem 0 3rem;
  font-size: 2.4rem;
  font-weight: bold;
  background: url(../../assets/images/ed_icon_07.svg) no-repeat center top;
}
.products_container .last::after {
  display: none;
}