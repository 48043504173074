.event_container {
  position: relative;
  width: 100%;
  max-width: 1920px;
  min-height: calc(100vh - 80px);
  margin: 0 auto;
  padding-top: 8rem;
  padding-left: 26rem;
}
.event_container .flex_row {
  display: flex;
  align-items: center;
  gap: 2rem;
}
.event_container .desc_s {
  font-size: 1.4rem;
  color: #999;
  line-height: 2.2rem;
}
.event_container .modal_title {
  margin-bottom: 1rem;
}
.event_container .modal_title h4 {
  font-size: 1.8rem;
  margin-bottom: 6px;
}
.event_container .modal_title p {
  color: #999;
}
.event_container .event_winner_content {
  padding: 2rem;
  background: #f7f9fa;
  height: 50rem;
  overflow-y: auto;
  margin-bottom: 2rem;
}
.event_container .table_wrap td button {
  color: #e60013;
  font-weight: 600;
  padding-right: 0;
}
@media (max-width: 1560px) {
  .event_container .table_wrap td {
    text-overflow: initial;
    overflow: initial;
    white-space: initial;
  }
}
.event_container .view_content .title {
  border-top: 3px solid #000;
  border-bottom: 1px solid #000;
  padding: 2.5rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.event_container .view_content .title h3 {
  font-size: 2.2rem;
}
.event_container .view_content .content {
  padding: 3.5rem 2rem;
  border-bottom: 1px solid #dedede;
}
.event_container .view_content .content .text_box {
  padding-top: 3rem;
}
.event_container .view_content .content .img_box {
  width: 100%;
}
.event_container .view_content .list_wrap {
  margin-bottom: 3rem;
}
.event_container .view_content .list_wrap li {
  border-bottom: 1px solid #dedede;
  height: 6.9rem;
  display: flex;
  align-items: center;
  padding: 0 2rem;
  cursor: pointer;
}
.event_container .view_content .list_wrap li i {
  width: 6rem;
  background: url(../../assets/images/icon_dropdown_arrow.svg) no-repeat center right;
}
.event_container .view_content .list_wrap li span {
  flex: 1;
  padding: 0 4rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.event_container .view_content .list_wrap li.prev i {
  background: url(../../assets/images/icon_dropdown_close.svg) no-repeat center right;
}
.event_container .view_content .btn_wrap {
  text-align: right;
}
.event_container .event_sub_nav {
  height: calc(100vh - 80px);
  position: absolute;
  background: #fff;
  width: 26rem;
  top: 8rem;
  left: 0;
  border-right: 1px solid #dedede;
  z-index: 1;
}
.event_container .event_sub_nav h3 {
  height: 5.5rem;
  line-height: 5.5rem;
  background: #000;
  color: #fff;
  font-size: 1.8rem;
  padding-left: 2.5rem;
}
.event_container .event_sub_nav nav li {
  height: 5rem;
  line-height: 4.9rem;
  border-bottom: 1px solid #dedede;
  color: #999;
}
.event_container .event_sub_nav nav li a {
  display: block;
  padding-left: 2rem;
}
.event_container .event_sub_nav nav :global .active {
  background-color: #f8fafa;
  color: #000;
  font-weight: bold;
}
.event_container section .event_contents {
  width: 100%;
  height: 100%;
  position: relative;
}
.event_container .contents_wrap {
  width: 100%;
  max-width: 135rem;
  padding: 6rem 5rem 15rem 10rem;
}
.event_container .contents_wrap .contents_title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 2rem;
  height: 3.9rem;
}
.event_container .contents_wrap .contents_title h3 {
  font-size: 2.4rem;
}
.event_container .contents_wrap .contents_title div {
  width: 29.5rem;
}
.event_container .contents_wrap .contents_title .desc {
  width: 100%;
  margin-top: 1rem;
}
.event_container .contents_wrap .contents_title .search_wrap {
  display: flex;
  justify-content: flex-end;
  position: relative;
}
.event_container .contents_wrap .contents_title .search_wrap input {
  width: 29.5rem;
  padding-right: 4rem;
}
.event_container .contents_wrap .contents_title .search_wrap button {
  position: absolute;
  top: 0.7rem;
  right: 1.5rem;
}
.event_container .contents_wrap .contents_title .filter {
  display: flex;
  gap: 2rem;
}
.event_container .contents_wrap .contents_title .filter li {
  color: #999999;
  cursor: pointer;
}
.event_container .contents_wrap .contents_title .filter li.on {
  font-weight: 600;
  color: #000;
  text-decoration: underline;
  text-underline-position: under;
}
.event_container .contents_wrap .bottom_btn_wrap {
  margin-top: 4rem;
  text-align: center;
}
@media (max-width: 1560px) {
  .event_container .contents_wrap {
    padding: 1.5vw 2.5vw 7.813vw 2.5vw;
  }
}
.event_container .event_board {
  display: flex;
  gap: 4rem 2rem;
  flex-wrap: wrap;
}
.event_container .event_board .board {
  flex: calc(50% - 1rem);
  cursor: pointer;
}
.event_container .event_board .board .img_box {
  margin-bottom: 2rem;
  width: 100%;
  height: 26.7rem;
}
.event_container .event_board .board .img_box img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.event_container .event_board .board h4 {
  font-size: 1.8rem;
  margin-bottom: 6px;
}
.event_container .event_board .board p {
  color: #999;
}
.event_container .empty_data {
  padding: 13rem 0 0.5rem 0;
  text-align: center;
  margin: 0 auto;
}
.event_container .empty_data p {
  padding: 11rem 0 3rem;
  font-size: 2.4rem;
  font-weight: bold;
  background: url(../../assets/images/ed_icon_07.svg) no-repeat center top;
}
.event_container .tab_menu {
  width: 100%;
  display: flex;
  margin-bottom: 2rem;
}
.event_container .tab_menu li {
  width: 50%;
  height: 5rem;
  line-height: 5rem;
  background: #f7f9fa;
  color: #999;
  font-weight: 500;
  text-align: center;
  cursor: pointer;
}
.event_container .tab_menu li.on {
  background: #e60013;
  color: #fff;
  font-weight: bold;
}
.event_container .tab_contents_wrap {
  padding-top: 4rem;
}
.event_container .tab_contents_wrap .faq_wrap {
  margin-bottom: 4rem;
}
.event_container .tab_contents_wrap .result_wrap h4 {
  font-size: 1.8rem;
  margin-bottom: 1rem;
}
.event_container .tab_contents_wrap .result_wrap button {
  display: block;
  margin: 4rem auto;
}