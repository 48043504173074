.footer {
  background: #000;
  color: #fff;
  min-width: 960px;
  width: 100%;
  min-height: 27rem;
  position: relative;
  z-index: 10;
}
.footer .product_wrap {
  width: 100%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.footer .product_wrap ul {
  display: flex;
  padding: 3rem 0;
  max-width: 1360px;
  margin: 0 auto;
  align-items: center;
  justify-content: space-between;
}
.footer .product_wrap ul li {
  width: 12rem;
  height: 9rem;
  text-indent: -9999px;
  background: url(../assets/images/logo_01.svg) no-repeat center;
}
.footer .product_wrap ul li:nth-child(2) {
  background: url(../assets/images/logo_02.svg) no-repeat center;
}
.footer .product_wrap ul li:nth-child(3) {
  background: url(../assets/images/logo_03.svg) no-repeat center;
}
.footer .product_wrap ul li:nth-child(4) {
  background: url(../assets/images/logo_04.svg) no-repeat center;
}
.footer .product_wrap ul li:nth-child(5) {
  background: url(../assets/images/logo_05.svg) no-repeat center;
}
.footer .product_wrap ul li:nth-child(6) {
  background: url(../assets/images/logo_06.svg) no-repeat center;
}
.footer .footer_wrap {
  display: flex;
  padding: 4.85rem 0;
  max-width: 1360px;
  margin: 0 auto;
  position: relative;
  gap: 8rem;
}
.footer .related_site {
  position: absolute;
  right: 3rem;
  top: 4rem;
}
.footer h2 {
  width: 9rem;
  height: 11.5rem;
  font-size: 0;
  text-indent: -9999px;
  background: url(../assets/images/logo_footer.png) no-repeat center;
  background-size: contain;
}
.footer .nav {
  margin-bottom: 3.5rem;
}
.footer .nav ul {
  display: flex;
  gap: 50px;
  font-size: 2rem;
  font-weight: 600;
}
.footer .info_company ul {
  display: flex;
  gap: 1.5rem 3.1rem;
  font-size: 1.6rem;
  margin-bottom: 1.5rem;
}
.footer .info_company ul:last-child {
  margin-bottom: 0;
}
.footer .info_company li {
  position: relative;
}
.footer .info_company li::after {
  content: "";
  width: 1px;
  height: 1.1rem;
  background: #fff;
  display: block;
  position: absolute;
  top: 0.4rem;
  right: -1.6rem;
}
.footer .info_company li:last-child::after {
  display: none;
}
.footer .copy_wrap {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  padding: 2rem 0;
  font-size: 1.4rem;
  color: rgba(255, 255, 255, 0.5);
}
.footer .copy_wrap p {
  padding-left: 17rem;
  max-width: 1360px;
  margin: 0 auto;
}

.main_footer .info_company .copy_wrap {
  border-top: 0;
  padding-top: 5px;
}
.main_footer .info_company .copy_wrap p {
  padding-left: 0;
  max-width: 100%;
}

@media (max-width: 1360px) {
  .footer .footer_wrap {
    width: 100%;
    padding-left: 5rem;
    padding-right: 5rem;
    gap: 3rem;
  }
  .footer .copy_wrap p {
    padding-left: 19rem;
  }
  .footer .related_site {
    right: 1rem;
  }
}
@media (max-width: 1000px) {
  .footer .footer_wrap {
    width: 100%;
    padding-left: 2rem;
  }
  .footer .footer_wrap .info_company ul {
    flex-wrap: wrap;
    gap: 1rem 3.1rem;
  }
}
@media (max-width: 959px) {
  .footer .footer_wrap .footer_logo {
    display: none;
  }
  .footer .footer_wrap .related_site {
    display: none;
  }
}