.header {
  background: #fff;
  width: 100%;
  min-width: 960px;
  height: 8rem;
  position: fixed;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
  z-index: 10;
}
.header .header_wrap {
  display: flex;
  gap: 2rem;
  padding: 0 0 0 3rem;
  align-items: center;
  max-width: 1920px;
  margin: 0 auto;
}
.header .header_wrap .header_logo {
  text-indent: -9999px;
  font-size: 0;
  width: 12.8rem;
  height: 8rem;
  background: url(../assets/images/logo_gnb_b.png) no-repeat;
  background-position: center 0.5rem;
  background-size: contain;
}
.header .header_wrap .header_logo a {
  display: block;
  text-indent: -9999px;
  font-size: 0;
  width: 100%;
  height: 100%;
}
.header .header_wrap .header_tournament_logo {
  font-size: 2rem;
  position: relative;
  padding-left: 1.6rem;
}
.header .header_wrap .header_tournament_logo span {
  color: #fff;
}
.header .header_wrap .header_tournament_logo::before {
  content: "";
  display: block;
  position: absolute;
  top: -0.6rem;
  left: 0rem;
  width: 3.6rem;
  height: 3.6rem;
  background: #3e21d9;
  border-radius: 1.8rem;
  z-index: -1;
}
.header .mainNav {
  margin-left: auto;
  height: 8rem;
  line-height: 7.4rem;
}
.header .mainNav ul {
  display: flex;
  gap: 2rem;
  font-size: 1.6rem;
  font-weight: 600;
}
.header .mainNav ul li a {
  padding: 0 1rem;
  display: block;
  border-top: 6px solid transparent;
  transition: border 0.2s ease;
}
.header .mainNav ul li :global .active {
  border-color: #3e21d9;
  color: #3e21d9;
}
.header .toggle_home {
  cursor: pointer;
}
.header .toggle_home ul {
  display: flex;
  color: #000;
  font-size: 1.6rem;
  height: 3.8rem;
  line-height: 3.8rem;
  border-radius: 1.9rem;
  font-weight: 500;
  background: #f7f9fa;
}
.header .toggle_home ul li {
  text-align: center;
  transition: all 0.3s;
  color: #fff;
  background: #000;
  border-radius: 2.1rem;
  background: #000 url(../assets/images/icon_btn_text_w.svg) no-repeat center right 2rem;
  background-size: 10px 10px;
}
.header .toggle_home ul li a {
  width: 100%;
  display: block;
  padding: 0 4rem 0 2rem;
}
.header .toggle_home ul li.on {
  color: #fff;
  background: #000;
  border-radius: 2rem;
}
.header .go_mall {
  width: 8rem;
  height: 8rem;
  background: #000 url(../assets/images/icon_cart02.svg) no-repeat center;
  cursor: pointer;
  color: #fff;
  background: #3e21d9;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

.admin_header {
  background: #3e21d9;
  height: 7rem;
  box-shadow: none;
}
.admin_header .header_wrap {
  height: 100%;
  padding: 0 2rem 0 2.7rem;
}
.admin_header .admin_header_logo {
  text-indent: -9999px;
  width: 12.7rem;
  height: 5.2rem;
  background: url(../assets/images/logo_admin.png) no-repeat;
  background-size: 100%;
}
.admin_header .admin_nav {
  margin: 0 auto;
}
.admin_header .admin_nav ul {
  display: flex;
  gap: 3.5rem;
}
.admin_header .admin_nav ul li a {
  color: #fff;
  opacity: 0.4;
  display: block;
  height: 7rem;
  line-height: 7rem;
  position: relative;
}
.admin_header .admin_nav ul li a::before {
  content: "";
  display: block;
  position: absolute;
  border-bottom: 9px solid #f0f2f4;
  border-top: 9px solid transparent;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: all 0.3s;
}
.admin_header .admin_nav ul li :global .active {
  opacity: 1;
  font-weight: bold;
}
.admin_header .admin_nav ul li :global .active::before {
  opacity: 1;
}
.admin_header .admin_user_info {
  display: flex;
  color: #fff;
  align-items: center;
  gap: 1rem;
}
.admin_header .admin_user_info .user {
  display: flex;
  gap: 1rem;
  align-items: center;
}
.admin_header .admin_user_info .user .profile {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  background: #000;
  display: block;
}
.admin_header .admin_user_info .user b {
  font-weight: 600;
}

.main_header {
  box-shadow: none;
  border-bottom: 1px solid #dedede;
}
.main_header .header_wrap {
  gap: 5rem;
}
.main_header .tournament_nav {
  position: relative;
  width: 12rem;
  height: 3.8rem;
}
.main_header .tournament_nav p {
  width: 12rem;
  height: 3.8rem;
  line-height: 3.8rem;
  border-radius: 1.9rem;
  color: #fff;
  padding: 0 2rem;
  background: #000 url(../assets/images/dropdown_down_w.svg) no-repeat center right 2rem;
  position: absolute;
  top: 0;
  z-index: 2;
  cursor: pointer;
}
.main_header .tournament_nav ul {
  position: absolute;
  top: 1.9rem;
  background: #000;
  border-radius: 0 0 1.9rem 1.9rem;
  overflow: hidden;
  height: 0;
  transition: all 0.2s;
  padding-top: 1.9rem;
  z-index: 1;
}
.main_header .tournament_nav ul li {
  cursor: pointer;
  background: #000;
  color: #fff;
  width: 12rem;
  height: 2.6rem;
  line-height: 2.6rem;
  padding: 0 2rem;
}
.main_header .tournament_nav.on p {
  background: #000 url(../assets/images/dropdown_up_w.svg) no-repeat center right 2rem;
}
.main_header .tournament_nav.on ul {
  top: 1.9rem;
  height: 13rem;
  padding: 1.9rem 0 2rem 0;
}
.main_header .go_mall {
  background: #e60013;
}
.main_header .go_mall.tournament_st {
  background: #000;
}
.main_header .mainNav {
  margin: 0 auto;
  flex: 1;
  max-width: 104rem;
}
.main_header .mainNav ul {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.main_header .mainNav ul li :global .active {
  border-color: transparent;
  color: #e60013;
}
.main_header .mainNav.tournament_st {
  max-width: initial;
}
.main_header .mainNav.tournament_st ul {
  justify-content: flex-end;
  display: flex;
  gap: 2rem;
  font-size: 1.6rem;
  font-weight: 600;
}
.main_header .mainNav.tournament_st ul li a {
  padding: 0 1rem;
  display: block;
  border-top: 6px solid transparent;
  transition: border 0.2s ease;
  line-height: 7rem;
}
.main_header .mainNav.tournament_st ul li :global .active {
  border-color: #3e21d9;
  color: #3e21d9;
}
.main_header .login_wrap {
  height: 8rem;
  line-height: 8rem;
}
.main_header .login_wrap ul {
  display: flex;
  gap: 3rem;
}
.main_header .login_wrap li {
  height: 100%;
  list-style: none;
}
.main_header .login_wrap li a {
  display: block;
  font-size: 1.4rem;
  font-weight: 500;
  color: #999;
  padding-left: 2rem;
}
.main_header .login_wrap li.login a {
  background: url(../assets/images/gnb_icon_login.svg) no-repeat center left;
}
.main_header .login_wrap li.login :global .active {
  color: #e60013;
  background: url(../assets/images/gnb_icon_login_s.svg) no-repeat center left;
}
.main_header .login_wrap li.logout a {
  background: url(../assets/images/gnb_icon_logout_01.svg) no-repeat center left;
}
.main_header .login_wrap li.mypage a {
  background: url(../assets/images/gnb_icon_mypage.svg) no-repeat center left;
}
.main_header .login_wrap li.mypage :global .active {
  color: #e60013;
  background: url(../assets/images/gnb_icon_mypage_s.svg) no-repeat center left;
}
@media (max-width: 1440px) {
  .main_header .header_wrap {
    gap: 1rem;
  }
  .main_header .header_wrap .header_logo {
    width: 4rem;
    background: url(../assets/images/app_gnb_logo.png) no-repeat center;
    background-size: contain;
  }
  .main_header .header_wrap .mainNav {
    flex: initial;
  }
  .main_header .header_wrap .mainNav.tournament_st {
    flex: 1;
  }
  .main_header .header_wrap .login_wrap ul {
    gap: 1rem;
  }
}
@media (max-width: 1200px) {
  .main_header .header_wrap .mainNav ul {
    gap: 0;
  }
  .main_header .header_wrap .mainNav.tournament_st ul {
    gap: 2rem;
  }
  .main_header .header_wrap .login_wrap ul li a {
    width: 2rem;
    background-position: center;
    text-indent: -9999px;
  }
}
@media (max-width: 1050px) {
  .main_header .header_wrap .login_wrap ul li a {
    width: 2rem;
    background-position: center;
    text-indent: -9999px;
  }
}