.admin_container {
  position: relative;
  width: 100%;
  max-width: 1920px;
  min-width: 1900px;
  min-height: 100vh;
  margin: 0 auto;
  padding-top: 7rem;
  padding-left: 24rem;
  background: #f0f2f4;
}
.admin_container .admin_sub_nav {
  height: calc(100% - 70px);
  position: absolute;
  background: #fff;
  width: 20rem;
  padding: 3rem 0;
  margin-right: 4rem;
  top: 7rem;
  left: 0;
}
.admin_container .admin_sub_nav .depth_1 > li {
  margin-bottom: 1.1rem;
  font-size: 1.8rem;
  font-weight: bold;
}
.admin_container .admin_sub_nav .depth_1 > li a {
  display: block;
  min-height: 3.9rem;
  line-height: 2.6rem;
  padding: 5px 0 5px 30px;
}
.admin_container .admin_sub_nav .depth_1 > li.depth_1_link :global .active {
  font-weight: bold;
  color: #3e21d9;
  background: #f0f2f4;
  border-left: 4px solid #4d2fed;
  padding-left: 2.6rem;
}
.admin_container .admin_sub_nav .depth_2 > li {
  font-size: 1.6rem;
  font-weight: 400;
}
.admin_container .admin_sub_nav .depth_2 > li :global .active {
  font-weight: bold;
  color: #3e21d9;
  background: #f0f2f4;
  border-left: 4px solid #4d2fed;
  padding-left: 2.6rem;
}
.admin_container section .admin_contents {
  width: 100%;
  height: 100%;
  padding: 4rem 4rem 5rem 0;
  position: relative;
  background: #f0f2f4;
}
.admin_container section .admin_contents h3 {
  font-size: 1.8rem;
  padding-left: 1.7rem;
  position: relative;
  margin-bottom: 1.5rem;
}
.admin_container section .admin_contents h3::before {
  content: "";
  display: block;
  position: absolute;
  top: 2px;
  left: 5px;
  width: 4px;
  height: 16px;
  background: #3e21d9;
}
.admin_container section .admin_contents .desc {
  font-size: 1.4rem;
  color: #3e21d9;
  position: absolute;
  top: 4.2rem;
  right: 4.5rem;
}
.admin_container section .admin_contents .contents_wrap {
  background: #fff;
  width: 100%;
  border-radius: 1.5rem;
  padding: 3rem 4rem 2rem 4rem;
  position: relative;
}
.admin_container section .admin_contents .contents_wrap.search_wrap {
  margin-bottom: 1rem;
  padding: 4rem 4rem 2rem 4rem;
}
.admin_container section .admin_contents .contents_wrap.search_wrap .desc_gray {
  margin-top: 1.5rem;
}
.admin_container section .admin_contents .contents_wrap.search_wrap td b {
  font-weight: 600;
}
.admin_container section .admin_contents .contents_wrap.search_wrap td .flex_row div:nth-child(2) {
  flex: 1;
}
.admin_container section .admin_contents .contents_wrap.search_wrap td .flex_row.multi div:nth-child(2) {
  flex: inherit;
  margin-left: 1rem;
}
.admin_container section .admin_contents .contents_wrap.search_wrap td .flex_row:nth-child(2) {
  margin-top: 1rem;
}
.admin_container section .admin_contents .contents_wrap.search_wrap .admin_table_wrap table td {
  overflow: initial;
  border-left: 1px solid #dedede;
}
.admin_container section .admin_contents .contents_wrap.search_wrap .admin_table_wrap table td:first-child {
  border-left: 0;
}
.admin_container section .admin_contents .contents_wrap.search_wrap .admin_table_wrap table tr {
  cursor: default;
}
.admin_container section .admin_contents .contents_wrap .counter {
  font-size: 1.4rem;
  margin-bottom: 1.3rem;
}
.admin_container section .admin_contents .contents_wrap .counter b {
  color: #3e21d9;
}
.admin_container section .admin_contents .bottom_btn_wrap {
  display: flex;
  justify-content: flex-end;
  padding-top: 1.5rem;
  gap: 5px;
}
.admin_container section .admin_contents .bottom_btn_wrap.position_st {
  position: absolute;
  bottom: 2rem;
  right: 4rem;
}
.admin_container section .admin_contents .bottom_btn_wrap.position_st input[type=file] {
  display: none;
}
.admin_container section .admin_contents .bottom_btn_wrap.multi {
  justify-content: flex-start;
}
.admin_container section .admin_contents .bottom_btn_wrap.multi .left {
  display: flex;
  gap: 5px;
  margin-right: auto;
}
.admin_container section .admin_contents .bottom_btn_wrap.multi .right {
  display: flex;
  gap: 5px;
  margin-left: auto;
}
.admin_container section .admin_contents .bottom_btn_wrap.multi .center {
  margin: 0 auto;
}
.admin_container section .admin_contents .bottom_center_btn {
  text-align: center;
  margin: 2rem auto;
}
.admin_container h4 {
  font-size: 1.8rem;
  margin-bottom: 1rem;
}

.bottom_btn_wrap {
  display: flex;
  justify-content: flex-end;
  gap: 5px;
}

td .flex_row:nth-child(2) {
  margin-top: 5px;
}
td .flex_col {
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: center;
  align-items: center;
}

.desc_gray {
  font-size: 1.4rem;
  color: #999;
}

.product_scroll_box {
  border: 1px solid #dedede;
  padding: 1rem;
  margin-bottom: 2rem;
}
.product_scroll_box div {
  height: 11rem;
  overflow-y: auto;
}
.product_scroll_box div ul {
  padding: 0 1rem;
}
.product_scroll_box div ul li {
  line-height: 2.6rem;
}

.category_select_wrap .category_wrap {
  display: flex;
  justify-content: space-between;
}
.category_select_wrap .category_wrap .category_box {
  width: 25rem;
  height: 23rem;
  position: relative;
}
.category_select_wrap .category_wrap .category_box h6 {
  width: 100%;
  height: 2.7rem;
  background: #000;
  color: #fff;
  margin: 0;
  font-size: 1.4rem;
  padding-left: 2rem;
  line-height: 2.7rem;
}
.category_select_wrap .category_wrap .category_box .scroll_box {
  border: 1px solid #dedede;
  border-top: 0;
  height: 20.5rem;
  padding: 5px 0px 5px 0;
  overflow-y: auto;
}
.category_select_wrap .category_wrap .category_box .scroll_box ul {
  padding: 5px 0;
}
.category_select_wrap .category_wrap .category_box .scroll_box ul li {
  height: 3.1rem;
  line-height: 3.1rem;
  color: #999;
  padding-left: 2rem;
  cursor: pointer;
}
.category_select_wrap .category_wrap .category_box .scroll_box ul li.on {
  background: #f7f9fa;
  color: #3e21d9;
  font-weight: 600;
}
.category_select_wrap .category_wrap .category_box.dep3 .scroll_box ul li {
  color: #000;
}
.category_select_wrap .category_wrap .category_box.dep3 .scroll_box ul li.on {
  background: #f7f9fa;
  color: #3e21d9;
  font-weight: 600;
}
.category_select_wrap .category_wrap .category_box.dep3::after {
  display: none;
}
.category_select_wrap .category_wrap .category_box::after {
  content: "";
  width: 3rem;
  height: 3rem;
  background: url(../../assets/images/next_arrow.svg) no-repeat center;
  display: block;
  position: absolute;
  top: 114px;
  right: -38px;
}

.file_list_wrap .file_list {
  margin-top: 1rem;
}
.file_list_wrap .file_list li {
  margin-bottom: 5px;
}
.file_list_wrap .file_list span {
  padding-right: 1rem;
}

.main_col2 {
  display: flex;
  gap: 2rem;
}
.main_col2 .category_box_wrap {
  width: 30.5rem !important;
}
.main_col2 .category_box_wrap .btn_wrap {
  margin-bottom: 1rem;
  display: flex;
  gap: 5px;
  justify-content: center;
}
.main_col2 .category_box_wrap .category_box {
  padding: 3rem !important;
}
.main_col2 .category_box_wrap .category_box .category {
  margin-bottom: 3rem;
  max-height: 55rem;
  overflow-y: auto;
}
.main_col2 .category_box_wrap .category_box .category .dep1 > li {
  position: relative;
}
.main_col2 .category_box_wrap .category_box .category .dep1 > li span {
  display: inline-block;
  font-weight: bold;
  cursor: pointer;
  position: relative;
}
.main_col2 .category_box_wrap .category_box .category .dep1 > li.none_sub > span {
  background: none;
}
.main_col2 .category_box_wrap .category_box .category .dep1 > li.none_sub > span::after {
  content: "";
  display: block;
  position: absolute;
  width: 1rem;
  height: 1px;
  background: #dedede;
  top: 8px;
  left: 7px;
}
.main_col2 .category_box_wrap .category_box .category .dep1 > li.disabled {
  opacity: 0.3;
  cursor: default;
}
.main_col2 .category_box_wrap .category_box .category .dep1 > li::before {
  content: "";
  display: block;
  position: absolute;
  width: 1px;
  height: 100%;
  background: #dedede;
  top: 9px;
  left: 7px;
}
.main_col2 .category_box_wrap .category_box .category .dep1 > li > span {
  padding-left: 2.3rem;
  margin-bottom: 1.5rem;
  background: url(../../assets/images/icon_category_close.svg) no-repeat left center;
}
.main_col2 .category_box_wrap .category_box .category .dep1 > li.on > span {
  background: url(../../assets/images/icon_category_open.svg) no-repeat left center;
}
.main_col2 .category_box_wrap .category_box .category .dep1 > li.on > span::after {
  content: "";
  display: block;
  position: absolute;
  width: 1px;
  height: 20px;
  background: #dedede;
  top: 25px;
  left: 29px;
}
.main_col2 .category_box_wrap .category_box .category .dep1 > li.on > .dep2 {
  display: block;
}
.main_col2 .category_box_wrap .category_box .category .dep1 > li:last-child::before {
  display: none;
}
.main_col2 .category_box_wrap .category_box .category .dep2 {
  display: none;
}
.main_col2 .category_box_wrap .category_box .category .dep2 > li > span {
  font-size: 1.4rem;
  margin-bottom: 1rem;
  padding-left: 4.6rem;
  background: url(../../assets/images/icon_category_close.svg) no-repeat left 2.2rem center;
}
.main_col2 .category_box_wrap .category_box .category .dep2 > li > span::before {
  content: "";
  display: block;
  position: absolute;
  width: 1px;
  height: 2.2rem;
  left: 2.9rem;
  top: 16px;
  background: #dedede;
}
.main_col2 .category_box_wrap .category_box .category .dep2 > li:last-child > span::before {
  display: none;
}
.main_col2 .category_box_wrap .category_box .category .dep2 > li.on > .dep3 {
  display: block;
}
.main_col2 .category_box_wrap .category_box .category .dep2 > li.on:last-child > span::before {
  display: block;
}
.main_col2 .category_box_wrap .category_box .category .dep2 > li.on:last-child > .dep3 li:last-child > span::before {
  display: none;
}
.main_col2 .category_box_wrap .category_box .category .dep2 > li.none_sub > span {
  background: none;
}
.main_col2 .category_box_wrap .category_box .category .dep2 > li.none_sub > span::after {
  content: "";
  display: block;
  position: absolute;
  width: 1rem;
  height: 1px;
  background: #dedede;
  top: 8px;
  left: 30px;
}
.main_col2 .category_box_wrap .category_box .category .dep2 > li.none_sub > span::before {
  height: 29px;
  left: 2.9rem;
  top: 8px;
}
.main_col2 .category_box_wrap .category_box .category .dep3 {
  display: none;
}
.main_col2 .category_box_wrap .category_box .category .dep3 > li span {
  font-weight: 400;
  font-size: 1.4rem;
  margin-bottom: 1rem;
  padding-left: 4.6rem;
}
.main_col2 .category_box_wrap .category_box .category .dep3 > li span::before {
  content: "";
  display: block;
  position: absolute;
  width: 1px;
  height: 2.9rem;
  left: 2.9rem;
  top: 8px;
  background: #dedede;
}
.main_col2 .category_box_wrap .category_box .category .dep3 > li span::after {
  content: "";
  display: block;
  position: absolute;
  width: 1rem;
  height: 1px;
  background: #dedede;
  top: 8px;
  left: 2.9rem;
}
.main_col2 .category_box_wrap .category_box .desc_gray {
  margin-top: 1.5rem;
}
.main_col2 .category_box_wrap .category_box .arrow_wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 1rem;
}
.main_col2 .category_box_wrap .category_box .arrow_wrap li {
  position: relative;
  width: 2.4rem;
  height: 2.4rem;
  cursor: pointer;
  background-color: lightblue;
  text-indent: -999999px;
  background: url(../../assets/images/icon_dropdown_arrow.svg) no-repeat center;
  background-size: 1.6rem;
}
.main_col2 .category_box_wrap .category_box .arrow_wrap li:nth-child(1)::after {
  content: "";
  display: block;
  position: absolute;
  bottom: 2px;
  left: 50%;
  transform: translateX(-50%);
  width: 1.6rem;
  height: 2px;
  background: #000;
}
.main_col2 .category_box_wrap .category_box .arrow_wrap li:nth-child(3) {
  transform: rotate(180deg);
}
.main_col2 .category_box_wrap .category_box .arrow_wrap li:nth-child(4) {
  transform: rotate(180deg);
}
.main_col2 .category_box_wrap .category_box .arrow_wrap li:nth-child(4)::after {
  content: "";
  display: block;
  position: absolute;
  bottom: 3px;
  left: 50%;
  transform: translateX(-50%);
  width: 1.6rem;
  height: 2px;
  background: #000;
}
.main_col2 .search_wrap {
  width: 131rem !important;
  margin-top: 3.7rem;
  min-height: 56.2rem;
}
.main_col2 .search_wrap .img_sm_box {
  height: auto;
}
.main_col2 .search_wrap .img_sm_box img {
  width: 140px;
  height: 85px;
}

.event_banner_img {
  max-width: 1000px;
  height: auto;
}

.right_btn_wrap {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;
  margin-left: auto;
}
.right_btn_wrap button:last-child {
  margin-left: 10px;
}

.modal_nav {
  width: 100%;
  display: flex;
  margin: 2rem 0 2rem;
}
.modal_nav li {
  flex: 1;
  height: 4.1rem;
  line-height: 4.1rem;
  background: #f7f9fa;
  color: #999;
  font-weight: 500;
  text-align: center;
  cursor: pointer;
}
.modal_nav li.on {
  background: #3e21d9;
  color: #fff;
  font-weight: 600;
}

.position_left_btn {
  position: absolute;
  bottom: 3rem;
  left: 3rem;
  gap: 1rem;
  display: flex;
}

.btn_center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.counter {
  font-size: 1.4rem;
  margin: 2rem 0 1rem;
}
.counter b {
  color: #3e21d9;
}

.date_pick_row {
  display: flex;
  align-items: center;
  gap: 3rem;
}
.date_pick_row span {
  min-width: 12rem;
}

.dnd_table_wrap ul {
  display: flex;
  text-align: center;
  align-items: center;
}
.dnd_table_wrap ul li {
  width: 11rem;
}
.dnd_table_wrap ul li:nth-child(3) {
  width: 25rem;
}
.dnd_table_wrap ul li:nth-child(4) {
  width: 64rem;
}
.dnd_table_wrap ul li:nth-child(5), .dnd_table_wrap ul li:nth-child(6), .dnd_table_wrap ul li:nth-child(7) {
  width: 15rem;
}
.dnd_table_wrap.staff_st ul {
  cursor: pointer;
}
.dnd_table_wrap.staff_st ul li {
  width: 13rem;
}
.dnd_table_wrap.staff_st ul li:nth-child(1) {
  width: 11rem;
}
.dnd_table_wrap.staff_st ul li:nth-child(4) {
  width: 25rem;
}
.dnd_table_wrap.staff_st ul li:nth-child(4) img {
  -o-object-fit: contain !important;
     object-fit: contain !important;
}
.dnd_table_wrap.staff_st ul li:nth-child(5), .dnd_table_wrap.staff_st ul li:nth-child(6) {
  width: 20rem;
}
.dnd_table_wrap.staff_st ul li:nth-child(7) {
  width: 13rem;
}
.dnd_table_wrap.staff_st .header {
  cursor: default;
}
.dnd_table_wrap .header {
  display: flex;
  border-top: 2px solid #000;
  border-bottom: 1px solid #dedede;
  font-weight: bold;
  height: 5.9rem;
  line-height: 5.7rem;
  background: #f7f9fa;
  text-align: center;
}
.dnd_table_wrap .list_wrap ul.list {
  height: 14rem;
  border-bottom: 1px solid #dedede;
}
.dnd_table_wrap .list_wrap ul.list li.btn_move {
  height: 100%;
  background-size: 22px auto;
  background: url(../../assets/images/icon_drag.svg) no-repeat center;
  cursor: pointer;
}
.dnd_table_wrap .list_wrap ul.list li:nth-child(4) {
  padding: 0 3rem;
  text-align: left;
  cursor: pointer;
}
.dnd_table_wrap .list_wrap ul.list li img {
  width: 20rem;
  height: 12rem;
  -o-object-fit: cover;
     object-fit: cover;
}
.dnd_table_wrap .list_wrap ul.list li a {
  display: block;
  width: 100%;
  height: 13.9rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.dnd_table_wrap .list_wrap ul.list.on {
  border: 2px solid #3e21d9;
  background: #fff;
  box-shadow: 5px 15px 30px 0 rgba(18, 19, 43, 0.12);
}
.dnd_table_wrap.product_st ul li {
  text-align: center;
  height: 5.9rem;
}
.dnd_table_wrap.product_st ul li:nth-child(1), .dnd_table_wrap.product_st ul li:nth-child(2) {
  width: 10%;
}
.dnd_table_wrap.product_st ul li:nth-child(3) {
  width: 27%;
}
.dnd_table_wrap.product_st ul li:nth-child(4) {
  width: 37%;
}
.dnd_table_wrap.product_st ul li:nth-child(5) {
  width: 15%;
}
.dnd_table_wrap.product_st .list_wrap ul.list {
  height: 6rem;
  line-height: 6rem;
}
.dnd_table_wrap.product_st .list_wrap ul.list li {
  text-align: center;
}

.admin_table_wrap h4 {
  margin-top: 3rem;
}
.admin_table_wrap h5 {
  font-size: 1.8rem;
  margin-bottom: 1rem;
}
.admin_table_wrap table th,
.admin_table_wrap table td {
  overflow: initial;
  text-overflow: initial;
  white-space: initial;
  word-break: break-all;
}
.admin_table_wrap tr {
  cursor: default;
}
.admin_table_wrap tr.th_st th {
  text-align: center;
}
.admin_table_wrap tr.th_st th:first-child {
  text-align: left;
}
.admin_table_wrap tr.th_st th:first-child[colspan="5"] {
  text-align: center;
}
.admin_table_wrap tr.th_st th:last-child {
  border-right: 0;
}
.admin_table_wrap thead th {
  background: #f7f9fa;
  border-bottom: 1px solid #dedede;
}
.admin_table_wrap tbody th {
  background: #f7f9fa;
  vertical-align: middle;
  border-left: 1px solid #dedede;
  border-right: 1px solid #dedede;
}
.admin_table_wrap tbody th:nth-child(1) {
  border-left: 0;
}
.admin_table_wrap tbody th:last-child {
  border-right: 0;
}
.admin_table_wrap tbody td {
  padding: 7px 10px;
  line-height: 2rem;
}
.admin_table_wrap tbody td .desc {
  position: initial !important;
  display: inline-block;
}
.admin_table_wrap tbody td .flex_row {
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: flex-start;
}
.admin_table_wrap tbody td.align_center {
  text-align: center;
}
.admin_table_wrap tbody td table {
  margin: 1rem 0;
}
.admin_table_wrap tbody td table th {
  border-left: 0;
  border-right: 0;
}
.admin_table_wrap .img_sm_box {
  max-height: 26rem;
  display: flex;
  align-items: flex-end;
  gap: 1.4rem;
}
.admin_table_wrap .img_sm_box img {
  width: 43rem;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.admin_table_wrap .thumbnail_box {
  display: flex;
  align-items: flex-end;
  gap: 1rem;
}
.admin_table_wrap .thumbnail_box img {
  width: 14rem;
  height: 8.5rem;
  -o-object-fit: cover;
     object-fit: cover;
}
.admin_table_wrap .profile_img_box img {
  width: 19rem;
  height: auto;
  -o-object-fit: cover;
     object-fit: cover;
}
.admin_table_wrap.align_center th,
.admin_table_wrap.align_center td {
  padding: 7px 5px;
  text-align: center;
}
.admin_table_wrap.align_center.left_col_2 table tr td:nth-child(2) {
  text-align: left;
  cursor: pointer;
}
.admin_table_wrap.align_center.left_col_3 table tr td:nth-child(3) {
  text-align: left;
  cursor: pointer;
}
.admin_table_wrap.align_center.left_col_4 table tr td:nth-child(4) {
  text-align: left;
}
.admin_table_wrap.border_none th,
.admin_table_wrap.border_none td {
  border-left: none !important;
  border-right: none !important;
}
.admin_table_wrap .left_col_1 tr td:nth-child(1) {
  text-align: left;
}
.admin_table_wrap .left_col_2 tr td:nth-child(2) {
  text-align: left;
}
.admin_table_wrap .left_col_3 tr td:nth-child(3) {
  text-align: left;
}
.admin_table_wrap .left_col_4 tr td:nth-child(4) {
  text-align: left;
}
.admin_table_wrap .left_col_5 tr td:nth-child(5) {
  text-align: left;
}
.admin_table_wrap .left_col_8 tr td:nth-child(8) {
  text-align: left;
}
.admin_table_wrap.row_select table tbody tr {
  cursor: pointer;
}
.admin_table_wrap .position_left_btn {
  left: 4rem;
  bottom: 2rem;
}
.admin_table_wrap.fixed_st .counter {
  margin-top: 0;
}
.admin_table_wrap.fixed_st .scroll_box {
  max-height: 46rem;
  overflow-y: auto;
  position: relative;
  border-top: 2px solid #000;
}
.admin_table_wrap.fixed_st table {
  border-top: 0;
  margin-bottom: 0 !important;
}
.admin_table_wrap.fixed_st table td {
  border-left: 0 !important;
}
.admin_table_wrap.fixed_st table thead tr th {
  position: sticky;
  top: 0;
  border-bottom: 0;
}

.info_box {
  padding: 2rem 0;
  font-weight: 500;
}
.info_box p {
  margin-bottom: 1rem;
}
.info_box ul {
  margin-bottom: 2rem;
}
.info_box ul li {
  padding-left: 1.2rem;
  line-height: 2.6rem;
}

.radio_wrap {
  display: flex;
  gap: 3rem;
  align-items: center;
}

.flex_row {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.flex_row label {
  padding-left: 1.7rem;
}
.flex_row.center {
  justify-content: center !important;
}

.search_bx_wrap {
  display: flex;
  justify-content: center;
  margin-bottom: 3rem;
}

.radio_input_wrap {
  display: flex;
  gap: 1rem;
  align-items: center;
}
.radio_input_wrap > div {
  display: flex;
  gap: 1rem;
  align-items: center;
}
.radio_input_wrap > div.num {
  margin-right: 5rem;
}
.radio_input_wrap > div.num input {
  width: 9rem;
}
.radio_input_wrap > div.string {
  flex: 1;
}
.radio_input_wrap > div.string div:last-child {
  flex: 1;
}

.img_file_box {
  display: inline-block;
  margin-right: 1rem;
}
.img_file_box input[type=file] {
  display: none;
}
.img_file_box label {
  display: inline-block;
  height: 4rem;
  line-height: 3.8rem;
  font-size: 1.6rem;
  min-width: 7.8rem;
  padding: 0 1.7rem;
  color: #000;
  border: 1px solid #000;
  text-align: center;
  border-radius: 3.5rem;
  background: #fff;
  font-weight: 500;
}

.product_select_box li {
  margin-bottom: 0.5rem;
}
.product_select_box li span {
  display: inline-block;
  min-width: 30%;
}
.product_select_box li button {
  margin-right: 2rem;
}

.table_in_table {
  border-top: 1px solid #dedede;
  margin-top: 2rem;
}

.desc {
  font-size: 1.4rem;
  color: #3e21d9;
}

table th.text_center {
  text-align: center;
  border-right: none !important;
}
table td.border_left {
  border-left: 1px solid #dedede;
}

.table_st_wrap {
  border-top: 2px solid #000;
}
.table_st_wrap .header {
  background: #f7f9fa;
  height: 4.1rem;
  line-height: 4rem;
  border-bottom: 1px solid #dedede;
  display: flex;
}
.table_st_wrap .header div {
  width: 50%;
  text-align: center;
}
.table_st_wrap .header div:nth-child(1) {
  border-right: 1px solid #dedede;
}
.table_st_wrap .body {
  display: flex;
  border-bottom: 1px solid #dedede;
  margin-bottom: 2rem;
}
.table_st_wrap .body .scroll_box {
  width: 50%;
  height: 33rem;
  overflow-y: auto;
}
.table_st_wrap .body .scroll_box:nth-child(1) {
  border-right: 1px solid #dedede;
}
.table_st_wrap .body .col {
  padding: 1rem;
}
.table_st_wrap .body .col label {
  font-size: 1.6rem;
  line-height: 2.9rem;
}
.table_st_wrap .body .col .list {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 0 0 0.5rem;
  line-height: 3rem;
}
.table_st_wrap .body .col .list b {
  font-weight: 600;
  margin-right: 2rem;
  min-width: 6rem;
}
.table_st_wrap .body .col .list i {
  width: 12px;
  height: 12px;
  line-height: 9px;
  border-radius: 3px;
  border: 1px solid #000;
  margin-left: 5px;
  position: relative;
  font-size: 1.5rem;
  cursor: pointer;
}
.table_st_wrap .body .col.col_2 {
  padding: 0;
  display: flex;
}
.table_st_wrap .body .col.col_2 > div {
  padding: 1rem;
  width: 50%;
}
.table_st_wrap .body .col.col_2 > div:nth-child(1) {
  border-right: 1px solid #dedede;
}
.table_st_wrap.st_2 .scroll_box {
  width: 100%;
  height: 16.5rem;
  overflow-y: auto;
  border-bottom: 1px solid #dedede;
}
.table_st_wrap.st_2 .body {
  margin-bottom: 0;
  border-bottom: 0;
}
.table_st_wrap.st_2 .col {
  width: 50%;
  height: inherit;
  overflow-y: visible;
}
.table_st_wrap.st_2 .col:nth-child(1) {
  border-right: 1px solid #dedede;
}
.table_st_wrap.st_3 .header div {
  font-weight: bold;
  font-size: 1.4rem;
}
.table_st_wrap.st_3 .header div:nth-child(1) {
  width: 100px;
}
.table_st_wrap.st_3 .header div:nth-child(2) {
  flex: 1;
}
.table_st_wrap.st_3 .scroll_box {
  width: 100%;
  height: 23rem;
  overflow-y: auto;
  border-bottom: 1px solid #dedede;
}
.table_st_wrap.st_3 .scroll_box .body {
  margin-bottom: 0;
  border-bottom: 0;
}
.table_st_wrap.st_3 .scroll_box .body .col {
  width: 100%;
  padding: 5px 0;
}
.table_st_wrap.st_3 .scroll_box .body .list {
  display: flex;
  padding: 0;
  line-height: 3.1rem;
}
.table_st_wrap.st_3 .scroll_box .body .list b {
  width: 100px;
  margin-right: 0;
  text-align: center;
}
.table_st_wrap.st_3 .scroll_box .body .list p {
  padding: 0 2rem;
}

.scroll_x_wrap {
  position: relative;
  /* &::after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      width: 8rem;
      height: calc(100% - 60px);
      background: linear-gradient(
          to right,
          rgba(255, 255, 255, 0),
          rgba(255, 255, 255, 0.95) 88%
      );
  } */
}
.scroll_x_wrap .scroll_x_bx {
  width: 100%;
  max-height: 979px;
  min-height: 300px;
  overflow-x: scroll;
  margin-bottom: 6rem;
  padding-bottom: 2rem;
  border-top: 3px solid #000;
}
.scroll_x_wrap .scroll_x_bx .inner {
  width: 2450px;
  padding-right: 50px;
}
.scroll_x_wrap .scroll_x_bx table {
  width: 2400px;
  border-top: none;
}
.scroll_x_wrap .scroll_x_bx table thead tr th {
  position: sticky;
  top: 0;
  border-bottom: 0;
  z-index: 1;
}
.scroll_x_wrap .scroll_x_bx table tbody th {
  border: 1px solid #dedede;
}

.text_bx {
  border: 1px solid #dedede;
  padding: 1rem;
  min-height: 20.7rem;
}

.send_test_num {
  display: flex;
  gap: 1rem;
  align-items: center;
  margin-top: 1rem;
}
.send_test_num b {
  font-size: 1.4rem;
  font-weight: 600;
}

.txt_right_primary {
  font-size: 1.4rem;
  color: #3e21d9;
  text-align: right;
  margin-bottom: 1rem;
}

.toggle_footnote {
  display: flex;
  align-items: center;
  justify-content: end;
  margin-top: 2rem;
  gap: 2rem;
}
.toggle_footnote li {
  padding-left: 4rem;
  background: url(../../assets/images/toggle_s.svg) no-repeat;
  background-size: 3rem auto;
}
.toggle_footnote li:nth-child(2) {
  background: url(../../assets/images/toggle_n.svg) no-repeat;
  background-size: 3rem auto;
}

.print_wrap .info_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.print_wrap .info_box .page_box {
  display: flex;
  gap: 2rem;
  align-items: center;
}
.print_wrap .info_box .page_box .prev,
.print_wrap .info_box .page_box .next {
  cursor: pointer;
  width: 2.6rem;
  height: 2.6rem;
  border-radius: 50%;
  border: 1px solid #000;
  background: url(../../assets/images/form_icon_extension.svg) no-repeat center;
  transform: rotate(90deg);
}
.print_wrap .info_box .page_box .next {
  transform: rotate(-90deg);
}
.print_wrap .info_box .page_box .page {
  display: flex;
  gap: 5px;
}
.print_wrap .info_box .page_box .page b {
  color: #3e21d9;
}
.print_wrap .print_box {
  margin-top: 2rem;
  width: 100%;
  height: 60rem;
  overflow-y: auto;
  border: 1px solid #dedede;
  text-align: center;
}

.gallery_img_slider {
  padding: 10px 50px;
}
.gallery_img_slider .main_img {
  width: 420px !important;
  padding: 0 10px;
}
.gallery_img_slider :global .slick-prev,
.gallery_img_slider :global .slick-next {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  border: 1px solid #000;
  background: url(../../assets/images/form_icon_extension.svg) no-repeat center;
  transform: rotate(90deg) translate(-50%, 50%);
}
.gallery_img_slider :global .slick-prev::before,
.gallery_img_slider :global .slick-next::before {
  display: none;
}
.gallery_img_slider :global .slick-next {
  transform: rotate(-90deg) translate(50%, 50%);
}

.filter_box_wrap {
  display: flex;
  gap: 3rem;
}
.filter_box_wrap .filter_box {
  flex: 1;
}
.filter_box_wrap .filter_box h5 {
  height: 4.1rem;
  line-height: 4rem;
  border-top: 2px solid #000;
  background: #f7f9fa;
  text-align: center;
  font-size: 1.4rem !important;
  font-weight: bold !important;
}
.filter_box_wrap .filter_box .scroll_box {
  border-bottom: 1px solid #dedede;
  height: 19.5rem;
  overflow-y: auto;
}
.filter_box_wrap .filter_box .scroll_box ul li {
  height: 2.9rem;
  line-height: 2.9rem;
  padding-left: 1.7rem;
}
.filter_box_wrap .filter_box .scroll_box ul li label {
  font-size: 1.6rem;
}
.filter_box_wrap .filter_box.st_2 {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}
.filter_box_wrap .filter_box.st_2 h5 {
  width: 100%;
}
.filter_box_wrap .filter_box.st_2 .scroll_box {
  width: 100%;
  height: 16rem;
  margin-bottom: 1rem;
}

.pop_img_preview {
  width: 100%;
  height: 30rem;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #dedede;
  background: #f7f9fa;
}

.terms_box {
  width: 100%;
  border: 1px solid #dedede;
  height: 50rem;
  overflow-y: auto;
  padding: 2rem;
}

.icon_o {
  display: block;
  width: 2rem;
  height: 2rem;
  margin: 0 auto;
  background: url(../../assets/images/icon_o.svg) no-repeat center;
}

.empty_data {
  padding: 13rem 0 13rem 0;
  text-align: center;
}
.empty_data p {
  padding: 11rem 0 11rem;
  font-size: 2.4rem;
  font-weight: bold;
  background: url(../../assets/images/ed_icon_07.svg) no-repeat center top;
}
.empty_data.coming_soon p {
  background: url(../../assets/images/ed_icon_03.svg) no-repeat center top;
}
.empty_data.sm {
  padding: 5rem 0 5rem 0;
}
.empty_data.sm p {
  font-size: 1.6rem;
}

.print_table_box {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 3rem;
  max-height: calc(100vh - 450px);
  overflow-y: auto;
}
.print_table_box h3 {
  width: 100%;
}
.print_table_box table {
  flex: 1;
}