.my_page_container {
  position: relative;
  width: 100%;
  max-width: 1920px;
  min-height: calc(100vh - 80px);
  margin: 0 auto;
  padding-top: 8rem;
  padding-left: 26rem;
}
.my_page_container .flex_row {
  display: flex;
  align-items: center;
  gap: 2rem;
}
.my_page_container .desc_s {
  font-size: 1.4rem;
  color: #999;
  line-height: 2.2rem;
}
.my_page_container .desc_list {
  font-size: 1.4rem;
  line-height: 2.2rem;
}
.my_page_container .desc_list li {
  white-space: normal;
  padding-left: 8px;
  position: relative;
}
.my_page_container .desc_list li::before {
  content: "";
  display: block;
  width: 3px;
  height: 3px;
  background: #000;
  border-radius: 50%;
  margin-right: 5px;
  position: absolute;
  top: 9px;
  left: 0;
}
.my_page_container .desc_list.gray {
  color: #999;
}
.my_page_container .desc_list.gray li::before {
  background: #999;
}
.my_page_container .desc_list.footer_st {
  margin-top: 3rem;
}
.my_page_container .desc_list.footer_st b {
  color: #000;
  display: block;
  margin-bottom: 1rem;
}
.my_page_container .info_title {
  margin-bottom: 2rem;
  line-height: 2.2rem;
}
.my_page_container .info_list_wrap {
  margin-bottom: 1rem;
}
.my_page_container .info_list_wrap h4 {
  font-size: 1.8rem;
  line-height: 2.4rem;
}
.my_page_container .info_list_wrap ul {
  padding: 1.6rem 0 1.6rem 2rem;
}
.my_page_container .info_list_wrap ul li {
  line-height: 2.1rem;
  list-style-type: disc;
}
.my_page_container .birth_wrap {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.my_page_container .birth_wrap span {
  margin-right: 1rem;
}
.my_page_container .img_file_box {
  display: inline-block;
  margin: 1.5rem 1rem 2rem 0;
}
.my_page_container .img_file_box input[type=file] {
  display: none;
}
.my_page_container .img_file_box label {
  display: inline-block;
  height: 4rem;
  line-height: 3.8rem;
  font-size: 1.6rem;
  min-width: 7.8rem;
  padding: 0 1.7rem;
  color: #000;
  border: 1px solid #000;
  text-align: center;
  border-radius: 3.5rem;
  background: #fff;
  font-weight: 500;
}
.my_page_container .detail_table_wrap table tr {
  cursor: default;
}
.my_page_container .detail_table_wrap table tr th,
.my_page_container .detail_table_wrap table tr td {
  overflow: initial;
  text-overflow: initial;
  padding: 5px 20px;
}
.my_page_container .detail_table_wrap table tr th {
  padding: 2rem 0 0 2rem;
}
.my_page_container .radio_wrap {
  display: flex;
  align-items: center;
  gap: 3rem;
}
.my_page_container .phone_wrap {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.my_page_container .address_wrap {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.my_page_container .table_img_box {
  width: 27rem;
  height: 16.8rem;
}
.my_page_container .table_img_box img {
  width: 100%;
  height: 100%;
}
.my_page_container .table_txt_box {
  word-break: break-word;
  width: 100%;
  white-space: break-spaces;
}
.my_page_container .btn_bottom_wrap {
  margin-top: 3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}
.my_page_container .btn_bottom_wrap .right {
  display: flex;
  gap: 1rem;
  margin-left: auto;
}
.my_page_container .thumbnail_box {
  display: flex;
  align-items: flex-end;
  gap: 1rem;
  margin-bottom: 1rem;
}
.my_page_container .thumbnail_box img {
  width: 14rem;
  height: 8.5rem;
  -o-object-fit: cover;
     object-fit: cover;
}
.my_page_container .mypage_sub_nav {
  height: calc(100vh - 80px);
  position: absolute;
  background: #fff;
  width: 26rem;
  top: 8rem;
  left: 0;
  border-right: 1px solid #dedede;
}
.my_page_container .mypage_sub_nav h3 {
  height: 5.5rem;
  line-height: 5.5rem;
  background: #000;
  color: #fff;
  font-size: 1.8rem;
  padding-left: 2.5rem;
}
.my_page_container .mypage_sub_nav nav li {
  height: 5rem;
  line-height: 4.9rem;
  border-bottom: 1px solid #dedede;
  color: #999;
}
.my_page_container .mypage_sub_nav nav li a {
  display: block;
  padding-left: 2rem;
}
.my_page_container .mypage_sub_nav nav :global .active {
  background-color: #f8fafa;
  color: #000;
  font-weight: bold;
}
.my_page_container section .mypage_contents {
  width: 100%;
  height: 100%;
  position: relative;
}
.my_page_container .mypage_sub_header {
  height: 10.5rem;
  background: #f8fafa;
  border-bottom: 1px solid #dedede;
}
.my_page_container .mypage_sub_header .inner {
  width: 100%;
  max-width: 130rem;
  padding: 0 0 0 10rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 1560px) {
  .my_page_container .mypage_sub_header .inner {
    padding: 0 0 0 2.5vw;
  }
}
.my_page_container .mypage_sub_header .user {
  padding: 2.5rem 0;
}
.my_page_container .mypage_sub_header .user p {
  font-size: 2.2rem;
  font-weight: 500;
}
.my_page_container .mypage_sub_header .user .j_id {
  font-size: 2.2rem;
  font-weight: bold;
  margin-top: 8px;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
}
.my_page_container .mypage_sub_header .user .j_id .tooltip {
  margin-left: 2rem;
  width: 2.4rem;
  height: 2.4rem;
  text-indent: -9999px;
  background: url(../../assets/images/icon_tooltip.svg) no-repeat center;
}
.my_page_container .mypage_sub_header .user .j_id .tooltip_content {
  position: absolute;
  top: -44px;
  left: 192px;
  width: 24.8rem;
  height: 11rem;
  background: #fff;
  padding: 2rem;
  color: #000;
  cursor: default;
  display: none;
  transition: all 0.5s;
  box-shadow: 1px 1px 5px 3px rgba(0, 0, 0, 0.03);
}
.my_page_container .mypage_sub_header .user .j_id .tooltip_content b {
  font-size: 1.6rem;
  font-weight: 600;
  margin-bottom: 1rem;
  display: block;
}
.my_page_container .mypage_sub_header .user .j_id .tooltip_content span {
  display: block;
  font-size: 1.4rem;
  line-height: 2rem;
  font-weight: 400;
}
.my_page_container .mypage_sub_header .user .j_id .tooltip_content::before {
  content: "";
  display: block;
  position: absolute;
  width: 1.6rem;
  height: 1.6rem;
  background: #e60013;
  top: -6px;
  right: -6px;
}
.my_page_container .mypage_sub_header .user .j_id .tooltip_content::after {
  content: "";
  display: block;
  position: absolute;
  width: 1.2rem;
  height: 1.2rem;
  background: #fff;
  transform: rotate(45deg);
  top: 50px;
  left: -6px;
}
.my_page_container .mypage_sub_header .user .j_id:hover .tooltip_content {
  display: block;
}
.my_page_container .mypage_sub_header .shortcut {
  height: 10.5rem;
  display: flex;
}
.my_page_container .mypage_sub_header .shortcut li {
  width: 10.5rem;
  height: 100%;
  padding-top: 6.8rem;
  text-align: center;
  font-size: 1.4rem;
  font-weight: bold;
  cursor: pointer;
}
.my_page_container .mypage_sub_header .shortcut li.cs {
  background: url(../../assets/images/icon_cs_40.svg) no-repeat center top 2rem;
}
.my_page_container .mypage_sub_header .shortcut li.as {
  background: url(../../assets/images/icon_as_40.svg) no-repeat center top 2rem;
}
.my_page_container .mypage_sub_header .shortcut li.logout {
  background: url(../../assets/images/icon_logout_40.svg) no-repeat center top 2rem;
}
.my_page_container .contents_wrap {
  width: 100%;
  max-width: 130rem;
  padding: 3.125vw 0 7.813vw 5.208vw;
}
.my_page_container .contents_wrap .contents_title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 2rem;
}
.my_page_container .contents_wrap .contents_title h3 {
  font-size: 2.4rem;
}
.my_page_container .contents_wrap .contents_title div {
  width: 29.5rem;
}
.my_page_container .contents_wrap .contents_title .desc {
  width: 100%;
  margin-top: 1rem;
}
.my_page_container .contents_wrap .contents_title .search_wrap {
  display: flex;
  justify-content: flex-end;
  position: relative;
}
.my_page_container .contents_wrap .contents_title .search_wrap input {
  width: 29.5rem;
  padding-right: 4rem;
}
.my_page_container .contents_wrap .contents_title .search_wrap button {
  position: absolute;
  top: 0.7rem;
  right: 1.5rem;
}
@media (max-width: 1560px) {
  .my_page_container .contents_wrap {
    padding: 1.5vw 1vw 7.813vw 2.5vw;
  }
}
.my_page_container .contents_wrap .tournament_history_table_wrap table tr td:nth-child(4) {
  padding-left: 0;
  padding-right: 0;
  text-overflow: initial;
  overflow: initial;
  word-wrap: initial;
  white-space: initial;
}
.my_page_container .password_wrap {
  width: 29.5rem;
  padding-top: 4.5rem;
  margin: 0 auto;
  text-align: center;
}
.my_page_container .password_wrap p {
  font-weight: bold;
  font-size: 1.4rem;
  margin: 15px 0 5px 0;
  text-align: left;
}
.my_page_container .password_wrap button {
  margin-top: 3rem;
}
.my_page_container .empty_data {
  padding: 13rem 0 0.5rem 0;
  text-align: center;
}
.my_page_container .empty_data p {
  padding: 11rem 0 3rem;
  font-size: 2.4rem;
  font-weight: bold;
  background: url(../../assets/images/ed_icon_07.svg) no-repeat center top;
}
.my_page_container .empty_data.event_st p {
  background: url(../../assets/images/ed_icon_08.svg) no-repeat center top;
}
.my_page_container .empty_data.cs_st p {
  background: url(../../assets/images/ed_icon_09.svg) no-repeat center top;
}
.my_page_container .empty_data.as_st p {
  background: url(../../assets/images/ed_icon_10.svg) no-repeat center top;
}
.my_page_container .tab_menu {
  width: 100%;
  display: flex;
  margin-bottom: 2rem;
}
.my_page_container .tab_menu li {
  width: 50%;
  height: 5rem;
  line-height: 5rem;
  background: #f7f9fa;
  color: #999;
  font-weight: 500;
  text-align: center;
  cursor: pointer;
}
.my_page_container .tab_menu li.on {
  background: #e60013;
  color: #fff;
  font-weight: bold;
}
@media (max-width: 1440px) {
  .my_page_container {
    padding-left: 15vw;
  }
  .my_page_container .mypage_sub_nav {
    width: 15vw;
  }
}
@media (max-width: 1280px) {
  .my_page_container table tr th {
    text-overflow: initial;
    overflow: initial;
    padding-left: 0;
    padding-right: 0;
  }
  .my_page_container table tr td {
    text-overflow: initial;
    overflow: initial;
    white-space: pre-wrap;
    padding-left: 0;
    padding-right: 0;
  }
}

.register_ball_table_wrap > button {
  display: block;
  margin-left: auto;
  margin-bottom: 1rem;
}
.register_ball_table_wrap table tr th {
  padding: 0 !important;
  line-height: 4.9rem;
}
.register_ball_table_wrap table tr:last-child td {
  padding-top: 2rem;
  border-bottom: 0;
}
.register_ball_table_wrap table .flex_row {
  gap: 1rem;
  align-items: center;
}
.register_ball_table_wrap table td button {
  min-width: initial !important;
}
.register_ball_table_wrap .serial_num {
  display: flex;
  gap: 0.4rem;
}
.register_ball_table_wrap .serial_num > div {
  flex: 1;
  min-width: initial;
}