.sign_container {
  min-width: 100%;
  padding-top: 4.4rem;
}
.sign_container .desc {
  line-height: 2rem;
}
.sign_container .required {
  font-size: 1.4rem;
  font-weight: 500;
  color: #e60013;
}
.sign_container .flex_row {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.sign_container .radio_wrap {
  display: flex;
  align-items: center;
  gap: 3rem;
  font-weight: 500;
}
.sign_container .checkbox_wrap {
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: 500;
}
.sign_container .checkbox_wrap button {
  margin-left: auto;
  font-size: 1.4rem;
  font-weight: 600;
}
.sign_container .address_wrap {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.sign_container .address_wrap .flex_row {
  justify-content: space-between;
  gap: 0;
}
.sign_container .address_wrap .flex_row div {
  width: 49%;
}
.sign_container .chk_agree label {
  font-size: 1.4rem;
  font-weight: 500;
  padding-left: 2.5rem;
}
.sign_container .email_wrap {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
}
.sign_container .email_wrap div {
  width: 45%;
}
.sign_container .email_wrap div:last-child {
  display: block;
  width: 100%;
  margin-top: 1rem;
}
.sign_container .phone_agree_wrap > div:nth-child(2) {
  margin-top: 1rem;
}
.sign_container .input_col3 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sign_container .input_col3 > div {
  width: 28%;
}
.sign_container .detail_table {
  width: 100%;
}
.sign_container .detail_table table {
  border-top: none;
}
.sign_container .detail_table table colgroup {
  display: none;
}
.sign_container .detail_table table .flex_row {
  flex-wrap: wrap;
}
.sign_container .detail_table table .required {
  display: block;
  width: 100%;
}
.sign_container .detail_table tr {
  cursor: default !important;
}
.sign_container .detail_table tr td,
.sign_container .detail_table tr th {
  overflow: inherit;
  text-overflow: inherit;
  border-bottom: none;
  display: block;
  padding: 0;
  height: auto;
}
.sign_container .detail_table tr th {
  margin-top: 2.4rem;
  font-size: 1.2rem;
}
.sign_container .detail_table .btn_bottom_wrap {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 5rem;
}
.sign_container .detail_table .btn_bottom_wrap button {
  flex: 1;
}
.sign_container .input_btn_wrap {
  border: 1px solid #dedede;
  display: flex;
  align-items: center;
}
.sign_container .input_btn_wrap > div {
  flex: 1;
}
.sign_container .input_btn_wrap > div input {
  border: none;
}
.sign_container .input_btn_wrap button {
  padding-right: 1.2rem;
  border: 0;
  background: none;
  font-size: 1.4rem;
  text-decoration: underline;
  text-underline-position: under;
}
.sign_container .sign_in_wrap {
  width: 100%;
  padding: 4rem 1.6rem 0;
}
.sign_container .sign_in_wrap .sign_in_box {
  width: 100%;
  padding-top: 3rem;
}
.sign_container .sign_in_wrap .sign_in_box label {
  font-size: 1.2rem;
}
.sign_container .sign_in_wrap .sign_in_box form input[type=checkbox]:checked ~ label {
  background: url(../../assets/images/checkbox_red_s.svg) no-repeat center left;
}
.sign_container .sign_in_wrap .sign_in_box .find_btn_wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2.4rem;
  margin-top: 2rem;
  margin-bottom: 10rem;
}
.sign_container .sign_in_wrap .sign_in_box .find_btn_wrap button {
  color: #999;
  font-size: 1.4rem;
  font-weight: 600;
}
.sign_container .sign_in_wrap .sign_in_box .find_btn_wrap::before {
  content: "";
  display: block;
  position: absolute;
  top: 2px;
  left: 39%;
  width: 1px;
  height: 10px;
  background: #dedede;
}
.sign_container .sign_in_wrap .sign_in_box .save_id label {
  font-size: 1.4rem;
  font-weight: 500;
}
.sign_container .find_id_pw_wrap {
  width: 100%;
  padding-top: 3rem;
}
.sign_container .find_id_pw_wrap .title {
  padding: 0 1.6rem;
}
.sign_container .find_id_pw_wrap .tab_menu {
  width: 100%;
  display: flex;
  margin: 1.6rem 0 2rem;
}
.sign_container .find_id_pw_wrap .tab_menu li {
  width: 50%;
  height: 5rem;
  line-height: 5rem;
  color: #999;
  font-weight: 500;
  text-align: center;
  cursor: pointer;
  border-top: 1px solid #dedede;
  border-bottom: 1px solid #dedede;
}
.sign_container .find_id_pw_wrap .tab_menu li.on {
  background: #e60013;
  color: #fff;
  font-weight: bold;
  border-top: 1px solid #e60013;
  border-bottom: 1px solid #e60013;
}
.sign_container .find_id_pw_wrap .find_box {
  padding: 0 1.6rem 2rem 1.6rem;
}
.sign_container .find_id_pw_wrap .find_box .desc {
  margin: 1.6rem 0 1.5rem;
  text-align: center;
}
.sign_container .find_id_pw_wrap .box {
  padding: 1.6rem;
  background: #f7f9fa;
  margin-bottom: 1rem;
}
.sign_container .find_id_pw_wrap .box h4 {
  font-size: 1.4rem;
}
.sign_container .find_id_pw_wrap .box .flex_row {
  width: 100%;
  justify-content: space-between;
}
.sign_container .find_id_pw_wrap .box .input_wrap {
  padding: 4px 0 0;
}
.sign_container .find_id_pw_wrap .box .input_wrap > p {
  margin-top: 2rem;
  margin-bottom: 5px;
  font-size: 1.2rem;
  font-weight: 600;
}
.sign_container .find_id_pw_wrap .box .input_wrap .contact_wrap {
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: space-between;
}
.sign_container .find_id_pw_wrap .box .input_wrap .contact_wrap > div {
  width: 14rem !important;
  flex: initial;
}
.sign_container .find_id_pw_wrap .box .input_wrap .contact_wrap.email_st {
  flex-wrap: wrap;
}
.sign_container .find_id_pw_wrap .box .input_wrap .contact_wrap.email_st > div:last-child {
  width: 100% !important;
}
.sign_container .find_id_pw_wrap .box .input_wrap .contact_wrap.email_st > div:last-child > ul {
  z-index: 11;
}
.sign_container .find_id_pw_wrap .btn_wrap {
  margin-top: 5rem;
}
.sign_container .find_id_pw_wrap .btn_wrap button {
  width: 100%;
}
.sign_container .result_wrap {
  width: 100%;
  margin: 3rem auto;
  text-align: center;
  padding: 0 1.6rem;
}
.sign_container .result_wrap h2 {
  text-align: left;
  margin-bottom: 3rem;
}
.sign_container .result_wrap > p {
  margin-bottom: 1.5rem;
  text-align: left;
}
.sign_container .result_wrap .box {
  background: #f7f9fa;
  padding: 3.2rem 0;
  margin-bottom: 2rem;
}
.sign_container .result_wrap .box p {
  font-size: 1.8rem;
  font-weight: 600;
}
.sign_container .result_wrap .box p.result {
  font-size: 3rem;
  font-weight: bold;
  color: #e60013;
}
.sign_container .result_wrap .input_wrap {
  text-align: left;
}
.sign_container .result_wrap .input_wrap p {
  font-size: 1.2rem;
  margin: 15px 0 5px;
  font-weight: 600;
}
.sign_container .result_wrap .input_wrap p:nth-child(1) {
  margin-top: 0;
}
.sign_container .result_wrap .input_wrap button {
  display: block;
  margin: 2rem auto 0;
  width: 100%;
}
.sign_container .result_wrap .flex_row {
  padding-top: 30rem;
}
.sign_container .result_wrap .flex_row button {
  flex: 1;
}
.sign_container .sign_up_wrap {
  max-width: 120rem;
  width: 100%;
  margin: 0 auto;
}
.sign_container .sign_up_wrap h2 {
  font-size: 2rem;
}
.sign_container .sign_up_wrap .identity_check_wrap {
  padding: 8rem 1.6rem 10rem;
  text-align: center;
}
.sign_container .sign_up_wrap .identity_check_wrap .desc {
  padding: 10rem 0 2.4rem;
  background: url(../../assets/images/ed_icon_04.svg) no-repeat center top;
}
.sign_container .sign_up_wrap .identity_check_wrap .desc p:nth-child(1) {
  margin-bottom: 1rem;
}
.sign_container .sign_up_wrap .identity_check_wrap .desc h2 {
  margin-bottom: 2rem;
}
.sign_container .sign_up_wrap .identity_check_wrap .info {
  padding-top: 3rem;
}
.sign_container .sign_up_wrap .identity_check_wrap .info .desc {
  padding: 0 0 2rem 0;
  background: none;
}
.sign_container .sign_up_wrap .identity_check_wrap button {
  width: 100%;
  height: 4.5rem;
  line-height: 4.3rem;
  font-size: 1.6rem;
}
.sign_container .sign_up_wrap .sign_up_box {
  padding: 6rem 1.6rem 2rem;
}
.sign_container .sign_up_wrap .sign_up_box .title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sign_container .sign_up_wrap .sign_up_box .desc {
  text-align: right;
  font-size: 1.4rem;
}
.sign_container .sign_up_wrap .sign_up_box th p {
  font-weight: 500;
}
.sign_container .sign_up_wrap .welcome_wrap {
  padding: 20rem 1.6rem 25rem;
  text-align: center;
}
.sign_container .sign_up_wrap .welcome_wrap h2 {
  font-size: 2rem;
  font-weight: 800;
  padding: 8.4rem 0 1.6rem;
  background: url(../../assets/images/ed_icon_05.svg) no-repeat center top;
  background-size: 6.4rem auto;
}
.sign_container .sign_up_wrap .welcome_wrap h2.written_st {
  background: url(../../assets/images/ed_icon_06.svg) no-repeat center top;
  background-size: 6.4rem auto;
}
.sign_container .sign_up_wrap .welcome_wrap .desc {
  margin-bottom: 5rem;
}
.sign_container .sign_up_wrap .id_card_wrap {
  font-size: 1.4rem;
  line-height: 2rem;
  white-space: break-spaces;
}
.sign_container .sign_up_wrap .id_card_wrap p {
  margin-bottom: 1rem;
}
.sign_container .sign_up_wrap .id_card_wrap ul {
  margin-bottom: 1rem;
}
.sign_container .sign_up_wrap .id_card_wrap ul li {
  color: #999;
  position: relative;
  padding-left: 7px;
}
.sign_container .sign_up_wrap .id_card_wrap ul li::before {
  content: "";
  display: block;
  position: absolute;
  top: 8px;
  left: 0;
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background: #999;
}
.sign_container .sign_up_wrap .id_card_wrap .flex_row {
  align-items: flex-end;
}
.sign_container .sign_up_wrap .id_card_wrap .flex_row img {
  width: 27rem;
  height: 16.8rem;
}

.authCode {
  position: relative;
}
.authCode span {
  color: #e60013;
  position: absolute;
  right: 2rem;
}