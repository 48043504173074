.products_container {
  position: relative;
  width: 100%;
  min-height: calc(100vh - 157px);
  min-width: initial;
  margin: 0 auto;
  padding-top: 15.7rem;
}
.products_container .flex_row {
  display: flex;
  align-items: center;
  gap: 2rem;
}
.products_container .desc_s {
  font-size: 1.4rem;
  color: #999;
  line-height: 2.2rem;
}
.products_container .desc_list {
  font-size: 1.4rem;
  line-height: 2.2rem;
}
.products_container .desc_list li {
  white-space: normal;
  padding-left: 8px;
  position: relative;
}
.products_container .desc_list li::before {
  content: "";
  display: block;
  width: 3px;
  height: 3px;
  background: #000;
  border-radius: 50%;
  margin-right: 5px;
  position: absolute;
  top: 9px;
  left: 0;
}
.products_container .desc_list.gray {
  color: #999;
}
.products_container .desc_list.gray li::before {
  background: #999;
}
.products_container .desc_list.footer_st {
  margin-top: 3rem;
}
.products_container .desc_list.footer_st b {
  color: #000;
  display: block;
  margin-bottom: 1rem;
}
.products_container .info_title {
  margin-bottom: 2rem;
  line-height: 2.2rem;
}
.products_container .radio_wrap {
  display: flex;
  align-items: center;
  gap: 3rem;
}
.products_container .btn_bottom_wrap {
  margin-top: 3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}
.products_container .btn_bottom_wrap .right {
  display: flex;
  gap: 1rem;
  margin-left: auto;
}
.products_container .products_sub_nav {
  min-height: 11.3rem;
  position: fixed;
  background: #fff;
  width: 100vw;
  top: 4.4rem;
  left: 0;
  z-index: 11;
}
.products_container .products_sub_nav .products_sub_header .title {
  position: relative;
}
.products_container .products_sub_nav .products_sub_header .title h3 {
  height: 6.4rem;
  line-height: 6.4rem;
  font-size: 2rem;
  padding: 0 1.6rem;
}
.products_container .products_sub_nav .products_sub_header .title .btn_wrap {
  display: flex;
  align-items: center;
  gap: 1.6rem;
}
.products_container .products_sub_nav .products_sub_header .title .btn_wrap button {
  text-indent: -9999px;
}
.products_container .products_sub_nav .products_sub_header .title .btn_wrap.btn_search {
  position: absolute;
  top: 2rem;
  right: 1.6rem;
}
.products_container .products_sub_nav .products_sub_header .search_wrap {
  position: relative;
}
.products_container .products_sub_nav .products_sub_header .search_wrap input {
  width: 100%;
  padding-right: 4rem;
  height: 4.9rem;
  line-height: 4.7rem;
  background: #f7f9fa;
  font-size: 1.4rem;
}
.products_container .products_sub_nav .products_sub_header .search_wrap button {
  position: absolute;
  top: 1.2rem;
  right: 1.5rem;
}
.products_container .products_sub_nav .search_filter .select_wrap {
  display: flex;
}
.products_container .products_sub_nav .search_filter .select_wrap > div {
  flex: 1;
}
.products_container .products_sub_nav .search_filter .select_wrap > div p {
  background: #f7f9fa;
  height: 4.9rem;
  line-height: 4.9rem;
  border-right: 0;
}
.products_container .products_sub_nav .search_filter .select_wrap > div ul {
  background: #f7f9fa;
  border-right: 0;
}
.products_container .products_sub_nav .search_filter .select_wrap > div:first-child p,
.products_container .products_sub_nav .search_filter .select_wrap > div:first-child ul {
  border-right: 1px solid #dedede;
}
.products_container .products_sub_nav .search_filter .select_wrap > div::after {
  top: 1.8rem;
}
.products_container .products_sub_nav .search_filter .search_wrap {
  position: relative;
}
.products_container .products_sub_nav .search_filter .search_wrap input {
  width: 100%;
  padding-right: 4rem;
  height: 4.9rem;
  line-height: 4.7rem;
  background: #f7f9fa;
  font-size: 1.4rem;
}
.products_container .products_sub_nav .search_filter .search_wrap button {
  position: absolute;
  top: 1.2rem;
  right: 1.5rem;
}
.products_container .products_sub_nav .smart_filter_wrap {
  height: calc(100vh - 157px);
  overflow-y: auto;
}
.products_container .products_sub_nav .smart_filter_wrap h4 {
  height: 4.3rem;
  line-height: 4.3rem;
  font-size: 1.6rem;
  border-bottom: 1px solid #dedede;
  padding: 0 1.6rem;
  margin-top: 8px;
}
.products_container .products_sub_nav .smart_filter_wrap ul li {
  cursor: pointer;
}
.products_container .products_sub_nav .smart_filter_wrap ul li span {
  display: block;
  height: 5rem;
  line-height: 4.9rem;
  padding-left: 1.6rem;
  font-weight: 500;
  background: url(../../assets/images/lnb_open.svg) no-repeat center right 2rem;
  border-bottom: 1px solid #dedede;
}
.products_container .products_sub_nav .smart_filter_wrap ul li .depth_2_wrap {
  max-height: 25rem;
  overflow-y: auto;
  padding: 0 1.6rem;
  display: none;
}
.products_container .products_sub_nav .smart_filter_wrap ul li .depth_2 p {
  line-height: 2.5rem;
}
.products_container .products_sub_nav .smart_filter_wrap ul li hr {
  border-style: dashed;
  border-bottom: 0;
  border-color: #868686;
}
.products_container .products_sub_nav .smart_filter_wrap ul li.on {
  border-bottom: 1px solid #dedede;
  padding: 0 5px 10px 0;
}
.products_container .products_sub_nav .smart_filter_wrap ul li.on span {
  background: url(../../assets/images/lnb_close.svg) no-repeat center right 2rem;
  border-bottom: 0;
}
.products_container .products_sub_nav .smart_filter_wrap ul li.on .depth_2_wrap {
  display: block;
}
.products_container .products_sub_nav .smart_filter_wrap .btn_wrap {
  display: flex;
  padding: 2.8rem 1.6rem 2rem;
  gap: 8px;
}
.products_container .products_sub_nav .smart_filter_wrap .btn_wrap button {
  width: calc(50% - 4px);
}
.products_container section .products_contents {
  width: 100%;
  height: 100%;
  position: relative;
  border-left: 1px solid #dedede;
}
.products_container section .products_contents .bottom_center_btn {
  padding: 0 1.6rem;
}
.products_container .contents_wrap {
  max-width: 130rem;
  width: 100%;
  position: relative;
}
.products_container .contents_wrap .contents_detail .contents_title .img_box {
  width: 100%;
  height: auto;
  overflow: hidden;
  position: relative;
}
.products_container .contents_wrap .contents_detail .contents_title .img_box .main_img {
  width: 100%;
  height: auto;
  padding-bottom: 3rem;
}
.products_container .contents_wrap .contents_detail .contents_title .img_box .main_img img {
  width: 30rem;
  margin: 1rem auto;
  aspect-ratio: 1/1;
  -o-object-fit: cover;
     object-fit: cover;
}
.products_container .contents_wrap .contents_detail .contents_title .img_box :global .slick-dots {
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  bottom: 0rem;
}
.products_container .contents_wrap .contents_detail .contents_title .img_box :global .slick-dots li {
  width: 10px;
}
.products_container .contents_wrap .contents_detail .contents_title .img_box :global .slick-dots li button::before {
  content: "";
  border: 1px solid #000;
  background: rgba(255, 255, 255, 0.4);
  width: 8px;
  height: 8px;
  border-radius: 50%;
  opacity: 1;
}
.products_container .contents_wrap .contents_detail .contents_title .img_box :global .slick-dots li.slick-active {
  width: 28px;
}
.products_container .contents_wrap .contents_detail .contents_title .img_box :global .slick-dots li.slick-active button::before {
  content: "";
  border: 1px solid rgba(255, 255, 255, 0.4);
  background: rgba(0, 0, 0, 0.4);
  width: 24px;
  height: 8px;
  border-radius: 5px;
}
.products_container .contents_wrap .contents_detail .contents_title .contents_info {
  padding: 2rem 1.6rem 0;
}
.products_container .contents_wrap .contents_detail .contents_title .contents_info .logo_wrap {
  margin-bottom: 2rem;
}
.products_container .contents_wrap .contents_detail .contents_title .contents_info .logo_wrap ul {
  display: flex;
  gap: 3.5rem;
}
.products_container .contents_wrap .contents_detail .contents_title .contents_info .logo_wrap ul li {
  height: 4.8rem;
}
.products_container .contents_wrap .contents_detail .contents_title .contents_info .logo_wrap ul li img {
  height: 4.8rem;
  width: auto;
}
.products_container .contents_wrap .contents_detail .contents_title .contents_info .title {
  width: 100%;
  border-top: 1px solid #000;
  border-bottom: 1px solid #dedede;
  padding: 2rem 3rem 2rem 0.2rem;
  position: relative;
}
.products_container .contents_wrap .contents_detail .contents_title .contents_info .title .en {
  font-size: 1.2rem;
  color: #e60013;
  font-weight: 600;
  margin-bottom: 4px;
}
.products_container .contents_wrap .contents_detail .contents_title .contents_info .title h3 {
  font-size: 2.4rem;
}
.products_container .contents_wrap .contents_detail .contents_title .contents_info .title .share_wrap {
  position: absolute;
  top: 2rem;
  right: 0rem;
  width: 3rem;
}
.products_container .contents_wrap .contents_detail .contents_title .contents_info .title .share_wrap .share_pop {
  display: none;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 100;
}
.products_container .contents_wrap .contents_detail .contents_title .contents_info .title .share_wrap .share_pop .box {
  width: 100vw;
  height: 17.7rem;
  background: #fff;
  position: absolute;
  bottom: 0;
  left: 0;
  border-radius: 2rem 2rem 0 0;
}
.products_container .contents_wrap .contents_detail .contents_title .contents_info .title .share_wrap .share_pop .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2rem 1.6rem 0;
}
.products_container .contents_wrap .contents_detail .contents_title .contents_info .title .share_wrap .share_pop .header h5 {
  font-size: 2rem;
}
.products_container .contents_wrap .contents_detail .contents_title .contents_info .title .share_wrap .share_pop .list_wrap {
  padding: 2.5rem 2rem;
}
.products_container .contents_wrap .contents_detail .contents_title .contents_info .title .share_wrap .share_pop .list_wrap ul {
  display: flex;
  justify-content: space-around;
}
.products_container .contents_wrap .contents_detail .contents_title .contents_info .title .share_wrap .share_pop .list_wrap ul li {
  color: #999;
  padding-top: 6rem;
  cursor: pointer;
  font-size: 1.4rem;
  text-align: center;
  min-width: 5rem;
  font-weight: 500;
}
.products_container .contents_wrap .contents_detail .contents_title .contents_info .title .share_wrap .share_pop .list_wrap ul li.kakao {
  background: url(../../assets/images/icon_kakao.png) no-repeat center top;
  background-size: 5rem 5rem;
}
.products_container .contents_wrap .contents_detail .contents_title .contents_info .title .share_wrap .share_pop .list_wrap ul li.facebook {
  background: url(../../assets/images/icon_facebook.png) no-repeat center top;
  background-size: 5rem 5rem;
}
.products_container .contents_wrap .contents_detail .contents_title .contents_info .title .share_wrap .share_pop .list_wrap ul li.link {
  background: url(../../assets/images/icon_link.png) no-repeat center top;
  background-size: 5rem 5rem;
}
.products_container .contents_wrap .contents_detail .contents_title .contents_info .title .share_wrap .share_pop .toast {
  height: 4.7rem;
  line-height: 4.7rem;
  width: calc(100% - 36px);
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  font-size: 1.4rem;
  font-weight: 500;
  text-align: center;
  border-radius: 10px;
  margin: 1rem auto;
  position: absolute;
  bottom: 19rem;
  left: 50%;
  transform: translateX(-50%);
}
.products_container .contents_wrap .contents_detail .contents_title .contents_info .title .share_wrap .share_pop.on {
  display: block;
}
.products_container .contents_wrap .contents_detail .contents_title .contents_info .contents {
  padding: 2rem 0;
  line-height: 2.1rem;
  width: 100%;
}
.products_container .contents_wrap .contents_detail .contents_title .contents_info .contents li {
  padding-left: 7px;
  position: relative;
}
.products_container .contents_wrap .contents_detail .contents_title .contents_info .contents li::before {
  content: "";
  display: block;
  width: 2px;
  height: 2px;
  border-radius: 50%;
  background: #000;
  position: absolute;
  top: 1.1rem;
  left: 0;
}
.products_container .contents_wrap .contents_detail .contents_title .contents_info .contents button {
  margin-top: 2.4rem;
  width: 100%;
}
.products_container .contents_wrap .contents_detail .detail_box {
  width: 100%;
  padding: 2rem 0;
  text-align: center;
}
.products_container .contents_wrap .contents_detail .bottom_btn_wrap {
  margin-bottom: 2.4rem;
  text-align: center;
}
.products_container .contents_wrap .view_content .title {
  border-top: 3px solid #000;
  border-bottom: 1px solid #000;
  padding: 2.5rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.products_container .contents_wrap .view_content .content {
  padding: 3.5rem 2rem;
  border-bottom: 1px solid #dedede;
}
.products_container .contents_wrap .view_content .content .text_box {
  padding-top: 3rem;
}
.products_container .contents_wrap .view_content .list_wrap {
  margin-bottom: 3rem;
}
.products_container .contents_wrap .view_content .list_wrap li {
  border-bottom: 1px solid #dedede;
  height: 6.9rem;
  display: flex;
  align-items: center;
  padding: 0 2rem;
  cursor: pointer;
}
.products_container .contents_wrap .view_content .list_wrap li i {
  width: 6rem;
  background: url(../../assets/images/icon_dropdown_arrow.svg) no-repeat center right;
}
.products_container .contents_wrap .view_content .list_wrap li span {
  flex: 1;
  padding: 0 4rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.products_container .contents_wrap .view_content .list_wrap li.prev i {
  background: url(../../assets/images/icon_dropdown_close.svg) no-repeat center right;
}
.products_container .contents_wrap .view_content .btn_wrap {
  text-align: right;
}
.products_container .contents_wrap .display_wrap {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem 0.8rem;
  margin-bottom: 2rem;
  padding: 2rem 1.6rem;
}
.products_container .contents_wrap .display_wrap .box {
  width: calc(50% - 4px);
  cursor: pointer;
}
.products_container .contents_wrap .display_wrap .box .img_box {
  width: 14rem;
  height: 14rem;
  margin: 0 auto;
}
.products_container .contents_wrap .display_wrap .box .img_box img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.products_container .contents_wrap .display_wrap .box p {
  margin-top: 3rem;
  text-align: center;
  word-break: break-all;
  font-weight: 500;
}
.products_container .contents_wrap .bottom_center_btn button {
  width: 100%;
  text-align: center;
  padding: 0 2rem 0 0;
  background-position: center right 45%;
}
@media (max-width: 600px) {
  .products_container .contents_wrap .bottom_center_btn button {
    background-position: center right 43%;
  }
}
@media (max-width: 375px) {
  .products_container .contents_wrap .bottom_center_btn button {
    background-position: center right 41%;
  }
}
.products_container .empty_data {
  padding: 17rem 0;
  text-align: center;
}
.products_container .empty_data p {
  padding: 11rem 0 1.6rem;
  font-size: 1.6rem;
  font-weight: bold;
  background: url(../../assets/images/ed_icon_07.svg) no-repeat center top;
}
.products_container .search_result {
  text-align: center;
  padding: 3.2rem 0 2.4rem;
}
.products_container .search_result p {
  font-size: 1.6rem;
  margin-bottom: 1.6rem;
}
.products_container .search_result p b {
  font-weight: bold;
  color: #e60013;
}