.file_box {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.file_box input[type=file] {
  display: none;
}
.file_box .file_name {
  font-size: 1.4rem;
  border: none;
  outline: none;
  flex: 1;
}
.file_box .file_name::-moz-placeholder {
  color: #999;
}
.file_box .file_name::placeholder {
  color: #999;
}

.img_file_box {
  display: inline-block;
  margin-right: 1rem;
}
.img_file_box input[type=file] {
  display: none;
}
.img_file_box label {
  display: inline-block;
  height: 4rem;
  line-height: 3.8rem;
  font-size: 1.6rem;
  min-width: 7.8rem;
  padding: 0 1.7rem;
  color: #000;
  border: 1px solid #000;
  text-align: center;
  border-radius: 3.5rem;
  background: #fff;
  font-weight: 500;
}