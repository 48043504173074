.tournament_container {
  width: 100%;
  min-height: calc(100vh - 270px);
}
.tournament_container .type_a {
  color: #cbb300;
}
.tournament_container .type_b {
  color: #528ee0;
}
.tournament_container .type_c {
  color: #d37a39;
}
.tournament_container .type_d {
  color: #45b752;
}
.tournament_container .tournament_sub_header {
  height: 8rem;
  line-height: 8rem;
  background: #3e21d9;
  color: #fff;
}
.tournament_container .tournament_sub_header h2 {
  font-size: 2.6rem;
  max-width: 1200px;
  min-width: 789px;
  margin: 0 auto;
}
@media (max-width: 1200px) {
  .tournament_container .tournament_sub_header {
    padding-left: 2rem;
  }
}
.tournament_container .tournament_img_header {
  border-top: 10px solid #3e21d9;
  height: 40rem;
  position: relative;
  color: #fff;
}
.tournament_container .tournament_img_header::before {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
}
.tournament_container .tournament_img_header::after {
  content: "";
  display: block;
  max-width: 1400px;
  height: 8rem;
  background: #fff;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
.tournament_container .tournament_img_header img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.tournament_container .tournament_img_header .title {
  max-width: 1400px;
  margin: 0 auto;
  position: absolute;
  width: 100%;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}
.tournament_container .tournament_img_header .title h2 {
  max-width: 1200px;
  margin: 10.5rem auto 2rem;
  font-size: 4rem;
}
.tournament_container .tournament_img_header .title p {
  max-width: 1200px;
  margin: 0 auto;
  font-size: 1.8rem;
  font-weight: 500;
}
@media (max-width: 1200px) {
  .tournament_container .tournament_img_header {
    margin-bottom: 6rem;
  }
  .tournament_container .tournament_img_header h2,
  .tournament_container .tournament_img_header p {
    padding-left: 2rem;
  }
  .tournament_container .tournament_img_header::after {
    display: none;
  }
}
.tournament_container section {
  max-width: 1200px;
  min-width: 789px;
  margin: 5rem auto 10rem;
}
.tournament_container section .search_wrap {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 2rem;
  position: relative;
}
.tournament_container section .search_wrap input {
  width: 29.5rem;
  padding-right: 4rem;
}
.tournament_container section .search_wrap button {
  position: absolute;
  top: 0.7rem;
  right: 1.5rem;
}
@media (max-width: 1200px) {
  .tournament_container section {
    padding: 0 1rem;
  }
}
.tournament_container .view_content {
  padding-bottom: 5rem;
}
.tournament_container .view_content b {
  font-weight: 600;
}
.tournament_container .view_content b.type_a {
  color: #cbb300;
}
.tournament_container .view_content b.type_b {
  color: #528ee0;
}
.tournament_container .view_content b.type_c {
  color: #d37a39;
}
.tournament_container .view_content b.type_d {
  color: #45b752;
}
.tournament_container .view_content .title {
  border-top: 3px solid #000;
  border-bottom: 1px solid #000;
  padding: 2.3rem 2rem;
  min-height: 10.5rem;
  position: relative;
}
.tournament_container .view_content .title b {
  margin-bottom: 1rem;
  display: block;
}
.tournament_container .view_content .title h3 {
  font-size: 2.2rem;
}
.tournament_container .view_content .title em,
.tournament_container .view_content .title button {
  position: absolute;
  top: 2.5rem;
  right: 2rem;
}
.tournament_container .view_content .content_wrap {
  padding: 3.5rem 2rem;
}
.tournament_container .view_content .content_wrap .download {
  font-size: 1.4rem;
  color: #3e21d9;
  text-decoration: underline;
  text-underline-position: under;
  margin-bottom: 3rem;
  font-weight: 500;
  display: inline-block;
  padding-right: 1.5rem;
  background: url(../../assets/images/icon_download.svg) no-repeat center right;
}
.tournament_container .view_content .content_wrap .content {
  line-height: 2.5rem;
}
.tournament_container .view_content .content_wrap.table_st {
  padding: 0;
}
.tournament_container .view_content .content_wrap.table_st h4 {
  margin-top: 5rem;
}
.tournament_container .view_content .content_wrap.table_st table {
  border-top: 0;
  margin-bottom: 3rem;
}
.tournament_container .view_content .content_wrap.table_st table tr {
  cursor: default;
}
.tournament_container .view_content .content_wrap.table_st table th,
.tournament_container .view_content .content_wrap.table_st table td {
  padding: 1.6rem 2rem;
}
.tournament_container .view_content .content_wrap.table_st table td.flex_row {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1rem;
  height: auto;
  min-height: 5.9rem;
}
.tournament_container .view_content .content_wrap.table_st table td.flex_row ul li {
  height: 3.3rem;
  line-height: 3.3rem;
  padding: 0 2rem;
  font-size: 1.4rem;
  font-weight: 500;
}
.tournament_container .view_content .content_wrap.table_st table td.flex_row ul li span {
  color: #3e21d9;
  margin-right: 1.5rem;
}
.tournament_container .view_content .content_wrap.table_st table td.flex_row ul li.rest {
  background: #e5f7f5 !important;
}
.tournament_container .view_content .content_wrap.table_st table td.flex_row ul li:nth-child(2n+1) {
  background: #f7f9fa;
}
.tournament_container .view_content .content_wrap.table_st table td.flex_row ul li:nth-child(1) {
  font-weight: bold;
  color: #fff;
  background: #3e21d9;
}
.tournament_container .view_content .content_wrap.table_st table td.flex_row ul:nth-child(2) li {
  background: #fff;
}
.tournament_container .view_content .content_wrap.table_st table td.flex_row ul:nth-child(2) li:nth-child(2n) {
  background: #f7f9fa;
}
.tournament_container .view_content .content_wrap.table_st table td.flex_row ul:nth-child(2) li:nth-child(1) {
  background: #02a496;
}
.tournament_container .view_content .content_wrap.table_st table td.flex_row ul:nth-child(2) li span {
  color: #02a496;
}
.tournament_container .view_content .content_wrap.search_result_table h5 {
  font-size: 1.8rem;
  font-weight: 500;
  margin-bottom: 2rem;
}
.tournament_container .view_content .content_wrap.search_result_table h5 span {
  font-weight: 500;
  margin-left: 1.5rem;
}
.tournament_container .view_content .content_wrap.search_result_table table {
  margin-bottom: 5rem;
}
.tournament_container .view_content .content_wrap.search_result_table table span {
  font-weight: 500;
}
.tournament_container .view_content .list_wrap {
  border-top: 1px solid #dedede;
  margin-bottom: 3rem;
}
.tournament_container .view_content .list_wrap li {
  border-bottom: 1px solid #dedede;
  height: 6.9rem;
  display: flex;
  align-items: center;
  padding: 0 2rem;
  cursor: pointer;
}
.tournament_container .view_content .list_wrap li i {
  width: 6rem;
  background: url(../../assets/images/icon_dropdown_arrow.svg) no-repeat center right;
}
.tournament_container .view_content .list_wrap li b {
  padding: 2rem;
  width: 16rem;
}
.tournament_container .view_content .list_wrap li span {
  flex: 1;
}
.tournament_container .view_content .list_wrap li.prev i {
  background: url(../../assets/images/icon_dropdown_close.svg) no-repeat center right;
}
.tournament_container .view_content .btn_wrap {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
}
.tournament_container .table_btn_st table tr {
  cursor: default;
}
.tournament_container .table_btn_st table tr td {
  padding: 0 0.5rem;
  line-height: 2.2rem;
  overflow: initial;
  text-overflow: initial;
  white-space: initial;
  word-wrap: break-word;
}
.tournament_container .table_btn_st table tr td:nth-child(2) {
  cursor: pointer;
}
.tournament_container .table_btn_st table tr td:nth-child(7), .tournament_container .table_btn_st table tr td:nth-child(8) {
  padding: 1.05rem 0;
  text-overflow: initial;
}
.tournament_container .table_btn_st table tbody tr:hover {
  background: #f7f9fa;
}
@media (max-width: 1199px) {
  .tournament_container .table_btn_st table tr th,
  .tournament_container .table_btn_st table tr td {
    text-overflow: initial;
    overflow: initial;
    white-space: inherit;
  }
  .tournament_container .table_btn_st table tr td:nth-child(8) button {
    padding-left: 0;
    padding-right: 0;
    border: none;
    text-decoration: underline;
    text-underline-position: under;
    background: transparent;
  }
}
.tournament_container .card_list_wrap .card {
  margin-bottom: 1.5rem;
  padding: 4rem 4.5rem;
  position: relative;
  background: #f7f9fa;
  cursor: pointer;
}
.tournament_container .card_list_wrap .card .title b {
  display: block;
  margin-bottom: 1rem;
}
.tournament_container .card_list_wrap .card .title h3 {
  font-size: 2.2rem;
}
.tournament_container .card_list_wrap .card .title button {
  position: absolute;
  top: 8.2rem;
  right: 5.1rem;
}
.tournament_container .card_list_wrap .card .title .state {
  position: absolute;
  width: 12rem;
  height: 12rem;
  line-height: 12rem;
  text-align: center;
  border-radius: 50%;
  font-size: 2.2rem;
  background: #f2f2f2;
  color: #999999;
  font-weight: 500;
  top: 6.2rem;
  right: 8rem;
}
.tournament_container .card_list_wrap .card .title .state.ing {
  width: 16rem;
  color: #fff;
  background: url(../../assets/images/circle_bg.png) no-repeat center;
  border-radius: 0;
}
.tournament_container .card_list_wrap .card .title .state.to_be {
  background: #02a496;
  color: #fff;
}
.tournament_container .card_list_wrap .card .title .state.end {
  background: #f2f2f2;
  color: #999999;
}
@media (max-width: 1200px) {
  .tournament_container .card_list_wrap .card .title .state,
  .tournament_container .card_list_wrap .card .title button {
    top: 3rem;
  }
}
.tournament_container .card_list_wrap .card .info {
  margin-top: 3rem;
  line-height: 2.6rem;
}
.tournament_container .card_list_wrap .card .info li {
  position: relative;
  padding-left: 7px;
}
.tournament_container .card_list_wrap .card .info li::before {
  content: "";
  display: block;
  position: absolute;
  width: 2px;
  height: 2px;
  background: #000;
  border-radius: 50%;
  left: 0;
  top: 12px;
}
.tournament_container .card_list_wrap .btn_wrap_center {
  display: flex;
  justify-content: center;
  padding-top: 1.5rem;
}
.tournament_container .img_type {
  margin-top: 0;
}
.tournament_container .img_type .view_content h3 {
  font-size: 2.4rem;
  padding-bottom: 2rem;
  border-bottom: 3px solid #000;
}
.tournament_container .img_type .view_content h4 {
  font-size: 2.4rem;
  padding-bottom: 2rem;
}
.tournament_container .result_detail_st {
  max-width: 1920px;
}
.tournament_container .result_detail_st .view_content {
  padding-bottom: 0;
}
.tournament_container .result_detail_st .view_content .search_wrap {
  margin-bottom: 0;
}
.tournament_container .result_detail_st .view_content .result_detail_header {
  max-width: 1200px;
  margin: 0 auto;
}
.tournament_container .result_detail_st .content_wrap.table_st {
  max-width: 1200px;
  margin: 0 auto;
}
.tournament_container .result_detail_st .content_wrap.table_st .inner_header {
  padding: 5rem 0 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.tournament_container .result_detail_st .content_wrap.table_st .inner_header h4 {
  margin-top: 0;
  padding-bottom: 0;
}
.tournament_container .result_award_wrap {
  margin: 5rem 0 10rem;
}
.tournament_container .result_award_wrap .result_award {
  display: flex;
  gap: 1.3rem;
  justify-content: space-between;
}
.tournament_container .result_award_wrap .result_award .award_bx {
  width: 23rem;
  height: 26rem;
  padding: 2.5rem 2.5rem 2rem 2.5rem;
  background: #3e21d9;
  position: relative;
  color: #fff;
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
}
.tournament_container .result_award_wrap .result_award .award_bx span {
  position: absolute;
  top: 1rem;
  right: 1rem;
  display: block;
  min-width: 3.6rem;
  height: 3.6rem;
  line-height: 3.6rem;
  padding: 0 1rem;
  background: #3e21d9;
  border-radius: 1.8rem;
  font-size: 1.4rem;
}
.tournament_container .result_award_wrap .result_award .award_bx span i {
  font-size: 1rem;
}
.tournament_container .result_award_wrap .result_award .award_bx img {
  width: 18rem;
  height: 18rem;
  margin-bottom: 1rem;
  -o-object-fit: cover;
     object-fit: cover;
}
.tournament_container .result_award_wrap .result_award .award_bx.award_1 span {
  background: #ffeb00;
  color: #000;
}
.tournament_container .result_award_wrap .result_award .award_bx.award_2 span {
  background: #02a496;
}
.tournament_container .result_award_wrap .result_award .award_bx.award_3 span {
  background: #eb8e30;
  color: #000;
}
.tournament_container .result_award_wrap .result_award .award_bx.award_all {
  background: #000;
}
.tournament_container .result_award_wrap .result_award .award_bx.award_perfect {
  background: #404040;
}
@media (max-width: 1280px) {
  .tournament_container .result_award_wrap .result_award .award_bx {
    width: 18vw;
    height: 20vw;
    padding: 1.5vw;
  }
  .tournament_container .result_award_wrap .result_award .award_bx img {
    width: 14vw;
    height: 14vw;
  }
}
.tournament_container .result_gallery_wrap {
  margin-top: 5rem;
  padding: 10rem 0;
  background: #f7f9fa;
}
.tournament_container .result_gallery_wrap h4 {
  font-size: 2.4rem;
  padding-bottom: 2rem;
  padding-left: 1.6rem;
  max-width: 1200px;
  margin: 0 auto;
}
.tournament_container .result_gallery_wrap .result_gallery {
  max-width: 1200px;
  margin: 0 auto;
}
.tournament_container .result_gallery_wrap .result_gallery .content {
  height: 38rem;
  padding: 0 1.5rem;
}
.tournament_container .result_gallery_wrap .result_gallery .content img {
  background: lightblue;
  height: 100%;
}
.tournament_container .result_gallery_wrap .result_gallery :global .slick-prev,
.tournament_container .result_gallery_wrap .result_gallery :global .slick-next {
  width: 5rem;
  height: 5rem;
  top: 17.5rem;
}
.tournament_container .result_gallery_wrap .result_gallery :global .slick-prev::before,
.tournament_container .result_gallery_wrap .result_gallery :global .slick-next::before {
  display: none;
}
.tournament_container .result_gallery_wrap .result_gallery :global .slick-prev {
  background: url(../../assets/images/btn_default_l.svg) no-repeat;
  left: -7rem !important;
}
.tournament_container .result_gallery_wrap .result_gallery :global .slick-prev:hover {
  background: url(../../assets/images/btn_default_l_b.svg) no-repeat;
}
.tournament_container .result_gallery_wrap .result_gallery :global .slick-next {
  background: url(../../assets/images/btn_default_r.svg) no-repeat;
  right: -7rem !important;
}
.tournament_container .result_gallery_wrap .result_gallery :global .slick-next:hover {
  background: url(../../assets/images/btn_default_r_b.svg) no-repeat;
}
@media (max-width: 1360px) {
  .tournament_container .result_gallery_wrap .result_gallery :global .slick-prev {
    display: none;
    left: 3rem !important;
    z-index: 1;
    opacity: 0.5;
  }
  .tournament_container .result_gallery_wrap .result_gallery :global .slick-next {
    display: none;
    right: 3rem !important;
    z-index: 1;
    opacity: 0.5;
  }
}

.bottom_btn_wrap {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
  max-width: 1200px;
  margin: 2rem auto 15rem;
}