.pagination {
  padding: 5rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.pagination ul {
  display: flex;
  justify-content: center;
  gap: 1.5rem;
}
.pagination ul li {
  cursor: pointer;
  min-width: 2.2rem;
  padding: 0 0.5rem;
  height: 2.2rem;
  line-height: 2.2rem;
  border-radius: 1.1rem;
  text-align: center;
  font-size: 1.4rem;
}
.pagination ul li.on {
  color: #fff;
  background: #000;
}
.pagination span {
  cursor: pointer;
  width: 2.2rem;
  height: 2.2rem;
  background: url(../../../assets/images/icon_btn_text.svg) no-repeat center;
}
.pagination span.first {
  transform: rotate(180deg);
}
.pagination span.first::before {
  content: "";
  display: inline-block;
  width: 1rem;
  height: 2.2rem;
  background: url(../../../assets/images/icon_btn_text.svg) no-repeat center;
}
.pagination span.prev {
  transform: rotate(180deg);
}
.pagination span.last::before {
  content: "";
  display: inline-block;
  width: 1rem;
  height: 2.2rem;
  background: url(../../../assets/images/icon_btn_text.svg) no-repeat center;
}
.pagination span.disable {
  opacity: 0.5;
  cursor: default;
}
.pagination.admin_st {
  padding: 2.5rem 0 1rem;
}
.pagination.admin_st ul li {
  min-width: 2rem;
  padding: 0 0.5rem;
  height: 2rem;
  line-height: 2rem;
  border-radius: 1rem;
}
.pagination.admin_st ul li.on {
  background: #3e21d9;
}