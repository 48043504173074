.login_wrap h3 {
  font-size: 2rem;
  color: #fff;
  margin-bottom: 1.5rem;
}
.login_wrap form {
  display: flex;
  gap: 1rem;
  margin-bottom: 1.5rem;
}
.login_wrap form label {
  display: none;
}
.login_wrap .find_btn_wrap {
  display: flex;
  gap: 2rem;
}
.login_wrap.official_st h3 {
  display: none;
}
.login_wrap.official_st form {
  flex-direction: column;
  gap: 1.5rem;
  position: relative;
}
.login_wrap.official_st form > div {
  padding-top: 2.4rem;
}
.login_wrap.official_st form > div:nth-child(3) {
  padding-top: 0;
  padding-bottom: 5px;
}
.login_wrap.official_st form label {
  display: block;
  font-size: 1.4rem;
  font-weight: bold;
  position: absolute;
  top: 0px;
}
.login_wrap.official_st form input[type=checkbox] + label {
  position: inherit;
}
.login_wrap.official_st form button[type=submit] {
  width: 9rem !important;
  margin: 0 auto;
  border-radius: 2rem;
}
.login_wrap.official_st .find_btn_wrap {
  display: none;
}
@media (max-width: 959px) {
  .login_wrap.official_st form button[type=submit] {
    width: 100% !important;
    height: 4.5rem;
  }
}