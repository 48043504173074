.media_container {
  position: relative;
  width: 100%;
  min-height: calc(100vh - 80px);
  min-width: inherit;
  padding-bottom: 3rem;
  padding-top: 14.3rem;
}
.media_container .flex_row {
  display: flex;
  align-items: center;
  gap: 2rem;
}
.media_container .desc_s {
  font-size: 1.4rem;
  color: #999;
  line-height: 2.2rem;
}
.media_container .info_title {
  margin-bottom: 2rem;
  line-height: 2.2rem;
}
.media_container .table_wrap .fixed {
  background: #f7f9fa;
}
.media_container .table_wrap .fixed td {
  color: #e60013;
  font-weight: 600;
}
.media_container .bottom_center_btn {
  text-align: center;
  margin-top: 2rem;
}
.media_container .bottom_center_btn button {
  width: 100%;
  text-align: center;
  background-position: 52vw;
  padding: 0;
  padding-right: 3rem;
}
.media_container .mobile_table_wrap table {
  border-top: none;
}
.media_container .mobile_table_wrap table thead,
.media_container .mobile_table_wrap table colgroup {
  display: none;
}
.media_container .mobile_table_wrap table tr {
  border-bottom: 1px solid #dedede;
  display: block;
  padding: 2rem;
  position: relative;
}
.media_container .mobile_table_wrap table tr.fixed td {
  color: #e60013;
  font-weight: 600;
}
.media_container .mobile_table_wrap table tr.fixed td:nth-child(3) {
  font-size: 1.2rem;
  color: #999;
  font-weight: 500;
}
.media_container .mobile_table_wrap table td {
  display: block;
  border-bottom: none;
  padding: 0;
  padding-left: 3.5rem;
  height: initial;
  line-height: 1.8rem;
}
.media_container .mobile_table_wrap table td:first-child {
  position: absolute;
  padding-left: 0rem;
  left: 2rem;
}
.media_container .mobile_table_wrap table td:nth-child(2) {
  font-size: 1.6rem;
  font-weight: 500;
  text-overflow: initial;
  overflow: initial;
  white-space: wrap;
  margin-bottom: 8px;
}
.media_container .mobile_table_wrap table td:nth-child(3) {
  font-size: 1.2rem;
  color: #999;
}
.media_container .mobile_table_wrap .bottom_center_btn {
  padding: 0 1.6rem;
}
.media_container .view_content {
  padding: 0 1.6rem;
}
.media_container .view_content .title {
  border-bottom: 1px solid #000;
  padding: 2rem 0.4rem;
}
.media_container .view_content .title h4 {
  font-size: 1.6rem;
  font-weight: 600;
  margin-bottom: 4px;
}
.media_container .view_content .title .date {
  font-size: 1.2rem;
  color: #999;
  font-weight: 500;
}
.media_container .view_content .content {
  padding: 2.4rem 0.4rem;
  border-bottom: 1px solid #dedede;
}
.media_container .view_content .content .text_box {
  padding-top: 3rem;
}
.media_container .view_content .list_wrap {
  margin-bottom: 3rem;
}
.media_container .view_content .list_wrap li {
  border-bottom: 1px solid #dedede;
  min-height: 6.9rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1.6rem 1.6rem 1.6rem 8.2rem;
  cursor: pointer;
  position: relative;
}
.media_container .view_content .list_wrap li i {
  width: 6rem;
  background: url(../../assets/images/icon_dropdown_arrow.svg) no-repeat center right;
  position: absolute;
  top: 50%;
  left: 4px;
  transform: translateY(-50%);
}
.media_container .view_content .list_wrap li span {
  width: 100%;
  margin-bottom: 4px;
  font-weight: 500;
}
.media_container .view_content .list_wrap li em {
  font-size: 1.2rem;
  font-weight: 500;
  color: #999;
}
.media_container .view_content .list_wrap li.prev i {
  background: url(../../assets/images/icon_dropdown_close.svg) no-repeat center right;
}
.media_container .view_content .btn_wrap {
  text-align: center;
}
.media_container .media_sub_nav {
  padding: 0.8rem 1.6rem 0;
  border-bottom: 1px solid #dedede;
  position: fixed;
  top: 4.3rem;
  left: 0;
  background: #fff;
  z-index: 10;
  width: 100%;
}
.media_container .media_sub_nav h3 {
  margin: 2rem 0 0.4rem;
  font-size: 2rem;
}
.media_container .media_sub_nav nav ul {
  display: flex;
}
.media_container .media_sub_nav nav .depth_1_link {
  height: 4.2rem;
  flex: 1;
  text-align: center;
}
.media_container .media_sub_nav nav .depth_1_link a {
  padding-top: 1.6rem;
  padding: 16px 4px 0;
  margin-right: 1.2rem;
  height: 100%;
  display: block;
  color: #999;
}
.media_container .media_sub_nav nav .depth_1_link :global .active {
  color: #000;
  font-weight: bold;
  border-bottom: 2px solid #000;
}
.media_container section .media_contents {
  width: 100%;
  height: 100%;
  position: relative;
}
.media_container .contents_wrap {
  width: 100%;
  padding: 0 0 15rem 0;
}
.media_container .contents_wrap .contents_title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.media_container .contents_wrap .contents_title h3 {
  font-size: 2.4rem;
}
.media_container .contents_wrap .contents_title .search_wrap {
  width: 100%;
  position: relative;
}
.media_container .contents_wrap .contents_title .search_wrap input {
  height: 5rem;
  padding: 0 5rem 0 2rem;
  background: #f7f9fa;
  background-position: center right 24px;
  border-top: 0;
  border-left: 0;
  border-right: 0;
}
.media_container .contents_wrap .contents_title .search_wrap button {
  position: absolute;
  top: 12px;
  right: 24px;
}
.media_container .empty_data {
  padding: 13rem 0 0.5rem 0;
  text-align: center;
}
.media_container .empty_data p {
  padding: 11rem 0 3rem;
  font-size: 1.6rem;
  font-weight: bold;
  background: url(../../assets/images/ed_icon_07.svg) no-repeat center top;
}
.media_container .tab_menu {
  width: 100%;
  display: flex;
  gap: 0.8rem;
  flex-wrap: wrap;
  padding: 1.2rem 1.6rem;
}
.media_container .tab_menu li {
  width: auto;
  padding: 0 1.6rem;
  height: 3.3rem;
  line-height: 3.1rem;
  background: #fff;
  color: #999;
  font-weight: 500;
  text-align: center;
  cursor: pointer;
  border: 1px solid #dedede;
}
.media_container .tab_menu li.on {
  background: #e60013;
  border-color: #e60013;
  color: #fff;
  font-weight: bold;
}
.media_container .tab_contents_wrap {
  padding: 0 1.6rem;
}
.media_container .tab_contents_wrap .faq_wrap {
  margin-bottom: 4rem;
}
.media_container .tab_contents_wrap .faq_wrap h3 span {
  display: none !important;
}
.media_container .tab_contents_wrap .result_wrap h4 {
  font-size: 1.8rem;
  margin-bottom: 1rem;
}
.media_container .tab_contents_wrap .result_wrap button {
  display: block;
  margin: 4rem auto;
}