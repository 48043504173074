.media_container {
  position: relative;
  width: 100%;
  max-width: 1920px;
  min-height: calc(100vh - 80px);
  margin: 0 auto;
  padding-top: 8rem;
  padding-left: 26rem;
}
.media_container .flex_row {
  display: flex;
  align-items: center;
  gap: 2rem;
}
.media_container .desc_s {
  font-size: 1.4rem;
  color: #999;
  line-height: 2.2rem;
}
.media_container .info_title {
  margin-bottom: 2rem;
  line-height: 2.2rem;
}
.media_container .table_wrap .fixed {
  background: #f7f9fa;
}
.media_container .table_wrap .fixed td {
  color: #e60013;
  font-weight: 600;
}
@media (max-width: 1560px) {
  .media_container .table_wrap td {
    text-overflow: initial;
    overflow: initial;
    white-space: initial;
  }
}
.media_container .view_content .title {
  border-top: 3px solid #000;
  border-bottom: 1px solid #000;
  padding: 2.5rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.media_container .view_content .content {
  padding: 3.5rem 2rem;
  border-bottom: 1px solid #dedede;
}
.media_container .view_content .content .text_box {
  padding-top: 3rem;
}
.media_container .view_content .list_wrap {
  margin-bottom: 3rem;
}
.media_container .view_content .list_wrap li {
  border-bottom: 1px solid #dedede;
  height: 6.9rem;
  display: flex;
  align-items: center;
  padding: 0 2rem;
  cursor: pointer;
}
.media_container .view_content .list_wrap li i {
  width: 6rem;
  background: url(../../assets/images/icon_dropdown_arrow.svg) no-repeat center right;
}
.media_container .view_content .list_wrap li span {
  flex: 1;
  padding: 0 4rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.media_container .view_content .list_wrap li.prev i {
  background: url(../../assets/images/icon_dropdown_close.svg) no-repeat center right;
}
.media_container .view_content .btn_wrap {
  text-align: right;
}
.media_container .media_sub_nav {
  height: calc(100vh - 80px);
  position: absolute;
  background: #fff;
  width: 26rem;
  top: 8rem;
  left: 0;
  border-right: 1px solid #dedede;
  z-index: 1;
}
.media_container .media_sub_nav h3 {
  height: 5.5rem;
  line-height: 5.5rem;
  background: #000;
  color: #fff;
  font-size: 1.8rem;
  padding-left: 2.5rem;
}
.media_container .media_sub_nav nav li {
  height: 5rem;
  line-height: 4.9rem;
  border-bottom: 1px solid #dedede;
  color: #999;
}
.media_container .media_sub_nav nav li a {
  display: block;
  padding-left: 2rem;
}
.media_container .media_sub_nav nav :global .active {
  background-color: #f8fafa;
  color: #000;
  font-weight: bold;
}
.media_container section .media_contents {
  width: 100%;
  height: 100%;
  position: relative;
}
.media_container .contents_wrap {
  width: 100%;
  max-width: 135rem;
  padding: 6rem 5rem 15rem 10rem;
}
.media_container .contents_wrap .contents_title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 2rem;
  height: 3.9rem;
}
.media_container .contents_wrap .contents_title h3 {
  font-size: 2.4rem;
}
.media_container .contents_wrap .contents_title div {
  width: 29.5rem;
}
.media_container .contents_wrap .contents_title .desc {
  width: 100%;
  margin-top: 1rem;
}
.media_container .contents_wrap .contents_title .search_wrap {
  display: flex;
  justify-content: flex-end;
  position: relative;
}
.media_container .contents_wrap .contents_title .search_wrap input {
  width: 29.5rem;
  padding-right: 4rem;
}
.media_container .contents_wrap .contents_title .search_wrap button {
  position: absolute;
  top: 0.7rem;
  right: 1.5rem;
}
@media (max-width: 1560px) {
  .media_container .contents_wrap {
    padding: 1.5vw 2.5vw 7.813vw 2.5vw;
  }
}
.media_container .faq_search_box {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  width: 60rem;
  margin: 1rem auto 7.5rem;
  border-bottom: 5px solid #000;
}
.media_container .faq_search_box div {
  flex: 1;
}
.media_container .faq_search_box div input {
  border: none;
  height: 5.5rem;
}
.media_container .faq_search_box button {
  width: 3rem;
  height: 3rem;
  background-size: 3rem 3rem;
}
.media_container .empty_data {
  padding: 13rem 0 0.5rem 0;
  text-align: center;
}
.media_container .empty_data p {
  padding: 11rem 0 3rem;
  font-size: 2.4rem;
  font-weight: bold;
  background: url(../../assets/images/ed_icon_07.svg) no-repeat center top;
}
.media_container .tab_menu {
  width: 100%;
  display: flex;
  margin-bottom: 2rem;
}
.media_container .tab_menu li {
  width: 50%;
  height: 5rem;
  line-height: 5rem;
  background: #f7f9fa;
  color: #999;
  font-weight: 500;
  text-align: center;
  cursor: pointer;
}
.media_container .tab_menu li.on {
  background: #e60013;
  color: #fff;
  font-weight: bold;
}
.media_container .tab_contents_wrap {
  padding-top: 4rem;
}
.media_container .tab_contents_wrap .faq_wrap {
  margin-bottom: 4rem;
}
.media_container .tab_contents_wrap .result_wrap h4 {
  font-size: 1.8rem;
  margin-bottom: 1rem;
}
.media_container .tab_contents_wrap .result_wrap button {
  display: block;
  margin: 4rem auto;
}
.media_container .tab_contents_wrap .btn_center {
  margin-top: 4rem;
  text-align: center;
}