.introduce_container {
  position: relative;
  width: 100%;
  min-width: initial;
  min-height: calc(100vh - 80px);
  margin: 0 auto;
  padding-top: 14.3rem;
}
.introduce_container .flex_row {
  display: flex;
  align-items: center;
  gap: 2rem;
}
.introduce_container .desc_s {
  font-size: 1.4rem;
  color: #999;
  line-height: 2.2rem;
}
.introduce_container .modal_title {
  margin-bottom: 1rem;
}
.introduce_container .modal_title h4 {
  font-size: 1.8rem;
  margin-bottom: 6px;
}
.introduce_container .modal_title p {
  color: #999;
}
.introduce_container .intro_box_wrap {
  width: 100%;
  padding: 2rem 1.6rem;
}
.introduce_container .intro_box_wrap .img_box {
  margin-bottom: 2rem;
}
.introduce_container .intro_box_wrap .img_box img {
  /* width: 100%;
  aspect-ratio: 8/5;
  object-fit: cover; */
  width: 32.8rem;
  height: 20rem;
}
.introduce_container .intro_box_wrap .txt_box h4 {
  font-size: 1.6rem;
  font-weight: 600;
  margin-bottom: 2rem;
}
.introduce_container .intro_box_wrap .txt_box div {
  margin-bottom: 2rem;
  line-height: 2.2rem;
}
.introduce_container .intro_box_wrap .txt_box div:last-child {
  margin-bottom: 0;
}
.introduce_container .intro_box_wrap .txt_box div.ceo {
  font-size: 1.2rem;
  text-align: right;
  margin-top: 3.2rem;
}
.introduce_container .intro_box_wrap .txt_box div.ceo span {
  color: #999;
  margin-bottom: 4px;
}
.introduce_container .intro_box_wrap .txt_box div.ceo p {
  line-height: 2.2rem;
}
.introduce_container .intro_box_wrap .txt_box div.ceo p b {
  font-size: 2rem;
  font-weight: bold;
}
@media (max-width: 600px) {
  .introduce_container .intro_box_wrap .img_box {
    margin-bottom: 2rem;
  }
  .introduce_container .intro_box_wrap .img_box img {
    width: 100%;
    height: auto;
    aspect-ratio: 8/5;
    -o-object-fit: cover;
       object-fit: cover;
  }
}
.introduce_container .history_box_wrap {
  padding: 2rem 1.6rem;
}
.introduce_container .history_box_wrap .intro_txt {
  line-height: 2.2rem;
}
.introduce_container .history_box_wrap .history_box {
  margin-top: 1.6rem;
}
.introduce_container .history_box_wrap .history_box .box {
  position: relative;
  padding-top: 2.4rem;
}
.introduce_container .history_box_wrap .history_box .box .year {
  font-size: 2.4rem;
  font-weight: bold;
  display: block;
  margin-bottom: 4px;
}
.introduce_container .history_box_wrap .history_box .box .list_box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 14px;
}
.introduce_container .history_box_wrap .history_box .box .history {
  display: flex;
  align-items: flex-start;
}
.introduce_container .history_box_wrap .history_box .box .history .month {
  color: #e60013;
  font-size: 1.6rem;
  font-weight: 600;
  margin-right: 1.6rem;
  display: block;
  width: 2.2rem;
}
.introduce_container .history_box_wrap .history_box .box .history p {
  line-height: 2rem;
  flex: 1;
}
.introduce_container .staff_box_wrap {
  padding: 2rem 1.6rem;
}
.introduce_container .staff_box_wrap .inner {
  display: flex;
  flex-wrap: wrap;
  gap: 20px 8px;
  justify-content: space-evenly;
}
.introduce_container .staff_box_wrap .inner .staff_box {
  width: calc(50% - 4px);
  max-width: 20rem;
  position: relative;
  cursor: pointer;
}
.introduce_container .staff_box_wrap .inner .staff_box img {
  width: 100%;
  height: auto;
  aspect-ratio: 0.84/1;
  -o-object-fit: cover;
     object-fit: cover;
}
.introduce_container .staff_box_wrap .inner .staff_box .info_txt {
  color: #999;
}
.introduce_container .staff_box_wrap .inner .staff_box .info_txt b {
  font-size: 1.6rem;
  color: #000;
  font-weight: 600;
  margin: 8px 0 4px;
  display: block;
}
.introduce_container .table_wrap td button {
  color: #e60013;
  font-weight: 600;
  padding-right: 0;
}
.introduce_container .view_content .title {
  border-top: 3px solid #000;
  border-bottom: 1px solid #000;
  padding: 2.5rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.introduce_container .view_content .title h3 {
  font-size: 2.2rem;
}
.introduce_container .view_content .content {
  padding: 3.5rem 2rem;
  border-bottom: 1px solid #dedede;
}
.introduce_container .view_content .content .text_box {
  padding-top: 3rem;
}
.introduce_container .view_content .content .img_box {
  width: 100%;
}
.introduce_container .view_content .list_wrap {
  margin-bottom: 3rem;
}
.introduce_container .view_content .list_wrap li {
  border-bottom: 1px solid #dedede;
  height: 6.9rem;
  display: flex;
  align-items: center;
  padding: 0 2rem;
  cursor: pointer;
}
.introduce_container .view_content .list_wrap li i {
  width: 6rem;
  background: url(../../assets/images/icon_dropdown_arrow.svg) no-repeat center right;
}
.introduce_container .view_content .list_wrap li span {
  flex: 1;
  padding: 0 4rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.introduce_container .view_content .list_wrap li.prev i {
  background: url(../../assets/images/icon_dropdown_close.svg) no-repeat center right;
}
.introduce_container .view_content .btn_wrap {
  text-align: right;
}
.introduce_container .introduce_sub_nav {
  padding: 0.8rem 1.6rem 0;
  border-bottom: 1px solid #dedede;
  position: fixed;
  top: 4.3rem;
  left: 0;
  background: #fff;
  z-index: 10;
  width: 100%;
}
.introduce_container .introduce_sub_nav h3 {
  margin: 2rem 0 0.4rem;
  font-size: 2rem;
}
.introduce_container .introduce_sub_nav .depth_1_link {
  height: 4.2rem;
}
.introduce_container .introduce_sub_nav .depth_1_link a {
  padding-top: 1.6rem;
  padding: 16px 4px 0;
  margin-right: 1.2rem;
  height: 100%;
  display: block;
  color: #999;
}
.introduce_container .introduce_sub_nav .depth_1_link :global .active {
  color: #000;
  font-weight: bold;
  border-bottom: 2px solid #000;
}
@media (max-width: 500px) {
  .introduce_container .introduce_sub_nav :global .header_sub_nav {
    width: 50rem;
  }
}
.introduce_container .introduce_sub_nav :global .slick-track {
  margin-left: 0;
  margin-right: 0;
}
.introduce_container section .introduce_contents {
  width: 100%;
  height: 100%;
  position: relative;
}
.introduce_container .contents_wrap {
  width: 100%;
  padding: 0 0 4rem 0;
}
.introduce_container .contents_wrap .bottom_center_btn {
  text-align: center;
  margin-top: 2rem;
}
.introduce_container .contents_wrap .bottom_center_btn button {
  width: 100%;
  text-align: center;
  background-position: 52vw;
  padding: 0;
  padding-right: 3rem;
}
.introduce_container .contents_wrap .contents_title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
}
.introduce_container .contents_wrap .contents_title h3 {
  font-size: 2.4rem;
}
.introduce_container .contents_wrap .contents_title .desc {
  width: 100%;
  margin-top: 1rem;
}
.introduce_container .contents_wrap .contents_title .search_wrap {
  width: 100%;
  position: relative;
}
.introduce_container .contents_wrap .contents_title .search_wrap input {
  width: 100%;
  height: 5rem;
  padding: 0 5rem 0 2rem;
  background: #f7f9fa;
  background-position: center right 24px;
  border-top: 0;
  border-left: 0;
  border-right: 0;
}
.introduce_container .contents_wrap .contents_title .search_wrap button {
  position: absolute;
  top: 12px;
  right: 24px;
}
.introduce_container .contents_wrap .contents_title .filter {
  display: flex;
  gap: 2rem;
}
.introduce_container .contents_wrap .contents_title .filter li {
  color: #999999;
  cursor: pointer;
}
.introduce_container .contents_wrap .contents_title .filter li.on {
  font-weight: 600;
  color: #000;
  text-decoration: underline;
  text-underline-position: under;
}
.introduce_container .contents_wrap .select_wrap {
  padding: 1.2rem 1.6rem;
}
.introduce_container .contents_wrap .center_search_wrap {
  width: 100%;
  position: relative;
}
.introduce_container .contents_wrap .center_search_wrap input {
  height: 5rem;
  padding: 0 5rem 0 2rem;
  background: #f7f9fa;
  background-position: center right 24px;
  border-left: 0;
  border-right: 0;
}
.introduce_container .contents_wrap .center_search_wrap button {
  position: absolute;
  top: 12px;
  right: 24px;
}
.introduce_container .contents_wrap .bottom_btn_wrap {
  margin-top: 4rem;
  text-align: center;
}
.introduce_container .contents_wrap.location_contents_wrap .contents_title {
  padding-left: 10rem;
  padding-right: 5rem;
  max-width: 130rem;
}
.introduce_container .contents_wrap.location_contents_wrap .location_info_wrap .map_wrap {
  width: 100%;
  height: 40rem;
  background: rgba(0, 0, 0, 0.3);
  overflow: hidden;
}
.introduce_container .contents_wrap.location_contents_wrap .location_info_wrap .contact {
  width: 100%;
  padding: 2.4rem 1.6rem;
}
.introduce_container .contents_wrap.location_contents_wrap .location_info_wrap .contact li {
  min-height: 4.2rem;
  padding: 0.2rem 0 0 5.4rem;
  margin-bottom: 1.6rem;
}
.introduce_container .contents_wrap.location_contents_wrap .location_info_wrap .contact li span {
  font-size: 1.2rem;
  display: block;
  margin-bottom: 4px;
  font-weight: 600;
}
.introduce_container .contents_wrap.location_contents_wrap .location_info_wrap .contact .address {
  background: url(../../assets/images/contact_pin.svg) no-repeat left top;
  background-size: 4.2rem 4.2rem;
}
.introduce_container .contents_wrap.location_contents_wrap .location_info_wrap .contact .tel {
  background: url(../../assets/images/contact_tel.svg) no-repeat left top;
  background-size: 4.2rem 4.2rem;
}
.introduce_container .contents_wrap.location_contents_wrap .location_info_wrap .contact .fax {
  background: url(../../assets/images/contact_fax.svg) no-repeat left top;
  background-size: 4.2rem 4.2rem;
}
.introduce_container .contents_wrap.location_contents_wrap .gallery_wrap {
  width: 100%;
  background: #f7f9fa;
  padding: 4rem 0;
}
.introduce_container .contents_wrap.location_contents_wrap .gallery_wrap .inner {
  position: relative;
  height: 22rem;
  overflow: hidden;
}
.introduce_container .contents_wrap.location_contents_wrap .gallery_wrap .inner .img_box {
  width: 25rem;
  height: 22rem;
  padding: 0 0.5rem;
}
.introduce_container .contents_wrap.location_contents_wrap .gallery_wrap .inner .img_box img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.introduce_container .contents_wrap.location_contents_wrap .gallery_wrap .inner :global .office_slider {
  position: absolute;
  width: 740px;
  left: 50%;
  transform: translateX(-50%);
}
@media (max-width: 767px) {
  .introduce_container .contents_wrap.location_contents_wrap .gallery_wrap .inner :global .office_slider {
    width: 768px;
    left: 1.6rem;
    transform: translateX(0);
  }
}
@media (max-width: 500px) {
  .introduce_container .contents_wrap.location_contents_wrap .gallery_wrap .inner :global .office_slider {
    width: 500px;
  }
}
@media (max-width: 300px) {
  .introduce_container .contents_wrap.location_contents_wrap .gallery_wrap .inner :global .office_slider {
    width: 400px;
  }
}
.introduce_container .contents_wrap.location_contents_wrap .location_info {
  padding: 4rem 1.6rem 0;
}
.introduce_container .contents_wrap.location_contents_wrap .location_info .box:first-child {
  margin-bottom: 3.2rem;
}
.introduce_container .contents_wrap.location_contents_wrap .location_info .box h4 {
  font-size: 1.6rem;
  font-weight: 600;
  margin-bottom: 1.2rem;
}
.introduce_container .contents_wrap.location_contents_wrap .location_info .box li {
  line-height: 2.2rem;
}
.introduce_container .contents_wrap.location_contents_wrap .location_info .box ol {
  padding-left: 1.6rem;
}
.introduce_container .contents_wrap.location_contents_wrap .location_info .box ol li {
  list-style-type: decimal;
}
.introduce_container .contents_wrap.location_contents_wrap .location_info .box ul li {
  padding-left: 8px;
  position: relative;
}
.introduce_container .contents_wrap.location_contents_wrap .location_info .box ul li::before {
  content: "";
  display: block;
  position: absolute;
  top: 1.1rem;
  left: 0;
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background: #000;
}
.introduce_container .introduce_board {
  display: flex;
  gap: 4rem 2rem;
  flex-wrap: wrap;
}
.introduce_container .introduce_board .board {
  flex: calc(50% - 1rem);
  cursor: pointer;
}
.introduce_container .introduce_board .board .img_box {
  margin-bottom: 2rem;
  width: 100%;
  height: 26.7rem;
}
.introduce_container .introduce_board .board .img_box img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.introduce_container .introduce_board .board h4 {
  font-size: 1.8rem;
  margin-bottom: 6px;
}
.introduce_container .introduce_board .board p {
  color: #999;
}
.introduce_container .staff_profile_wrap .profile_box {
  display: flex;
  gap: 2.4rem;
}
.introduce_container .staff_profile_wrap .profile_box .img_box {
  width: 33%;
  max-width: 20rem;
}
.introduce_container .staff_profile_wrap .profile_box .img_box img {
  width: 100%;
  height: 100%;
  aspect-ratio: 0.83/1;
  -o-object-fit: cover;
     object-fit: cover;
}
.introduce_container .staff_profile_wrap .profile_box .profile {
  flex: 1;
}
.introduce_container .staff_profile_wrap .profile_box .profile h4 {
  font-size: 1.6rem;
  margin-bottom: 0.8rem;
}
.introduce_container .staff_profile_wrap .profile_box .profile > ul li {
  line-height: 2.2rem;
  padding-left: 8px;
  position: relative;
}
.introduce_container .staff_profile_wrap .profile_box .profile > ul li::before {
  content: "";
  display: block;
  position: absolute;
  top: 11px;
  left: 0;
  width: 3px;
  height: 3px;
  background: #000;
  border-radius: 50%;
}
.introduce_container .staff_profile_wrap .award_box {
  padding: 1.6rem 0;
}
.introduce_container .staff_profile_wrap .award_box h4 {
  margin-bottom: 8px;
  font-weight: 400;
}
.introduce_container .staff_profile_wrap .award_box .inner {
  background: #f7f9fa;
  padding: 1.2rem;
}
.introduce_container .staff_profile_wrap .award_box .inner ul {
  max-height: 24rem;
  overflow-y: auto;
}
.introduce_container .staff_profile_wrap .award_box .inner li {
  display: flex;
  line-height: 2.2rem;
}
.introduce_container .staff_profile_wrap .award_box .inner li span {
  color: #999;
  display: block;
  margin-right: 2rem;
}
.introduce_container .center_card_board {
  padding: 1.2rem 1.6rem;
}
.introduce_container .center_card_board .box {
  width: 100%;
  background: #f7f9fa;
  padding: 2.5rem;
  margin-bottom: 0.8rem;
}
.introduce_container .center_card_board .box h4 {
  font-size: 1.4rem;
  font-weight: 600;
  margin-bottom: 0.8rem;
}
.introduce_container .center_card_board .box p {
  font-size: 1.4rem;
}
.introduce_container .center_card_board .box p.tel {
  font-size: 1.2rem;
  margin-top: 4px;
  color: #999;
}
.introduce_container .empty_data {
  padding: 13rem 0 0.5rem 0;
  text-align: center;
}
.introduce_container .empty_data p {
  padding: 11rem 0 1.6rem;
  font-size: 1.6rem;
  font-weight: bold;
  background: url(../../assets/images/ed_icon_07.svg) no-repeat center top;
}
.introduce_container .tab_menu {
  width: 100%;
  display: flex;
}
.introduce_container .tab_menu li {
  width: 50%;
  height: 5rem;
  line-height: 5rem;
  background: #f7f9fa;
  color: #999;
  font-weight: 500;
  text-align: center;
  cursor: pointer;
}
.introduce_container .tab_menu li.on {
  background: #e60013;
  color: #fff;
  font-weight: bold;
}
.introduce_container .tab_menu li.in {
  color: #e60013;
}
.introduce_container .tab_contents_wrap {
  padding-top: 4rem;
}
.introduce_container .tab_contents_wrap .faq_wrap {
  margin-bottom: 4rem;
}
.introduce_container .tab_contents_wrap .result_wrap h4 {
  font-size: 1.8rem;
  margin-bottom: 1rem;
}
.introduce_container .tab_contents_wrap .result_wrap button {
  display: block;
  margin: 4rem auto;
}