@charset "UTF-8";
/* s: reset css */
@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.6/dist/web/static/pretendard.css");
@import "react-perfect-scrollbar/dist/css/styles.css";
html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

body {
  margin: 0;
}

* {
  box-sizing: border-box;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary,
address {
  display: block;
}

address {
  font-style: normal;
}

a {
  color: inherit;
  background-color: transparent;
  text-decoration: none;
}

a:active,
a:hover {
  outline: 0;
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0;
}

img {
  border: 0;
  max-width: 100%;
  vertical-align: top;
}

hr {
  height: 0;
  box-sizing: content-box;
}

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font: inherit;
  color: inherit;
}

button {
  overflow: visible;
  background: none;
  border: none;
  padding: 0;
}

button,
select {
  text-transform: none;
}

button,
html input[type=button],
input[type=reset],
input[type=submit] {
  -webkit-appearance: button;
  cursor: pointer;
}

button[disabled],
html input[disabled] {
  cursor: default;
}

button:focus {
  outline: #007bce dotted thin;
}

textarea {
  overflow: auto;
}

.input_file input[type=file] {
  display: none;
}

.input_file label {
  width: 9.5rem;
  height: 4rem;
  line-height: 3.8rem;
  text-align: center;
  border: 1px solid #000;
  border-radius: 2rem;
  display: block;
  font-size: 1.6rem;
  font-weight: 600;
}

optgroup {
  font-weight: bold;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  padding: 0;
}

ul,
ol {
  padding: 0;
  margin: 0;
  list-style: none;
}

dl,
dt,
dd {
  margin: 0;
}

p {
  padding: 0;
  margin: 0;
}

i,
em {
  font-style: normal;
}

/* e: reset css */
/* s: global css */
html {
  font-family: "Pretendard Variable", Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
  font-size: 10px;
  font-weight: 400;
  color: #000;
  word-break: keep-all;
}

body {
  font-size: 1.6rem;
  font-weight: 400;
  height: 100vh;
}

.container {
  width: 100%;
  min-width: 798px;
  padding-top: 8rem;
}

.admin_container {
  width: 100%;
  min-width: 798px;
  max-width: 1920px;
  margin: 0 auto;
  padding-top: 7rem;
  background: #f0f2f4;
}

textarea {
  resize: none;
  width: 100%;
  border: 1px solid #dedede;
  outline: none;
  padding: 1rem 1.5rem;
}

table {
  border-top: 3px solid #000;
  table-layout: fixed;
}

.border_table table {
  border-top: 3px solid #000 !important;
}

table th,
table td {
  text-align: left;
  padding: 1.05rem 2rem;
  height: 5.9rem;
  border-bottom: 1px solid #dedede;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 2.6rem;
}

table tbody th {
  vertical-align: baseline;
}

table thead th {
  border-bottom: 1px solid #000;
}

table tbody tr {
  cursor: pointer;
}

.cursor_default tbody tr {
  cursor: default;
}

table tr.on {
  color: #3e21d9;
  font-weight: 600;
  background: #f7f9fa;
}

table.align_center th,
table.align_center td {
  text-align: center;
}

table.align_left_col1 tr td:nth-child(1) {
  text-align: left;
  padding: 0 4rem;
}

table.align_left_col2 tr td:nth-child(2) {
  text-align: left;
  padding: 0 4rem;
}

table.align_left_col3 tr td:nth-child(3) {
  text-align: left;
  padding: 0 4rem;
}

table.align_left_col4 tr td:nth-child(4) {
  text-align: left;
  padding: 0 4rem;
}

table.align_left_col5 tr td:nth-child(5) {
  text-align: left;
  padding: 0 4rem;
}

table b.type_a {
  color: #cbb300 !important;
}

table b.type_b {
  color: #528ee0 !important;
}

table b.type_c {
  color: #d37a39 !important;
}

table b.type_d {
  color: #45b752 !important;
}

.table_wrap {
  border-top: 3px solid #000;
  border-bottom: 1px solid #dedede;
  min-height: 18.5rem;
}

.table_wrap table {
  border-top: none;
}

.table_wrap table tr {
  cursor: default;
}

.table_wrap table tr:last-child th,
.table_wrap table tr:last-child td {
  border-bottom: none;
}

.table_wrap table thead th {
  border-bottom: 1px solid #000 !important;
}

.table_wrap table th,
.table_wrap table td {
  padding: 1.05rem 0.5rem;
}

.fixed_scroll_table {
  overflow-y: auto;
  overflow-x: hidden;
  border-top: 3px solid #000;
  margin-bottom: 0;
}

.fixed_scroll_table table {
  width: 100%;
  margin-right: 1rem;
  border-top: none;
}

.fixed_scroll_table thead {
  position: sticky;
  top: 0;
  z-index: 1;
}

.fixed_scroll_table tbody {
  overflow-y: auto;
  max-height: 100%;
}

.no_search_result p {
  padding: 14.5rem 0 4.3rem;
  text-align: center;
  font-weight: 600;
  background: url(./assets/images/icon_empty_data.svg) no-repeat center top 4rem;
}

.scrollbar-container {
  max-height: 100vh;
}

.ps__rail-y {
  right: 0;
}

.flex_row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.scroll_bx {
  overflow-y: auto;
}

.primary_c {
  color: #3e21d9;
}

.gray_c {
  color: #999;
}

.underline {
  text-decoration: underline;
  text-underline-position: under;
}

.red_c,
.require_red {
  color: #e60013;
}

.f_14 {
  font-size: 1.4rem;
}

::-webkit-slider-runnable-track {
  background: #ddd;
}

/*
 * 1. Set to 0 width and remove border for a slider without a thumb
 * 2. Shadow is negative the full width of the input and has a spread 
 *    of the width of the input.
 */
::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 40px; /* 1 */
  height: 10px;
  background: #999;
  box-shadow: -200px 0 0 200px #999; /* 2 */
  border: 2px solid #999; /* 1 */
}

::-moz-range-track {
  height: 10px;
  background: #ddd;
}

::-moz-range-thumb {
  background: #999;
  height: 10px;
  width: 40px;
  border: 3px solid #999;
  border-radius: 0 !important;
  box-shadow: -200px 0 0 200px transparent;
  box-sizing: border-box;
}

::-ms-fill-lower {
  background: dodgerblue;
}

::-ms-thumb {
  background: #999;
  border: 2px solid #999;
  height: 10px;
  width: 40px;
  box-sizing: border-box;
}

::-ms-ticks-after {
  display: none;
}

::-ms-ticks-before {
  display: none;
}

::-ms-track {
  background: #ddd;
  color: transparent;
  height: 10px;
  border: none;
}

::-ms-tooltip {
  display: none;
}

/* 스크롤바 설정*/
::-webkit-scrollbar {
  width: 6px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  height: 17%;
  background-color: rgba(186, 186, 186, 0.5);
  border-radius: 10px;
}

/* 스크롤바 뒷 배경 설정*/
::-webkit-scrollbar-track {
  background-color: rgba(245, 245, 245, 0.5);
}

/* e: global css */
@media (max-width: 959px) {
  body {
    font-size: 1.4rem;
  }
}