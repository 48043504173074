.reception_status_wrap h3 {
  font-size: 2rem;
  text-align: center;
}
.reception_status_wrap .search_box {
  display: flex;
  justify-content: center;
  padding: 3rem 0;
}
.reception_status_wrap .modal_nav {
  width: 100%;
  display: flex;
  margin-top: 4rem;
  padding-right: 2rem;
}
.reception_status_wrap .modal_nav li {
  width: 50%;
  height: 5rem;
  line-height: 5rem;
  background: #f7f9fa;
  color: #999;
  font-weight: 500;
  text-align: center;
}
.reception_status_wrap .modal_nav li.on {
  background: #3e21d9;
  color: #fff;
  font-weight: bold;
}
.reception_status_wrap .tab_menu {
  margin-bottom: 2.3rem;
}
.reception_status_wrap .tab_menu ul {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
}
.reception_status_wrap .tab_menu ul li {
  width: 7.2rem;
  height: 4rem;
  line-height: 3.8rem;
  border-radius: 2.2rem;
  border: 1px solid #dedede;
  text-align: center;
  font-weight: 500;
  color: #999;
  cursor: pointer;
}
.reception_status_wrap .tab_menu ul li.on {
  background: #3e21d9;
  border-color: #3e21d9;
  color: #fff;
  font-weight: bold;
}
.reception_status_wrap .search_result h4 {
  color: #3e21d9;
  margin-bottom: 1.3rem;
}
.reception_status_wrap .search_result table {
  margin-bottom: 2rem;
}