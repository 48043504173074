.breadcrumb {
  width: 100%;
  height: 5.5rem;
  border-bottom: 1px solid #dedede;
}
.breadcrumb .inner {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 2rem;
  gap: 1.8rem;
}
.breadcrumb .inner .home {
  height: 100%;
  line-height: 5.4rem;
  cursor: pointer;
  text-indent: -9999px;
  width: 2rem;
  background: url(../../../assets/images/bread_crumb_home.svg) no-repeat center;
}
.breadcrumb .inner ul {
  display: flex;
  align-items: center;
  height: 100%;
  gap: 1.8rem;
}
.breadcrumb .inner ul li {
  height: 100%;
  line-height: 5.4rem;
  cursor: pointer;
  position: relative;
  font-size: 1.4rem;
}
.breadcrumb .inner ul li::before {
  content: "";
  position: absolute;
  width: 2px;
  height: 2px;
  border-radius: 50%;
  background: #000;
  top: 50%;
  left: -11px;
  transform: translateY(-50%);
}
.breadcrumb .inner ul li:last-child {
  font-weight: bold;
}