.official_main_wrap {
    width: 100%;
    position: relative;
    .band_banner {
        max-width: 1920px;
        width: 100%;
        top: 8rem;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        position: fixed;
        height: 8rem;
        background: #3e21d9;
        color: #fff;
        align-items: center;
        padding: 0 2.5rem 0 22rem;
        z-index: 9;
        h4 {
            padding-left: 4.5rem;
            background: url(../assets/images/icon_tournament_cup.svg) no-repeat
                left center;
            font-size: 2.2rem;
            font-weight: 600;
            padding-right: 1.5rem;
            cursor: pointer;
        }
        p {
            padding: 0 1rem;
            height: 2.5rem;
            border-radius: 1.3rem;
            border: 1px solid #fff;
            line-height: 2.3rem;
            font-size: 1.4rem;
            font-weight: 500;
            cursor: pointer;
        }
        .today_close_wrap {
            margin-left: auto;
            display: flex;
            gap: 3rem;
        }
        @media (max-width: 1200px) {
            padding: 0 2.5rem 0 2.5rem;
        }
    }
    article {
        padding: 10rem 0;
        max-width: 1920px;
        margin: 0 auto;
        h2 {
            font-size: 3rem;
            font-weight: 800;
            margin-bottom: 2rem;
            display: flex;
            justify-content: space-between;
        }
        &.dark {
            background: #000;
            color: #fff;
            .contents_wrap {
                gap: 4rem;
            }
        }
        &.blue {
            background: #f7f9fa;
        }
    }

    .contents_wrap {
        max-width: 1200px;
        margin: 0 auto;
        @media (max-width: 1200px) {
            padding: 0 1rem;
        }
    }
    .official_main {
        width: 100%;
        height: 65rem;
        position: relative;

        .visual_wrap {
            padding: 0;
            background: rgba(0, 0, 0, 0.5);
            width: 100%;
            height: 65rem;
            .main_img {
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
        .login_wrap {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: 0;
            width: 100%;
            background: rgb(23, 18, 47, 0.5);
            height: 17rem;
            -webkit-backdrop-filter: blur(8.9px);
            backdrop-filter: blur(8.9px);

            // display: flex;
            // align-items: center;
            // padding: 0 0 0 36rem;
            padding: 0;
            & > div {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
            .login {
                // min-width: 70rem;
                width: 86rem;
                height: 17rem;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                margin-left: auto;
                input {
                    width: 29.5rem;
                }
                .welcome_wrap {
                    color: #fff;
                    h3 {
                        font-size: 3rem;
                    }
                    p {
                        font-size: 2rem;
                        font-weight: 500;
                    }
                    .j_id {
                        font-size: 3rem;
                        font-weight: bold;
                        margin-top: 1.3rem;
                        display: flex;
                        align-items: center;
                        position: relative;
                        cursor: pointer;
                        .tooltip {
                            margin-left: 2rem;
                            width: 2.4rem;
                            height: 2.4rem;
                            text-indent: -9999px;
                            background: url(../assets/images/icon_tooltip_w.svg)
                                no-repeat center;
                        }
                        .tooltip_content {
                            position: absolute;
                            top: -122px;
                            left: 246px;
                            display: block;
                            width: 24.8rem;
                            height: 11rem;
                            background: #fff;
                            padding: 2rem;
                            color: #000;
                            cursor: default;
                            display: none;
                            transition: all 0.5s;
                            b {
                                font-size: 1.6rem;
                                font-weight: 600;
                                margin-bottom: 1rem;
                                display: block;
                            }
                            span {
                                display: block;
                                font-size: 1.4rem;
                                line-height: 2rem;
                                font-weight: 400;
                            }
                            &::before {
                                content: "";
                                display: block;
                                position: absolute;
                                width: 1.6rem;
                                height: 1.6rem;
                                background: #e60013;
                                top: -6px;
                                right: -6px;
                            }
                            &::after {
                                content: "";
                                display: block;
                                position: absolute;
                                width: 1.2rem;
                                height: 1.2rem;
                                background: #fff;
                                transform: rotate(45deg);
                                top: 102px;
                                left: 16px;
                            }
                        }
                        &:hover {
                            .tooltip_content {
                                display: block;
                            }
                        }
                    }

                    .btn_wrap {
                        margin-top: 2rem;
                        gap: 2rem;
                        display: flex;
                    }
                }
                @media (max-width: 1500px) {
                    justify-content: space-around;
                }
                @media (max-width: 1200px) {
                    justify-content: flex-start;
                    padding-left: 2rem;
                    input {
                        width: 18rem;
                    }
                }
            }
            .my_page_wrap {
                height: 17rem;
                display: flex;
                width: 36%;
                // flex: 1;
                background: #000000;
                p {
                    width: 17rem;
                    text-align: center;
                    color: #fff;
                    font-size: 1.6rem;
                    font-weight: bold;
                    padding-top: 10.8rem;
                    cursor: pointer;
                    &.cs {
                        background: #1c1c1c url(../assets/images/icon_cs.svg)
                            no-repeat center top 4.8rem;
                    }
                    &.as {
                        background: url(../assets/images/icon_as.svg) no-repeat
                            center top 4.8rem;
                    }
                }
            }
        }
    }
    .tournament_list {
        li {
            height: 8rem;
            line-height: 8rem;
            background: #f7f9fa;
            margin-top: 1rem;
            border-left: 8px solid #e8e86b;
            padding: 0 3.5rem;
            font-weight: bold;
            font-size: 2rem;
            display: flex;
            align-items: center;
            cursor: pointer;
            &:first-child {
                margin-top: 0;
            }
            &.type_a {
                border-color: #e8e86b;
            }
            &.type_b {
                border-color: #a1d969;
            }
            &.type_c {
                border-color: #e8a06b;
            }
            &.type_d {
                border-color: #7fb1f5;
            }
            .title {
                font-size: 1.6rem;
                width: 258px;
                display: inline-block;
            }
            .name {
                max-width: 50%;
            }
            .location {
                font-size: 1.6rem;
                font-weight: 400;
                margin-left: auto;
                i {
                    display: inline-block;
                    margin-left: 1rem;
                    font-size: 1.4rem;
                    font-weight: 600;
                    min-width: 4rem;
                    height: 2.2rem;
                    line-height: 2rem;
                    border: 1px solid #000;
                    border-radius: 3px;
                    padding: 0 0.5rem;
                    text-align: center;
                }
            }
            .title,
            .name {
                display: block;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
        @media (max-width: 1000px) {
            gap: 1rem;
            li {
                padding: 0 2rem;
                .title {
                    width: 150px;
                }
            }
        }
    }
    .contents_slider {
        .content {
            padding: 0 10px;
            img {
                width: 100%;
                height: 20rem;
                background: lightgray;
            }
            span {
                margin-top: 2rem;
                display: block;
                font-size: 1.6rem;
                font-weight: bold;
                color: #3e21d9;
            }
            p {
                margin-top: 1rem;
                font-size: 2rem;
                font-weight: 600;
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
        :global {
            .slick-prev,
            .slick-next {
                width: 5rem;
                height: 5rem;
                top: 10rem;

                &::before {
                    display: none;
                }
            }
            .slick-prev {
                background: url(../assets/images/btn_default_l.svg) no-repeat;
                left: -7rem !important;
                &:hover {
                    background: url(../assets/images/btn_default_l_b.svg)
                        no-repeat;
                }
            }
            .slick-next {
                background: url(../assets/images/btn_default_r.svg) no-repeat;
                right: -7rem !important;
                &:hover {
                    background: url(../assets/images/btn_default_r_b.svg)
                        no-repeat;
                }
            }
            @media (max-width: 1360px) {
                .slick-prev {
                    left: 3rem !important;
                    z-index: 1;
                    opacity: 0.5;
                }
                .slick-next {
                    right: 3rem !important;
                    z-index: 1;
                    opacity: 0.5;
                }
            }
        }
    }
    .official_main_slider {
        width: 100%;
        height: 65rem;
        .main_img {
            width: 100%;
            height: 65rem;
            object-fit: cover;
        }
        :global {
            .slick-dots {
                max-width: 1200px;
                left: 50%;
                transform: translateX(-50%);
                text-align: left;
                bottom: 20.4rem;
                li {
                    width: 10px;
                    button {
                        &::before {
                            content: "";
                            border: 1px solid #fff;
                            width: 8px;
                            height: 8px;
                            border-radius: 50%;
                            opacity: 1;
                        }
                    }
                    &.slick-active {
                        width: 28px;
                        button {
                            &::before {
                                content: "";
                                border: 1px solid #fff;
                                background: #fff;
                                width: 24px;
                                height: 8px;
                                border-radius: 5px;
                            }
                        }
                    }
                }
                @media (max-width: 1200px) {
                    width: 100%;
                    padding: 0 2rem;
                }
            }
        }
    }
}
.recommend_product_wrap {
    position: relative;
    overflow: hidden;
    padding-bottom: 0 !important;
    .slider_wrap {
        width: 100%;

        position: absolute;
        .contents_wrap {
            width: 100%;
            height: 26rem;
            position: relative;
        }
        .product_slider {
            position: absolute;
            left: 42rem;
            top: 0;
            // width: 1230px;
            width: 1200px;
            height: 38rem;
            .item {
                cursor: pointer;
                width: 38rem !important;
                height: 38rem;
                padding-right: 3rem;
                position: relative;
                background: #fff;
                border: 1px solid #eee;
                p {
                    position: absolute;
                    font-size: 2.4rem;
                    font-weight: bold;
                    text-transform: uppercase;
                    width: 100%;
                    padding: 0 3.5rem;
                    top: 2.5rem;
                    left: 0;
                    span {
                        display: block;
                        font-size: 1.2rem;
                        font-weight: bold;
                        color: #e60013;
                        margin-bottom: 4px;
                    }
                }
                img {
                    width: 21rem;
                    height: 21rem;
                    position: absolute;
                    bottom: 5rem;
                    left: 50%;
                    transform: translateX(-50%);
                }
            }
            :global {
                .slick-arrow.slick-prev,
                .slick-arrow.slick-next {
                    background: #000;
                    top: 117px;
                    left: -419px;
                    width: 5rem;
                    height: 5rem;
                    background: url(../assets/images/btn_default_l.svg)
                        no-repeat;
                    &::before {
                        display: none;
                    }
                }
                .slick-arrow.slick-prev:hover {
                    background: url(../assets/images/btn_default_l_b.svg)
                        no-repeat;
                }
                .slick-arrow.slick-next {
                    left: -359px;
                    background: url(../assets/images/btn_default_r.svg)
                        no-repeat;
                    &:hover {
                        background: url(../assets/images/btn_default_r_b.svg)
                            no-repeat;
                    }
                }
            }
        }
    }
    .category_wrap {
        background: #e60013;
        width: 100%;
        height: 26rem;
        margin-top: 26rem;
        ul {
            padding-top: 3.5rem;
            display: inline-flex;
            flex-direction: column;
            align-items: flex-start;
            li {
                color: #fff;
                font-size: 2rem;
                font-weight: bold;
                height: 3rem;
                line-height: 3rem;
                margin-bottom: 1rem;
                cursor: pointer;
                padding: 0 7px;
                display: inline-block;
                width: auto;
                text-transform: uppercase;
                &.on {
                    background: #000;
                }
            }
        }
    }
}
.kegel_wrap {
    display: flex;
    gap: 3rem;
    div {
        width: 38rem;
        cursor: pointer;
        img {
            width: 38rem;
            height: 35rem;
        }
        h3 {
            margin: 2rem 0 1.4rem;
            font-size: 2rem;
        }
        p {
            line-height: 2.8rem;
        }
    }
}
.catalog_wrap {
    display: flex;
    gap: 2rem;
    & > div {
        flex: 1;

        height: 36rem;
        padding: 4rem 5rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        h2 {
            color: #fff;
        }
        &.catalog_box {
            background: url(../assets/images/bg_catalog.png) no-repeat center;
            background-size: cover;
            li {
                line-height: 2.4rem;
                margin-top: 2rem;
            }
        }
        &.faq_box {
            background: #e60013 url(../assets/images/bowling_pin.svg) no-repeat
                left 327px top 102px;
        }
    }
}
.notice {
    padding-top: 3rem !important;
    .notice_wrap {
        .list {
            border-top: 3px solid #000;
            li {
                cursor: pointer;
                height: 8rem;
                line-height: 8rem;
                border-bottom: 1px solid #dedede;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 2rem;
                gap: 1rem;
                transition: all 0.3s;
                position: relative;
                &::after {
                    content: "";
                    display: none;
                    width: 3.4rem;
                    height: 3.4rem;
                    background: none;
                    position: absolute;
                    top: 2.3rem;
                    right: 2.5rem;
                    transition: all 0.3s;
                }
                b {
                    flex: 1;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    font-size: 2rem;
                    font-weight: 600;
                }
                .date {
                    font-size: 1.4rem;
                    color: #999;
                }
                &:hover {
                    color: #fff;
                    padding-right: 9rem;
                    background: #e60013;
                    &::after {
                        display: block;
                        background: url(../assets/images/btn_default_r_b.svg)
                            no-repeat center;
                        background-size: 3.4rem 3.4rem;
                    }
                    .date {
                        color: #fff;
                    }
                }
            }
        }
    }
}
.sns_article {
    background: #f7f9fa;
    margin: 5rem auto 0 !important;
    padding: 3rem 0 !important;
    .sns_wrap {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 4rem;
        h3 {
            font-size: 2rem;
            font-weight: 800;
        }
        ul {
            display: flex;
            align-items: center;
            gap: 2rem;
            li {
                cursor: pointer;
                text-indent: -9999px;
                width: 5rem;
                height: 5rem;
                a {
                    display: block;
                    width: 100%;
                    height: 100%;
                }
                &.kakao {
                    background: url(../assets/images/icon_kakao.png) no-repeat
                        center;
                    background-size: 100%;
                }
                &.facebook {
                    background: url(../assets/images/icon_facebook.png)
                        no-repeat center;
                    background-size: 100%;
                }
                &.official_insta a {
                    background: url(../assets/images/official_insta.png)
                        no-repeat center;
                    background-size: 100%;
                }
                &.mall_insta a {
                    background: url(../assets/images/mall_insta.png) no-repeat
                        center;
                    background-size: 100%;
                }
                &.youtube a {
                    background: url(../assets/images/icon_youtube.png) no-repeat
                        center;
                    background-size: 100%;
                }
            }
        }
    }
}
.page_error_container {
    .page_error {
        width: 40.3rem;
        margin: 30rem auto;
        text-align: center;
        h2 {
            padding-top: 11rem;
            background: url(../assets/images/ed_icon_01.svg) no-repeat center
                top;
            &.error_2 {
                background: url(../assets/images/ed_icon_02.svg) no-repeat
                    center top;
            }
            &.error_3 {
                background: url(../assets/images/ed_icon_03.svg) no-repeat
                    center top;
            }
        }
        .info {
            margin: 3rem 0 5rem;
            b {
                display: block;
                margin-bottom: 1.5rem;
            }
            p {
                line-height: 2.2rem;
            }
        }
        .box {
            width: 100%;
            background: #f7f9fa;
            padding: 2rem 0;
            text-align: center;
            b {
                display: inline-block;
                margin-right: 2rem;
            }
        }
        .btn_wrap {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 1rem;
        }
    }
    @media (max-width: 959px) {
        min-width: initial;
        .page_error {
            max-width: 40.3rem;
            width: 100%;
            padding: 0 1.6rem;
            .box {
                b {
                    display: block;
                    margin-bottom: 8px;
                }
            }
        }
    }
}
