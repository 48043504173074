.header {
  background: transparent;
  width: 100%;
  height: 4.4rem;
  position: fixed;
  z-index: 100;
}
.header .header_wrap {
  display: flex;
  gap: 2rem;
  justify-content: space-between;
  padding: 0 1.6rem;
}
.header .header_wrap a {
  display: block;
  width: 100%;
  height: 100%;
}
.header .header_wrap .header_logo {
  text-indent: -9999px;
  font-size: 0;
  width: 4.4rem;
  height: 4.4rem;
  background: url(../assets/images/app_gnb_logo.png) no-repeat;
  background-position: center;
  background-size: 3.1rem;
}
.header .header_wrap .go_home {
  text-indent: -999999px;
  width: 2.4rem;
  height: 4.4rem;
  background: url(../assets/images/header_home_w.svg) no-repeat center;
  background-size: 2.4rem auto;
  margin-left: auto;
}
.header .header_wrap .go_tournament_home {
  width: 4rem;
  height: 4.4rem;
  margin-left: auto;
  padding-top: 1.1rem;
}
.header .header_wrap .go_tournament_home a {
  display: block;
  width: 4rem;
  height: 2.3rem;
  line-height: 2.3rem;
  border: 1px solid #fff;
  color: #fff;
  font-size: 1.4rem;
  font-weight: 500;
  text-align: center;
  border-radius: 1.2rem;
}
.header .header_wrap .header_tournament_logo {
  font-size: 1.4rem;
  position: relative;
  padding-top: 0.7rem;
  line-height: 3rem;
}
.header .header_wrap .header_tournament_logo a {
  color: #fff;
  padding-left: 1.8rem;
}
.header .header_wrap .header_tournament_logo span {
  color: #3e21d9;
}
.header .header_wrap .header_tournament_logo::before {
  content: "";
  display: block;
  position: absolute;
  top: 7px;
  left: 0;
  width: 3rem;
  height: 3rem;
  background: #fff;
  border-radius: 1.5rem;
  z-index: -1;
}
.header.tournament_header_wrap {
  background: #3e21d9;
}
.header .mobile_menu_warp ul {
  display: flex;
  gap: 2rem;
  height: 4.4rem;
  align-items: center;
  overflow: hidden;
}
.header .mobile_menu_warp ul li {
  cursor: pointer;
  background: url(../assets/images/app_gnb_menu_w.svg) no-repeat center;
  background-size: 2.4rem auto;
  text-indent: -9999px;
  width: 2.4rem;
  height: 2.4rem;
}
.header .mobile_menu_warp ul li.mypage {
  background: none;
}
.header .mobile_menu_warp ul li.mypage a {
  display: block;
  height: 100%;
  background: url(../assets/images/app_gnb_my_w.svg) no-repeat center left;
}
.header.on {
  background: #fff;
}
.header.on .mobile_menu_warp ul li:first-child {
  background: url(../assets/images/app_gnb_menu.svg) no-repeat center;
  background-size: 2.4rem auto;
}
.header.on .mobile_menu_warp ul li.mypage a {
  background: url(../assets/images/app_gnb_my.svg) no-repeat center left;
}
.header.on .header_wrap .header_tournament_logo {
  width: 9rem;
  height: 3rem;
}
.header.on .header_wrap .header_tournament_logo a {
  color: #000;
  padding-left: 1.8rem;
}
.header.on .header_wrap .header_tournament_logo span {
  color: #fff;
}
.header.on .header_wrap .header_tournament_logo::before {
  content: "";
  display: block;
  position: absolute;
  top: 7px;
  left: 0;
  width: 3rem;
  height: 3rem;
  background: #3e21d9;
  border-radius: 1.5rem;
  z-index: -1;
}
.header.on .header_wrap .go_home {
  background: url(../assets/images/header_home.svg) no-repeat center;
  background-size: 2.4rem auto;
}
.header.on .header_wrap .go_tournament_home a {
  border-color: #000;
  color: #000;
}

.slide_lnb_wrap {
  position: fixed;
  top: 0;
  right: 0vw;
  transform: translateX(100vw);
  width: 100vw;
  height: 100vh;
  z-index: 100;
  transition: all 0.5s;
}
.slide_lnb_wrap .slide_lnb_box {
  width: 83vw;
  height: 100%;
  background: #fff;
  position: absolute;
  top: 0;
  right: 0vw;
  transform: translateX(100vw);
  transition: all 0.5s;
}
.slide_lnb_wrap.on {
  transform: translateX(0vw);
}
.slide_lnb_wrap.on .slide_lnb_box {
  transform: translateX(0vw);
}
.slide_lnb_wrap + .dim {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.3);
  z-index: 99;
}
.slide_lnb_wrap .slide_lnb_header {
  display: flex;
  padding: 0 1.6rem 0 2.4rem;
  justify-content: space-between;
  align-items: center;
  height: 4rem;
  margin-top: 4px;
}
.slide_lnb_wrap .slide_lnb_header .logo {
  width: 8.1rem;
  height: 100%;
}
.slide_lnb_wrap .slide_lnb_header .logo a {
  display: block;
  width: 8.1rem;
  height: 100%;
  text-indent: -9999px;
  background: url(../assets/images/app_lnb_logo.png) no-repeat center;
  background-size: 8.1rem 3.6rem;
}
.slide_lnb_wrap .slide_lnb_header .header_tournament_logo {
  font-size: 1.4rem;
  position: relative;
  line-height: 3rem;
  font-weight: bold;
}
.slide_lnb_wrap .slide_lnb_header .header_tournament_logo a {
  color: #000;
  padding-left: 1.8rem;
}
.slide_lnb_wrap .slide_lnb_header .header_tournament_logo span {
  color: #fff;
}
.slide_lnb_wrap .slide_lnb_header .header_tournament_logo::before {
  content: "";
  display: block;
  position: absolute;
  top: 0px;
  left: 0;
  width: 3rem;
  height: 3rem;
  background: #3e21d9;
  border-radius: 1.5rem;
  z-index: -1;
}
.slide_lnb_wrap .mypage_wrap {
  padding: 2.8rem 0 2rem;
  border-bottom: 1px solid #dedede;
}
.slide_lnb_wrap .mypage_wrap ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 2rem 2.4rem;
  padding: 0 1.6rem 0 2.4rem;
}
.slide_lnb_wrap .mypage_wrap ul li {
  line-height: 1.7rem;
  height: 1.7rem;
}
.slide_lnb_wrap .mypage_wrap ul li a {
  display: block;
  width: 100%;
  height: 100%;
  padding-left: 2rem;
  color: #999;
  font-weight: 500;
}
.slide_lnb_wrap .mypage_wrap ul li.login a {
  background: url(../assets/images/app_lnb_login.svg) no-repeat left center;
}
.slide_lnb_wrap .mypage_wrap ul li.sign a {
  background: url(../assets/images/app_lnb_sign.svg) no-repeat left center;
}
.slide_lnb_wrap .mypage_wrap ul li.my a {
  background: url(../assets/images/app_lnb_my.svg) no-repeat left center;
}
.slide_lnb_wrap .mypage_wrap ul li.logout a {
  background: url(../assets/images/app_lnb_logout.svg) no-repeat left center;
}
.slide_lnb_wrap .mypage_wrap ul li.cs a {
  background: url(../assets/images/app_lnb_cs.svg) no-repeat left center;
}
.slide_lnb_wrap .mypage_wrap ul li.my_history a {
  background: url(../assets/images/app_lnb_my_history.svg) no-repeat left center;
}
.slide_lnb_wrap .lnb_nav {
  padding-top: 8px;
}
.slide_lnb_wrap .lnb_nav ul li {
  padding: 0 2.4rem;
}
.slide_lnb_wrap .lnb_nav ul li a {
  display: block;
  width: calc(100% - 4rem);
  min-height: 3.8rem;
  line-height: 3.8rem;
  font-size: 1.6rem;
  font-weight: 500;
}
.slide_lnb_wrap .lnb_nav ul li.tournament_link {
  position: relative;
}
.slide_lnb_wrap .lnb_nav ul li.tournament_link span {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 2.4rem;
  display: block;
  width: 4rem;
  min-height: 4.3rem;
  line-height: 4.3rem;
  font-size: 1.6rem;
  font-weight: 500;
  background: url(../assets/images/app_lnb_minus.svg) no-repeat center;
  background-size: 1.8rem 1.8rem;
}
.slide_lnb_wrap .lnb_nav ul li.tournament_link ul {
  display: none;
  justify-content: space-between;
}
.slide_lnb_wrap .lnb_nav ul li.tournament_link ul li {
  height: 4.1rem;
  padding: 0;
}
.slide_lnb_wrap .lnb_nav ul li.tournament_link ul li a {
  display: block;
  font-size: 1.4rem;
  font-weight: 400;
}
.slide_lnb_wrap .lnb_nav ul li.tournament_link.on {
  background: #f7f9fa;
}
.slide_lnb_wrap .lnb_nav ul li.tournament_link.on span {
  background: url(../assets/images/app_lnb_plus.svg) no-repeat center;
  background-size: 1.8rem 1.8rem;
}
.slide_lnb_wrap .lnb_nav ul li.tournament_link.on ul {
  display: flex;
}
.slide_lnb_wrap .sns_nav {
  position: absolute;
  bottom: 1rem;
  width: 100%;
}
.slide_lnb_wrap .sns_nav .to_home {
  width: 100%;
  height: 4.3rem;
  line-height: 4.3rem;
  padding: 0 3rem;
  margin-bottom: 2rem;
  background: #f7f9fa;
}
.slide_lnb_wrap .sns_nav .to_home a {
  display: block;
  width: 100%;
  height: 100%;
  font-size: 1.6rem;
  font-weight: 500;
  background: url(../assets/images/icon_btn_text.svg) no-repeat center left 12rem;
}
.slide_lnb_wrap .sns_nav ul {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
}
.slide_lnb_wrap .sns_nav ul li a {
  display: block;
  width: 100%;
  height: 100%;
  text-align: center;
  padding-top: 4.6rem;
}
.slide_lnb_wrap .sns_nav ul li.mall a {
  background: url(../assets/images/lnb_mall.png) no-repeat center top;
  background-size: 3.6rem 3.6rem;
}
.slide_lnb_wrap .sns_nav ul li.insta a {
  background: url(../assets/images/lnb_insta.png) no-repeat center top;
  background-size: 3.6rem 3.6rem;
}
.slide_lnb_wrap .sns_nav ul li.youtube a {
  background: url(../assets/images/lnb_youtube.png) no-repeat center top;
  background-size: 3.6rem 3.6rem;
}