.terms_wrap {
  width: 100%;
  max-height: 60rem;
  overflow-y: auto;
  background: #f7f9fa;
  text-align: left;
  padding: 1rem;
  margin-bottom: 3.4rem;
}
.terms_wrap > div {
  min-width: initial;
}
.terms_wrap > div h3 {
  text-align: left !important;
}
.terms_wrap > div > div {
  padding: 0 !important;
}
@media (max-width: 959px) {
  .terms_wrap {
    max-height: 40rem;
    height: 40rem;
    margin-bottom: 1.6rem;
  }
  .terms_wrap > div article > div {
    padding: 0 !important;
  }
}