.footer {
  background: #000;
  color: #fff;
  width: 100%;
  min-height: 27rem;
  position: relative;
  z-index: 10;
}
.footer .footer_wrap {
  padding: 3.2rem 1.6rem;
  position: relative;
}
.footer h2 {
  position: absolute;
  top: 3.2rem;
  right: 1.6rem;
  width: 9rem;
  height: 11.5rem;
  font-size: 0;
  text-indent: -9999px;
  background: url(../assets/images/logo_footer.png) no-repeat center;
  background-size: contain;
}
.footer .nav {
  margin-bottom: 3.2rem;
}
.footer .nav ul {
  font-size: 1.4rem;
  font-weight: 600;
}
.footer .nav ul li {
  margin-bottom: 1.6rem;
}
.footer .info_company ul {
  font-size: 1.2rem;
  margin-bottom: 1.6rem;
}
.footer .info_company ul:last-child {
  margin-bottom: 0;
}
.footer .info_company li {
  margin-bottom: 1rem;
}
.footer .copy_wrap {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  padding: 1.6rem 0;
  font-size: 1.2rem;
  color: rgba(255, 255, 255, 0.5);
}
.footer .copy_wrap p {
  padding-left: 1.6rem;
}