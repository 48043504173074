.waiting_service_wrap .loading_box {
  padding-top: 5rem;
  text-align: center;
  margin-bottom: 9.3rem;
}
.waiting_service_wrap .loading_box .loading {
  background: url(../../../assets/images/loading.svg) no-repeat;
  width: 9rem;
  height: 9rem;
  margin: 0 auto;
  -moz-animation: rotate-loading 3s linear 0s infinite normal;
  -moz-transform-origin: 50% 50%;
  -o-animation: rotate-loading 3s linear 0s infinite normal;
  -o-transform-origin: 50% 50%;
  -webkit-animation: rotate-loading 3s linear 0s infinite normal;
  -webkit-transform-origin: 50% 50%;
  animation: rotate-loading 3s linear 0s infinite normal;
}
.waiting_service_wrap .loading_box .loading.type_2 {
  background: url(../../../assets/images/loading_red.svg) no-repeat;
}
.waiting_service_wrap .loading_box h2 {
  font-size: 2.6rem;
  margin: 2.5rem 0 1.4rem;
}
.waiting_service_wrap .info {
  color: #999;
}
.waiting_service_wrap .info li {
  margin-top: 1rem;
  position: relative;
  padding-left: 1.4rem;
}
.waiting_service_wrap .info li::before {
  content: "";
  width: 3px;
  height: 3px;
  border-radius: 50%;
  display: block;
  background: #999;
  vertical-align: middle;
  position: absolute;
  top: 6px;
  left: 0;
}
@media (max-width: 959px) {
  .waiting_service_wrap .loading_box {
    margin-bottom: 6rem;
  }
  .waiting_service_wrap .loading_box h2 {
    font-size: 2rem;
  }
  .waiting_service_wrap .info {
    font-size: 1.2rem;
  }
}

@keyframes rotate-loading {
  0% {
    transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
  }
}