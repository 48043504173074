.introduce_container {
  position: relative;
  width: 100%;
  max-width: 1920px;
  min-height: calc(100vh - 80px);
  margin: 0 auto;
  padding-top: 8rem;
  padding-left: 26rem;
}
.introduce_container .flex_row {
  display: flex;
  align-items: center;
  gap: 2rem;
}
.introduce_container .desc_s {
  font-size: 1.4rem;
  color: #999;
  line-height: 2.2rem;
}
.introduce_container .modal_title {
  margin-bottom: 1rem;
}
.introduce_container .modal_title h4 {
  font-size: 1.8rem;
  margin-bottom: 6px;
}
.introduce_container .modal_title p {
  color: #999;
}
.introduce_container .intro_box_wrap {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 10rem;
}
.introduce_container .intro_box_wrap .img_box {
  flex: 41%;
  aspect-ratio: 1/1;
}
.introduce_container .intro_box_wrap .img_box img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.introduce_container .intro_box_wrap .txt_box {
  flex: 50%;
  padding-top: 1.6rem;
}
.introduce_container .intro_box_wrap .txt_box h4 {
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 3.5rem;
}
.introduce_container .intro_box_wrap .txt_box div {
  margin-bottom: 2rem;
  line-height: 2.6rem;
}
.introduce_container .intro_box_wrap .txt_box div:last-child {
  margin-bottom: 0;
}
.introduce_container .intro_box_wrap .txt_box div.ceo {
  font-size: 1.4rem;
  text-align: right;
  margin-top: 3rem;
}
.introduce_container .intro_box_wrap .txt_box div.ceo span {
  color: #999;
  margin-bottom: 5px;
}
.introduce_container .intro_box_wrap .txt_box div.ceo p {
  line-height: 2.6rem;
}
.introduce_container .intro_box_wrap .txt_box div.ceo p b {
  font-size: 2rem;
  font-weight: bold;
}
@media (max-width: 1560px) {
  .introduce_container .intro_box_wrap {
    gap: 3vw;
  }
}
@media (max-width: 1440px) {
  .introduce_container .intro_box_wrap {
    align-items: flex-start;
  }
}
.introduce_container .history_box_wrap .intro_txt {
  line-height: 2.4rem;
}
.introduce_container .history_box_wrap .history_box {
  margin-top: 6.5rem;
}
.introduce_container .history_box_wrap .history_box .box {
  padding-left: 23rem;
  position: relative;
  padding-bottom: 3.2rem;
}
.introduce_container .history_box_wrap .history_box .box::before {
  content: "";
  display: block;
  width: 1px;
  height: 100%;
  border-left: 1px dashed #909090;
  position: absolute;
  top: 2.4rem;
  left: -1px;
}
.introduce_container .history_box_wrap .history_box .box:last-child::before {
  display: none;
}
.introduce_container .history_box_wrap .history_box .box .year {
  font-size: 5rem;
  font-weight: bold;
  display: block;
  position: absolute;
  padding-left: 2.6rem;
  top: 0;
  left: 0;
}
.introduce_container .history_box_wrap .history_box .box .year::before {
  content: "";
  display: block;
  width: 1.3rem;
  height: 1.3rem;
  border: 3px solid #e60013;
  border-radius: 50%;
  box-sizing: border-box;
  position: absolute;
  top: 2.4rem;
  left: -7px;
  background: #fff;
}
.introduce_container .history_box_wrap .history_box .box .list_box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 6rem;
  gap: 1.5rem;
}
.introduce_container .history_box_wrap .history_box .box .history {
  display: flex;
  align-items: flex-start;
}
.introduce_container .history_box_wrap .history_box .box .history .month {
  color: #e60013;
  font-size: 1.6rem;
  font-weight: 600;
  margin-right: 3rem;
  display: block;
  width: 2.2rem;
}
.introduce_container .history_box_wrap .history_box .box .history p {
  line-height: 2.2rem;
}
.introduce_container .staff_box_wrap {
  padding-top: 2.8rem;
}
.introduce_container .staff_box_wrap .inner {
  display: flex;
  flex-wrap: wrap;
  gap: 6rem;
}
.introduce_container .staff_box_wrap .inner .staff_box {
  width: calc(20% - 48px);
  max-width: 27rem;
  text-align: center;
  cursor: pointer;
}
.introduce_container .staff_box_wrap .inner .staff_box img {
  width: 100%;
  height: 25rem;
  -o-object-fit: cover;
     object-fit: cover;
}
.introduce_container .staff_box_wrap .inner .staff_box .info_txt {
  margin-top: 1rem;
  min-height: 6rem;
  background: #000;
  color: #999;
  padding: 1rem;
  text-align: left;
  font-size: 1.4rem;
}
.introduce_container .staff_box_wrap .inner .staff_box .info_txt b {
  color: #fff;
  font-weight: 600;
  display: block;
  font-size: 1.6rem;
  margin-bottom: 5px;
  word-break: break-all;
}
@media (max-width: 1360px) {
  .introduce_container .staff_box_wrap .inner {
    gap: 8rem;
  }
  .introduce_container .staff_box_wrap .inner .staff_box {
    width: calc(25% - 60px);
  }
  .introduce_container .staff_box_wrap .inner .staff_box img {
    height: 20rem;
  }
}
.introduce_container .table_wrap td button {
  color: #e60013;
  font-weight: 600;
  padding-right: 0;
}
.introduce_container .view_content .title {
  border-top: 3px solid #000;
  border-bottom: 1px solid #000;
  padding: 2.5rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.introduce_container .view_content .title h3 {
  font-size: 2.2rem;
}
.introduce_container .view_content .content {
  padding: 3.5rem 2rem;
  border-bottom: 1px solid #dedede;
}
.introduce_container .view_content .content .text_box {
  padding-top: 3rem;
}
.introduce_container .view_content .content .img_box {
  width: 100%;
}
.introduce_container .view_content .list_wrap {
  margin-bottom: 3rem;
}
.introduce_container .view_content .list_wrap li {
  border-bottom: 1px solid #dedede;
  height: 6.9rem;
  display: flex;
  align-items: center;
  padding: 0 2rem;
  cursor: pointer;
}
.introduce_container .view_content .list_wrap li i {
  width: 6rem;
  background: url(../../assets/images/icon_dropdown_arrow.svg) no-repeat center right;
}
.introduce_container .view_content .list_wrap li span {
  flex: 1;
  padding: 0 4rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.introduce_container .view_content .list_wrap li.prev i {
  background: url(../../assets/images/icon_dropdown_close.svg) no-repeat center right;
}
.introduce_container .view_content .btn_wrap {
  text-align: right;
}
.introduce_container .introduce_sub_nav {
  height: calc(100vh - 80px);
  position: absolute;
  background: #fff;
  width: 26rem;
  top: 8rem;
  left: 0;
  border-right: 1px solid #dedede;
  z-index: 1;
}
.introduce_container .introduce_sub_nav h3 {
  height: 5.5rem;
  line-height: 5.5rem;
  background: #000;
  color: #fff;
  font-size: 1.8rem;
  padding-left: 2.5rem;
}
.introduce_container .introduce_sub_nav nav li {
  height: 5rem;
  line-height: 4.9rem;
  border-bottom: 1px solid #dedede;
  color: #999;
}
.introduce_container .introduce_sub_nav nav li a {
  display: block;
  padding-left: 2rem;
}
.introduce_container .introduce_sub_nav nav :global .active {
  background-color: #f8fafa;
  color: #000;
  font-weight: bold;
}
.introduce_container section .introduce_contents {
  width: 100%;
  height: 100%;
  position: relative;
}
.introduce_container .contents_wrap {
  width: 100%;
  max-width: 135rem;
  padding: 7rem 5rem 15rem 10rem;
}
.introduce_container .contents_wrap .contents_title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 2rem;
  height: 3.9rem;
  position: relative;
}
.introduce_container .contents_wrap .contents_title h3 {
  font-size: 2.4rem;
}
.introduce_container .contents_wrap .contents_title div {
  width: 29.5rem;
}
.introduce_container .contents_wrap .contents_title .desc {
  width: 100%;
  margin-top: 1rem;
}
.introduce_container .contents_wrap .contents_title .search_wrap {
  display: flex;
  justify-content: flex-end;
  position: relative;
}
.introduce_container .contents_wrap .contents_title .search_wrap input {
  width: 29.5rem;
  padding-right: 4rem;
}
.introduce_container .contents_wrap .contents_title .search_wrap button {
  position: absolute;
  top: 0.7rem;
  right: 1.5rem;
}
.introduce_container .contents_wrap .contents_title .filter {
  display: flex;
  gap: 2rem;
}
.introduce_container .contents_wrap .contents_title .filter li {
  color: #999999;
  cursor: pointer;
}
.introduce_container .contents_wrap .contents_title .filter li.on {
  font-weight: 600;
  color: #000;
  text-decoration: underline;
  text-underline-position: under;
}
.introduce_container .contents_wrap .center_search_wrap {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  width: 60rem;
  margin: 1rem auto 7.5rem;
  border-bottom: 5px solid #000;
}
.introduce_container .contents_wrap .center_search_wrap div {
  flex: 1;
}
.introduce_container .contents_wrap .center_search_wrap div input {
  border: none;
  height: 5.5rem;
}
.introduce_container .contents_wrap .center_search_wrap button {
  width: 3rem;
  height: 3rem;
  background-size: 3rem 3rem;
}
.introduce_container .contents_wrap .bottom_btn_wrap {
  margin-top: 4rem;
  text-align: center;
}
.introduce_container .contents_wrap.location_contents_wrap {
  padding: 7rem 0rem 15rem 0rem;
  max-width: initial;
}
.introduce_container .contents_wrap.location_contents_wrap .contents_title {
  padding-left: 10rem;
  padding-right: 5rem;
  max-width: 130rem;
}
.introduce_container .contents_wrap.location_contents_wrap .location_info_wrap {
  padding-left: 10rem;
  max-width: 130rem;
  padding-right: 5rem;
}
.introduce_container .contents_wrap.location_contents_wrap .location_info_wrap .map_wrap {
  width: 100%;
  height: 40rem;
  background: rgba(0, 0, 0, 0.3);
  overflow: hidden;
  margin-bottom: 2rem;
}
.introduce_container .contents_wrap.location_contents_wrap .location_info_wrap .contact {
  width: 100%;
  background: #f7f9fa;
  padding: 0 5rem;
}
.introduce_container .contents_wrap.location_contents_wrap .location_info_wrap .contact ul {
  display: flex;
  align-items: center;
  min-height: 12rem;
  justify-content: space-between;
  padding: 2rem 0;
}
.introduce_container .contents_wrap.location_contents_wrap .location_info_wrap .contact li {
  height: 5rem;
  padding-left: 6.5rem;
}
.introduce_container .contents_wrap.location_contents_wrap .location_info_wrap .contact li span {
  font-size: 1.4rem;
  display: block;
  margin-bottom: 5px;
  font-weight: 600;
}
.introduce_container .contents_wrap.location_contents_wrap .location_info_wrap .contact .logo {
  text-indent: -9999px;
  background: url(../../assets/images/logo_gnb_b.png) no-repeat center;
  background-size: cover;
  width: 15.4rem;
  height: 6.8rem;
}
.introduce_container .contents_wrap.location_contents_wrap .location_info_wrap .contact .address {
  background: url(../../assets/images/contact_pin.svg) no-repeat left center;
  background-size: 5rem 5rem;
}
.introduce_container .contents_wrap.location_contents_wrap .location_info_wrap .contact .tel {
  background: url(../../assets/images/contact_tel.svg) no-repeat left center;
  background-size: 5rem 5rem;
}
.introduce_container .contents_wrap.location_contents_wrap .location_info_wrap .contact .fax {
  background: url(../../assets/images/contact_fax.svg) no-repeat left center;
  background-size: 5rem 5rem;
}
.introduce_container .contents_wrap.location_contents_wrap .gallery_wrap {
  width: 100%;
  background: #f7f9fa;
  padding: 8rem 5rem 8rem 10rem;
  margin: 8rem 0;
}
.introduce_container .contents_wrap.location_contents_wrap .gallery_wrap .inner {
  max-width: 120rem;
  display: flex;
  justify-content: space-between;
  gap: 3rem;
}
.introduce_container .contents_wrap.location_contents_wrap .gallery_wrap .inner .img_box {
  width: calc(33% - 20px);
  max-height: 35rem;
}
.introduce_container .contents_wrap.location_contents_wrap .gallery_wrap .inner .img_box img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.introduce_container .contents_wrap.location_contents_wrap .location_info {
  padding-left: 10rem;
  padding-right: 5rem;
  max-width: 130rem;
}
.introduce_container .contents_wrap.location_contents_wrap .location_info .box:first-child {
  margin-bottom: 8rem;
}
.introduce_container .contents_wrap.location_contents_wrap .location_info .box h4 {
  font-size: 1.8rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
}
.introduce_container .contents_wrap.location_contents_wrap .location_info .box li {
  line-height: 2.5rem;
}
.introduce_container .contents_wrap.location_contents_wrap .location_info .box ol {
  padding-left: 1.6rem;
}
.introduce_container .contents_wrap.location_contents_wrap .location_info .box ol li {
  list-style-type: decimal;
}
.introduce_container .contents_wrap.location_contents_wrap .location_info .box ul li {
  padding-left: 8px;
  position: relative;
}
.introduce_container .contents_wrap.location_contents_wrap .location_info .box ul li::before {
  content: "";
  display: block;
  position: absolute;
  top: 1.1rem;
  left: 0;
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background: #000;
}
@media (max-width: 1560px) {
  .introduce_container .contents_wrap {
    padding: 1.5vw 2.5vw 7.813vw 2.5vw;
  }
  .introduce_container .contents_wrap.location_contents_wrap {
    padding-top: 1.5rem;
  }
  .introduce_container .contents_wrap.location_contents_wrap .contents_title,
  .introduce_container .contents_wrap.location_contents_wrap .location_info_wrap,
  .introduce_container .contents_wrap.location_contents_wrap .location_info,
  .introduce_container .contents_wrap.location_contents_wrap .gallery_wrap {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
}
@media (max-width: 1300px) {
  .introduce_container .contents_wrap.location_contents_wrap .location_info_wrap .contact {
    padding: 0 2rem;
  }
  .introduce_container .contents_wrap.location_contents_wrap .location_info_wrap .contact ul {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  .introduce_container .contents_wrap.location_contents_wrap .location_info_wrap .contact ul .logo {
    display: none;
  }
}
@media (max-width: 1024px) {
  .introduce_container .contents_wrap.location_contents_wrap .location_info_wrap .contact ul {
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 3rem;
  }
  .introduce_container .contents_wrap.location_contents_wrap .location_info_wrap .contact ul .address {
    width: 100%;
  }
}
.introduce_container .introduce_board {
  display: flex;
  gap: 4rem 2rem;
  flex-wrap: wrap;
}
.introduce_container .introduce_board .board {
  flex: calc(50% - 1rem);
  cursor: pointer;
}
.introduce_container .introduce_board .board .img_box {
  margin-bottom: 2rem;
  width: 100%;
  height: 26.7rem;
}
.introduce_container .introduce_board .board .img_box img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.introduce_container .introduce_board .board h4 {
  font-size: 1.8rem;
  margin-bottom: 6px;
}
.introduce_container .introduce_board .board p {
  color: #999;
}
.introduce_container .staff_profile_wrap .profile_box {
  display: flex;
  gap: 3rem;
}
.introduce_container .staff_profile_wrap .profile_box .img_box {
  width: 14rem;
  height: 16.5rem;
}
.introduce_container .staff_profile_wrap .profile_box .img_box img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.introduce_container .staff_profile_wrap .profile_box .profile {
  flex: 1;
}
.introduce_container .staff_profile_wrap .profile_box .profile h4 {
  font-size: 1.8rem;
  margin-bottom: 1.5rem;
}
.introduce_container .staff_profile_wrap .profile_box .profile > ul li {
  line-height: 2.6rem;
  padding-left: 8px;
  position: relative;
}
.introduce_container .staff_profile_wrap .profile_box .profile > ul li::before {
  content: "";
  display: block;
  position: absolute;
  top: 11px;
  left: 0;
  width: 3px;
  height: 3px;
  background: #000;
  border-radius: 50%;
}
.introduce_container .staff_profile_wrap .profile_box .profile .award_box {
  padding: 10px 8px;
}
.introduce_container .staff_profile_wrap .profile_box .profile .award_box .inner {
  background: #f7f9fa;
  padding: 1.5rem 2rem;
  max-height: 50rem;
  overflow-y: auto;
}
.introduce_container .staff_profile_wrap .profile_box .profile .award_box .inner ul {
  max-height: 17rem;
  overflow-y: auto;
}
.introduce_container .staff_profile_wrap .profile_box .profile .award_box .inner li {
  display: flex;
  line-height: 2.6rem;
}
.introduce_container .staff_profile_wrap .profile_box .profile .award_box .inner li span {
  color: #999;
  display: block;
  margin-right: 2rem;
}
.introduce_container .center_card_board {
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
}
.introduce_container .center_card_board .box {
  width: calc(50% - 10px);
  background: #f7f9fa;
  padding: 2.5rem;
}
.introduce_container .center_card_board .box h4 {
  font-size: 1.8rem;
  font-weight: 600;
  margin-bottom: 1rem;
}
.introduce_container .center_card_board .box p {
  font-size: 1.4rem;
}
.introduce_container .center_card_board .box p.tel {
  margin-top: 5px;
  color: #999;
}
.introduce_container .empty_data {
  padding: 13rem 0 0.5rem 0;
  text-align: center;
}
.introduce_container .empty_data p {
  padding: 11rem 0 3rem;
  font-size: 2.4rem;
  font-weight: bold;
  background: url(../../assets/images/ed_icon_08.svg) no-repeat center top;
}
.introduce_container .tab_menu {
  width: 100%;
  display: flex;
  margin-bottom: 4rem;
}
.introduce_container .tab_menu li {
  width: 50%;
  height: 5rem;
  line-height: 5rem;
  background: #f7f9fa;
  color: #999;
  font-weight: 500;
  text-align: center;
  cursor: pointer;
}
.introduce_container .tab_menu li.on {
  background: #e60013;
  color: #fff;
  font-weight: bold;
}
.introduce_container .tab_menu li.in {
  color: #e60013;
}
.introduce_container .tab_menu li.in.on {
  color: #fff;
}
.introduce_container .tab_contents_wrap {
  padding-top: 4rem;
}
.introduce_container .tab_contents_wrap .faq_wrap {
  margin-bottom: 4rem;
}
.introduce_container .tab_contents_wrap .result_wrap h4 {
  font-size: 1.8rem;
  margin-bottom: 1rem;
}
.introduce_container .tab_contents_wrap .result_wrap button {
  display: block;
  margin: 4rem auto;
}