.kegel_container {
  position: relative;
  width: 100%;
  max-width: 1920px;
  min-height: calc(100vh - 80px);
  margin: 0 auto;
  padding-top: 8rem;
  padding-left: 26rem;
}
.kegel_container .flex_row {
  display: flex;
  align-items: center;
  gap: 2rem;
}
.kegel_container .desc_s {
  font-size: 1.4rem;
  color: #999;
  line-height: 2.2rem;
}
.kegel_container .desc_list {
  font-size: 1.4rem;
  line-height: 2.2rem;
}
.kegel_container .desc_list li {
  white-space: normal;
  padding-left: 8px;
  position: relative;
}
.kegel_container .desc_list li::before {
  content: "";
  display: block;
  width: 3px;
  height: 3px;
  background: #000;
  border-radius: 50%;
  margin-right: 5px;
  position: absolute;
  top: 9px;
  left: 0;
}
.kegel_container .desc_list.gray {
  color: #999;
}
.kegel_container .desc_list.gray li::before {
  background: #999;
}
.kegel_container .desc_list.footer_st {
  margin-top: 3rem;
}
.kegel_container .desc_list.footer_st b {
  color: #000;
  display: block;
  margin-bottom: 1rem;
}
.kegel_container .info_title {
  margin-bottom: 2rem;
  line-height: 2.2rem;
}
.kegel_container .detail_table_wrap table tr {
  cursor: default;
}
.kegel_container .detail_table_wrap table tr th,
.kegel_container .detail_table_wrap table tr td {
  overflow: initial;
  text-overflow: initial;
  padding: 5px 20px;
}
.kegel_container .detail_table_wrap table tr th {
  padding: 2rem 0 0 2rem;
}
.kegel_container .table_img_box {
  width: 27rem;
  height: 16.8rem;
}
.kegel_container .table_img_box img {
  width: 100%;
  height: 100%;
}
.kegel_container .btn_bottom_wrap {
  margin-top: 3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}
.kegel_container .btn_bottom_wrap .right {
  display: flex;
  gap: 1rem;
  margin-left: auto;
}
.kegel_container .thumbnail_box {
  display: flex;
  align-items: flex-end;
  gap: 1rem;
  margin-bottom: 1rem;
}
.kegel_container .thumbnail_box img {
  width: 14rem;
  height: 8.5rem;
  -o-object-fit: cover;
     object-fit: cover;
}
.kegel_container .kegel_sub_nav {
  height: calc(100vh - 80px);
  position: absolute;
  background: #fff;
  width: 26rem;
  top: 8rem;
  left: 0;
  border-right: 1px solid #dedede;
  z-index: 1;
}
.kegel_container .kegel_sub_nav h3 {
  height: 5.5rem;
  line-height: 5.5rem;
  background: #000;
  color: #fff;
  font-size: 1.8rem;
  padding-left: 2.5rem;
}
.kegel_container .kegel_sub_nav nav li {
  height: 5rem;
  line-height: 4.9rem;
  border-bottom: 1px solid #dedede;
  color: #999;
}
.kegel_container .kegel_sub_nav nav li a {
  display: block;
  padding-left: 2rem;
}
.kegel_container .kegel_sub_nav nav li.depth_1 > a {
  background: url(../../assets/images/lnb_arrow_gray.svg) no-repeat center right 2rem;
  transition: all 0.1s;
}
.kegel_container .kegel_sub_nav nav li.depth_1 .depth_2 {
  display: none;
}
.kegel_container .kegel_sub_nav nav li.depth_1 .on {
  font-weight: bold;
  color: #000;
  background: #f8fafa url(../../assets/images/lnb_arrow_black.svg) no-repeat center right 2rem;
}
.kegel_container .kegel_sub_nav nav li.depth_1 .on + .depth_2 {
  display: block;
  background: #f8fafa;
}
.kegel_container .kegel_sub_nav nav li.depth_1 .on + .depth_2 li {
  border-bottom: none;
  color: #000;
}
.kegel_container .kegel_sub_nav nav li.depth_1 .on + .depth_2 li a {
  padding-left: 2.9rem;
  background-image: url(../../assets/images/lnb_depth.svg);
  background-repeat: no-repeat;
  background-position: left 2rem center;
}
.kegel_container .kegel_sub_nav nav li.depth_1 .on + .depth_2 li:last-child {
  border-bottom: 1px solid #dedede;
}
.kegel_container .kegel_sub_nav nav li.depth_1 .on + .depth_2 :global .active {
  background-color: #edf0f0;
}
.kegel_container .kegel_sub_nav nav :global .active {
  background-color: #f8fafa;
  color: #000;
  font-weight: bold;
}
.kegel_container section .kegel_contents {
  width: 100%;
  height: 100%;
  position: relative;
}
.kegel_container .contents_wrap {
  max-width: 130rem;
  width: 100%;
  padding: 6rem 0 15rem 10rem;
}
.kegel_container .contents_wrap .contents_title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 2rem;
}
.kegel_container .contents_wrap .contents_title h3 {
  font-size: 2.4rem;
}
.kegel_container .contents_wrap .contents_title div {
  width: 29.5rem;
}
.kegel_container .contents_wrap .contents_title .search_wrap {
  position: relative;
}
.kegel_container .contents_wrap .contents_title .search_wrap div {
  width: 29.5rem;
}
.kegel_container .contents_wrap .contents_title .search_wrap div input {
  padding: 0 3.5rem 0 1.5rem;
}
.kegel_container .contents_wrap .contents_title .search_wrap button {
  position: absolute;
  right: 10px;
  top: 7px;
}
.kegel_container .contents_wrap .contents_title .desc {
  width: 100%;
  margin-top: 1rem;
}
.kegel_container .contents_wrap .card_board {
  width: 100%;
}
.kegel_container .contents_wrap .card_board .card {
  width: 100%;
  height: 17.4rem;
  background: #f8fafa;
  display: flex;
  margin-bottom: 1.5rem;
  cursor: pointer;
}
.kegel_container .contents_wrap .card_board .card .img_box {
  width: 27rem;
  height: 17.4rem;
}
.kegel_container .contents_wrap .card_board .card .img_box img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.kegel_container .contents_wrap .card_board .card .text_box {
  padding: 4rem;
  flex: 1;
}
.kegel_container .contents_wrap .card_board .card .text_box h5 {
  font-size: 1.6rem;
  color: #e60013;
  font-weight: 600;
  margin-bottom: 1rem;
}
.kegel_container .contents_wrap .card_board .card .text_box h4 {
  font-size: 2.2rem;
  margin-bottom: 2rem;
}
.kegel_container .contents_wrap .contents_detail .contents_title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 42rem;
  margin-bottom: 8rem;
}
.kegel_container .contents_wrap .contents_detail .contents_title .img_box {
  max-width: 60rem;
  width: 50%;
  height: 42rem;
}
.kegel_container .contents_wrap .contents_detail .contents_title .img_box img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.kegel_container .contents_wrap .contents_detail .contents_title .contents_info {
  max-width: 50rem;
  width: 47%;
  height: 100%;
  padding: 5rem 0;
}
.kegel_container .contents_wrap .contents_detail .contents_title .contents_info .title {
  width: 100%;
  border-top: 2px solid #000;
  border-bottom: 1px solid #dedede;
  padding: 2.4rem 1.5rem;
}
.kegel_container .contents_wrap .contents_detail .contents_title .contents_info .title .en {
  font-size: 1.4rem;
  color: #e60013;
  font-weight: 600;
  margin-bottom: 4px;
}
.kegel_container .contents_wrap .contents_detail .contents_title .contents_info .title h3 {
  font-size: 3rem;
}
.kegel_container .contents_wrap .contents_detail .contents_title .contents_info .contents {
  padding: 3rem 1.5rem;
  line-height: 2.6rem;
  width: 100%;
}
.kegel_container .contents_wrap .contents_detail .contents_title .contents_info .contents li {
  padding-left: 7px;
  position: relative;
}
.kegel_container .contents_wrap .contents_detail .contents_title .contents_info .contents li::before {
  content: "";
  display: block;
  width: 2px;
  height: 2px;
  border-radius: 50%;
  background: #000;
  position: absolute;
  top: 1.1rem;
  left: 0;
}
.kegel_container .contents_wrap .contents_detail .detail_box {
  width: 100%;
  border-top: 2px solid #000;
  border-bottom: 1px solid #dedede;
  padding: 8rem 0;
  text-align: center;
}
.kegel_container .contents_wrap .contents_detail .btn_wrap {
  margin-top: 2rem;
  text-align: right;
}
@media (max-width: 1440px) {
  .kegel_container .contents_wrap .contents_detail .contents_title {
    height: auto;
    margin-bottom: 4rem;
  }
  .kegel_container .contents_wrap .contents_detail .contents_title .contents_info {
    padding: 2rem 0;
  }
}
.kegel_container .contents_wrap .view_content .title {
  border-top: 3px solid #000;
  border-bottom: 1px solid #000;
  padding: 2.5rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.kegel_container .contents_wrap .view_content .content {
  padding: 3.5rem 2rem;
  border-bottom: 1px solid #dedede;
}
.kegel_container .contents_wrap .view_content .content .text_box {
  padding-top: 3rem;
}
.kegel_container .contents_wrap .view_content .list_wrap {
  margin-bottom: 3rem;
}
.kegel_container .contents_wrap .view_content .list_wrap li {
  border-bottom: 1px solid #dedede;
  height: 6.9rem;
  display: flex;
  align-items: center;
  padding: 0 2rem;
  cursor: pointer;
}
.kegel_container .contents_wrap .view_content .list_wrap li i {
  width: 6rem;
  background: url(../../assets/images/icon_dropdown_arrow.svg) no-repeat center right;
}
.kegel_container .contents_wrap .view_content .list_wrap li span {
  flex: 1;
  padding: 0 4rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.kegel_container .contents_wrap .view_content .list_wrap li.prev i {
  background: url(../../assets/images/icon_dropdown_close.svg) no-repeat center right;
}
.kegel_container .contents_wrap .view_content .btn_wrap {
  text-align: right;
}
.kegel_container .contents_wrap .gallery_board {
  display: flex;
  flex-wrap: wrap;
  gap: 4rem 3rem;
  margin-bottom: 6rem;
}
.kegel_container .contents_wrap .gallery_board .box {
  max-width: 38rem;
  width: calc(33% - 20px);
  max-height: 30rem;
  cursor: pointer;
}
.kegel_container .contents_wrap .gallery_board .box .thumbnail {
  width: 100%;
  height: 26rem;
}
.kegel_container .contents_wrap .gallery_board .box .thumbnail img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.kegel_container .contents_wrap .gallery_board .box p {
  margin-top: 2rem;
  font-size: 1.8rem;
  font-weight: 600;
}
.kegel_container .contents_wrap .bottom_center_btn {
  text-align: center;
  margin-top: 6rem;
}
@media (max-width: 1560px) {
  .kegel_container .contents_wrap {
    padding: 1.5vw 2.5vw 7.813vw 2.5vw;
  }
}
.kegel_container .empty_data {
  padding: 13rem 0 0.5rem 0;
  text-align: center;
}
.kegel_container .empty_data p {
  padding: 11rem 0 3rem;
  font-size: 2.4rem;
  font-weight: bold;
  background: url(../../assets/images/ed_icon_07.svg) no-repeat center top;
}
.kegel_container .empty_data.event_st p {
  background: url(../../assets/images/ed_icon_08.svg) no-repeat center top;
}
.kegel_container .empty_data.cs_st p {
  background: url(../../assets/images/ed_icon_09.svg) no-repeat center top;
}
.kegel_container .empty_data.as_st p {
  background: url(../../assets/images/ed_icon_10.svg) no-repeat center top;
}
.kegel_container .tab_menu {
  width: 100%;
  display: flex;
  margin-bottom: 2rem;
}
.kegel_container .tab_menu li {
  width: 50%;
  height: 5rem;
  line-height: 5rem;
  background: #f7f9fa;
  color: #999;
  font-weight: 500;
  text-align: center;
  cursor: pointer;
}
.kegel_container .tab_menu li.on {
  background: #e60013;
  color: #fff;
  font-weight: bold;
}