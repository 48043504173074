.search_box {
  text-align: center;
  position: relative;
  width: 45rem;
}
.search_box .btn_search {
  position: absolute;
  text-indent: -9999px;
  width: 3rem;
  height: 3rem;
  background: url(../../../assets/images/icon_popup_search.svg) no-repeat center;
  top: 1.6rem;
  right: 2.5rem;
}
.search_box .btn_search:focus {
  outline: none;
}
.search_box .error_txt {
  font-size: 1.4rem;
  color: #e60013;
  margin-top: 0.5rem;
  padding-left: 2.7rem;
  text-align: left;
  background: url(../../../assets/images/icon_error.svg) no-repeat left 1rem center;
}
.search_box.lg {
  color: red;
}
.search_box.default input {
  height: 3.9rem;
  width: 100%;
  background: #fff;
  border-color: #dedede;
  border-radius: 0rem;
  padding: 0 5rem 0 2.5rem;
}
.search_box.default .btn_search {
  top: 4px;
  right: 10px;
  background-size: 2.4rem 2.4rem;
}
@media (max-width: 959px) {
  .search_box {
    width: 100%;
    max-width: 45rem;
    margin: 0 auto;
  }
  .search_box .btn_search {
    top: 8px;
    right: 1.6rem;
    width: 2.4rem;
    height: 2.4rem;
    background-size: 2.4rem auto;
  }
}