.selectBox {
  position: relative;
  min-width: 8.5rem;
  width: 29.5rem;
}
.selectBox::after {
  content: "";
  font-size: 1.3rem;
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  transition: all 0.3s;
  background: url(../../../assets/images/icon_dropdown_arrow.svg) no-repeat;
  width: 1rem;
  height: 1rem;
  transform: rotate(0deg);
}
.selectBox p {
  padding: 0 1.5rem;
  border: 1px solid #dedede;
  background: #fff;
  height: 3.9rem;
  line-height: 3.7rem;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.selectBox ul {
  position: absolute;
  display: none;
  background: #fff;
  width: inherit;
  transition: all 0.3s;
  overflow: hidden;
  z-index: 9;
  border: 1px solid #dedede;
  border-top: none;
  max-height: 20rem;
  overflow-y: auto;
}
.selectBox ul li {
  height: 3.9rem;
  line-height: 3.9rem;
  cursor: pointer;
  padding: 0 1.4rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.selectBox ul li:hover {
  background: #f7f9fa;
}
.selectBox ul :global li.true {
  cursor: default;
}
.selectBox ul :global li.true span {
  color: #999999;
}
.selectBox.on p {
  border-bottom: none;
}
.selectBox.on::after {
  transform: rotate(180deg);
}
.selectBox.on ul {
  display: block;
}
.selectBox.default p {
  height: 4.5rem;
  line-height: 4.5rem;
}
.selectBox.default ul {
  position: absolute;
  width: 100%;
}
.selectBox.sm {
  width: 8.5rem;
}
.selectBox.readonly {
  color: #999;
  border-color: #dedede;
}
.selectBox.readonly p {
  background: #f7f9fa;
  cursor: default;
}
.selectBox.readonly::after {
  cursor: default;
  opacity: 0.5;
}
.selectBox.admin_st {
  width: 24rem !important;
}
.selectBox.admin_st p {
  height: 4.5rem;
  line-height: 4.3rem;
}
.selectBox.admin_st::after {
  width: 1.4rem;
  height: 1.4rem;
  background: url(../../../assets/images/form_icon_extension.svg) no-repeat;
}
.selectBox.admin_st ul {
  width: 24rem;
}
.selectBox.admin_st.w100 {
  width: 100% !important;
}
.selectBox.admin_st.w_sm {
  width: 12rem !important;
  text-align: left;
}
.selectBox.w_50 {
  width: 50% !important;
}
.selectBox.w_100 {
  width: 100% !important;
}
.selectBox.right {
  margin-left: auto;
}
.selectBox.dark_mode {
  width: 17rem;
}
.selectBox.dark_mode::after {
  background: url(../../../assets/images/dropdown_down_w.svg) no-repeat;
}
.selectBox.dark_mode p {
  background: #000;
  color: #fff !important;
  background: url;
}
.selectBox.dark_mode ul {
  background: #000;
}
.selectBox.dark_mode ul li:hover {
  background: rgba(255, 255, 255, 0.1);
}
@media (max-width: 959px) {
  .selectBox p,
  .selectBox li {
    font-size: 1.4rem;
  }
}

table .selectBox ul {
  position: fixed;
}
table .selectBox.admin_st ul {
  position: absolute;
  width: 100%;
}

.readonly {
  color: #999999;
}
.readonly.on p {
  border-bottom: 1px solid #dedede;
}
.readonly.on ul {
  display: none;
}
.readonly.on::after {
  transform: rotate(0);
}