.button {
  height: 5rem;
  line-height: 4.8rem;
  border: 1px solid #eff1f2;
  background: #eff1f2;
  min-width: 13rem;
  font-size: 1.8rem;
  font-weight: 600;
  border-radius: 3.5rem;
  transition: all 0.2s;
  padding: 0 2.5rem;
  text-align: center;
}
.button a {
  display: block;
}
.button:hover {
  background: #eff1f2;
  border-color: #000000;
}
.button:focus {
  outline: none;
}
.button:disabled, .button:focus:disabled, .button:hover:disabled, .button:active:disabled {
  background: #dedede;
  border-color: #dedede;
  color: #999999;
  cursor: not-allowed;
}
.button.primary {
  border-color: #3e21d9;
  color: #3e21d9;
  background: #fff;
}
.button.primary.plus {
  padding-left: 2.7rem;
  text-align: left;
  background: #fff url(../../../assets/images/btn_plus_primary.svg) no-repeat left 1rem center;
}
.button.official {
  color: #fff;
  background: #e60013;
  border-color: #e60013;
}
.button.official:hover {
  border-color: #e60013;
}
.button.official.xl {
  width: 100%;
  font-size: 2.4rem;
}
.button.secondary {
  color: #fff;
  background: #000000;
  border-color: #000000;
}
.button.secondary:hover {
  background: #46474a;
  border-color: #46474a;
}
.button.secondary:disabled, .button.secondary:focus:disabled, .button.secondary:hover:disabled, .button.secondary:active:disabled {
  background: #dedede;
  border-color: #dedede;
  color: #999999;
  cursor: not-allowed;
}
.button.secondary_2 {
  color: #fff;
  background: #02a496;
  border-color: #02a496;
}
.button.white {
  color: #fff;
  background: transparent;
  border-color: #fff;
}
.button.border {
  color: #000;
  background: #fff;
  border-color: #000000;
}
.button.border.plus {
  padding-left: 3.2rem;
  background: url(../../../assets/images/add_circle.svg) no-repeat left 1rem center;
  background-size: 2.4rem auto;
}
.button.sm {
  height: 4rem;
  line-height: 3.8rem;
  font-size: 1.6rem;
  min-width: 9rem;
  padding: 0 2rem;
}
.button.xs {
  height: 3.3rem;
  line-height: 3.1rem;
  font-size: 1.4rem;
  min-width: 7.8rem;
  padding: 0 1.5rem;
}
.button.xxs {
  height: 2.9rem;
  line-height: 2.7rem;
  font-size: 1.4rem;
  min-width: 7.8rem;
  padding: 0 1rem;
}
.button.xxxs {
  height: 2.7rem;
  line-height: 2.5rem;
  font-size: 1.4rem;
  min-width: 5.4rem;
  padding: 0 1.5rem;
}
.button.xl {
  height: 8rem;
  line-height: 7.8rem;
  border-radius: 3.9rem;
}
.button.close_x {
  text-indent: -9999px;
  font-size: 0;
  background: url(../../../assets/images/icon_popup_x.svg) no-repeat;
  width: 2.4rem;
  height: 2.4rem;
  min-width: initial;
  padding: 0;
  border: none;
}
.button.btn_text {
  background: url(../../../assets/images/icon_btn_text.svg) no-repeat;
  background-position: right center;
  border: none;
  text-align: left;
  font-weight: 500;
  font-size: 1.4rem;
  padding: 0 1.2rem 0 0;
  width: auto;
  min-width: initial;
  height: initial;
  line-height: initial;
}
.button.btn_text.white {
  color: #fff;
  padding: 0 1.5rem 0 0;
  background: url(../../../assets/images/icon_btn_text_w.svg) no-repeat;
  background-position: right center;
  background-size: 10px 10px;
}
.button.btn_text.primary {
  color: #3e21d9;
}
.button.btn_text.lg {
  font-size: 2rem;
  font-weight: bold;
  background: url(../../../assets/images/btn_text_lg.svg) no-repeat right center;
  background-size: 20px;
  padding: 0 3rem 0 0;
}
.button.text_underline {
  border: none;
  text-align: left;
  font-weight: 400;
  font-size: 1.6rem;
  padding: 0 1.2rem 0 0;
  background: none;
  text-decoration: underline;
  text-underline-position: under;
  width: auto;
  min-width: initial;
  height: initial;
  line-height: initial;
}
.button.square {
  color: #fff;
  background: #000;
  font-size: 1.6rem;
  font-weight: 600;
  height: 3.9rem;
  line-height: 3.9rem;
  min-width: 8.8rem;
  border-radius: 0;
  border: none;
}
.button.circle_add {
  min-width: 3.4rem;
  width: 3.4rem;
  height: 3.4rem;
  background: url(../../../assets/images/add_circle.svg) no-repeat center;
  border: none;
  padding: 0;
}
.button.circle_add.sm {
  min-width: 2.7rem;
  width: 2.7rem;
  height: 2.7rem;
  border: 1px solid #999;
  background: #fff;
  position: relative;
}
.button.circle_add.sm::before {
  content: "";
  display: block;
  width: 1rem;
  height: 1.5px;
  background: #999;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 50%;
}
.button.circle_add.sm::after {
  content: "";
  display: block;
  width: 1.1rem;
  height: 1.5px;
  background: #999;
  position: absolute;
  transform: rotate(90deg);
  left: 7.7px;
}
.button.circle_up {
  min-width: 2.8rem;
  width: 2.8rem;
  height: 2.8rem;
  background: url(../../../assets/images/form_icon_extension.svg) no-repeat center;
  border: 1px solid #000;
  border-radius: 50%;
  padding: 0;
}
.button.circle_down {
  min-width: 2.8rem;
  width: 2.8rem;
  height: 2.8rem;
  background: url(../../../assets/images/form_icon_close.svg) no-repeat center;
  border: 1px solid #000;
  border-radius: 50%;
  padding: 0;
}
.button.search_sm {
  min-width: initial;
  width: 2.4rem;
  height: 2.4rem;
  padding: 0;
  background: url(../../../assets/images/icon_search.svg) no-repeat;
  border: none;
}
.button.poi {
  min-width: initial;
  width: 1.8rem;
  height: 1.8rem;
  padding: 0;
  background: url(../../../assets/images/icon_location.svg) no-repeat center;
  border: none;
  margin-left: 1rem;
  vertical-align: text-top;
}
.button.text_icon {
  width: 13.7rem;
  border-color: #dedede;
  background: #fff;
  color: #999;
  padding-right: 0 3rem;
  text-align: left;
  background: url(../../../assets/images/btn_more.svg) no-repeat center right 3rem;
}
.button.download {
  background: url(../../../assets/images/icon_download.svg) no-repeat center right 1.5rem;
  padding-right: 3.5rem;
}
.button.official_st {
  color: #e60013;
}
.button.official_st.download {
  background: url(../../../assets/images/download_red.svg) no-repeat center right 1.5rem;
  padding-right: 3.5rem;
  text-decoration: underline;
  text-underline-position: under;
}
.button.upload {
  background: url(../../../assets/images/btn_icon_upload.svg) no-repeat center right 1.5rem;
  padding-right: 3.5rem;
}
.button.upload input[type='file'] {
  display: none;
}
.button.btn_x {
  background: url(../../../assets/images/pop_close_16_b.svg) no-repeat center;
  padding: 0;
  border: none;
  min-width: initial;
  width: 2rem;
  height: 2rem;
}
.button.share {
  background: url(../../../assets/images/icon_share.svg) no-repeat center;
  background-size: 2.2rem 2.2rem;
  padding: 0;
  border: none;
  min-width: initial;
  width: 3rem;
  height: 3rem;
}
.button.filter {
  background: url(../../../assets/images/icon_filter.svg) no-repeat center;
  background-size: 2.2rem 2.2rem;
  padding: 0;
  border: none;
  min-width: initial;
  width: 2.4rem;
  height: 2.4rem;
}
@media (max-width: 959px) {
  .button {
    font-size: 1.6rem;
    height: 4.5rem;
    line-height: 4.3rem;
    min-width: 10rem;
  }
  .button.sm {
    height: 3.3rem;
    line-height: 3.1rem;
    font-size: 1.4rem;
  }
}