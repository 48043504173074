.date_picker_box {
  display: flex;
  align-items: center;
}
.date_picker_box button:focus {
  outline: none;
}
.date_picker_box select {
  border: none;
  outline: none;
}
.date_picker_box input {
  cursor: pointer;
  width: 24rem;
  height: 4.5rem;
  border: 1px solid #dedede;
  padding: 0 1.5rem;
  background: url(../../../assets/images/form_icon_calendar.svg) no-repeat right 15px center;
}
.date_picker_box input:focus {
  outline: none;
}
.date_picker_box input[type=time] {
  background: none;
}
.date_picker_box > span {
  display: block;
  margin: 0 1rem;
}
.date_picker_box :global .react-datepicker-popper[data-placement^=bottom] {
  z-index: 10;
  padding-top: 5px;
}
.date_picker_box :global .react-datepicker {
  width: 240px;
  font-family: "Pretendard Variable", Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
  font-size: 1.4rem;
  border-color: #dedede;
  box-shadow: 2px 2px 10px 3px rgba(0, 0, 0, 0.1);
}
.date_picker_box :global .react-datepicker-wrapper {
  width: 24rem;
}
.date_picker_box :global .react-datepicker__triangle {
  display: none;
}
.date_picker_box :global .react-datepicker__header {
  padding: 5px 0 10px;
  border-bottom: 1px solid #dedede;
  background-color: #fff;
}
.date_picker_box :global .react-datepicker__header .react-datepicker__current-month {
  font-size: 1.6rem;
  font-weight: 500;
}
.date_picker_box :global .react-datepicker__header .react-datepicker__day-names .react-datepicker__day-name {
  width: 3rem;
  line-height: 3rem;
  font-size: 1.4rem;
  color: #999;
}
.date_picker_box :global .react-datepicker__month-container {
  float: inherit;
}
.date_picker_box :global .react-datepicker__month .react-datepicker__day-name,
.date_picker_box :global .react-datepicker__month .react-datepicker__day,
.date_picker_box :global .react-datepicker__month .react-datepicker__time-name {
  line-height: 3rem;
  width: 3rem;
}
.date_picker_box :global .react-datepicker__day--selected,
.date_picker_box :global .react-datepicker__day--in-selecting-range,
.date_picker_box :global .react-datepicker__day--in-range,
.date_picker_box :global .react-datepicker__month-text--selected,
.date_picker_box :global .react-datepicker__month-text--in-selecting-range,
.date_picker_box :global .react-datepicker__month-text--in-range,
.date_picker_box :global .react-datepicker__quarter-text--selected,
.date_picker_box :global .react-datepicker__quarter-text--in-selecting-range,
.date_picker_box :global .react-datepicker__quarter-text--in-range,
.date_picker_box :global .react-datepicker__year-text--selected,
.date_picker_box :global .react-datepicker__year-text--in-selecting-range,
.date_picker_box :global .react-datepicker__year-text--in-range {
  background-color: #b9b3d6;
}
.date_picker_box :global .react-datepicker__day--in-range,
.date_picker_box :global .react-datepicker__month-text--in-range,
.date_picker_box :global .react-datepicker__quarter-text--in-range,
.date_picker_box :global .react-datepicker__year-text--in-range {
  background-color: #8172d4;
}
.date_picker_box :global .react-datepicker__year-read-view--down-arrow,
.date_picker_box :global .react-datepicker__month-read-view--down-arrow,
.date_picker_box :global .react-datepicker__month-year-read-view--down-arrow,
.date_picker_box :global .react-datepicker__navigation-icon::before {
  width: 6px;
  height: 6px;
  border-width: 2px 2px 0 0;
  border-color: #999;
  top: 8px;
}
.date_picker_box :global .react-datepicker__day--outside-month {
  color: #999;
}
.date_picker_box.sm input {
  height: 3.5rem;
  width: 19rem;
}
.date_picker_box.sm :global .react-datepicker-wrapper {
  width: 19rem;
}