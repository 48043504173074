.official_main_wrap {
  width: 100%;
  position: relative;
}
.official_main_wrap .band_banner {
  max-width: 1920px;
  width: 100%;
  top: 8rem;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  position: fixed;
  height: 8rem;
  background: #3e21d9;
  color: #fff;
  align-items: center;
  padding: 0 2.5rem 0 22rem;
  z-index: 9;
}
.official_main_wrap .band_banner h4 {
  padding-left: 4.5rem;
  background: url(../assets/images/icon_tournament_cup.svg) no-repeat left center;
  font-size: 2.2rem;
  font-weight: 600;
  padding-right: 1.5rem;
  cursor: pointer;
}
.official_main_wrap .band_banner p {
  padding: 0 1rem;
  height: 2.5rem;
  border-radius: 1.3rem;
  border: 1px solid #fff;
  line-height: 2.3rem;
  font-size: 1.4rem;
  font-weight: 500;
  cursor: pointer;
}
.official_main_wrap .band_banner .today_close_wrap {
  margin-left: auto;
  display: flex;
  gap: 3rem;
}
@media (max-width: 1200px) {
  .official_main_wrap .band_banner {
    padding: 0 2.5rem 0 2.5rem;
  }
}
.official_main_wrap article {
  padding: 10rem 0;
  max-width: 1920px;
  margin: 0 auto;
}
.official_main_wrap article h2 {
  font-size: 3rem;
  font-weight: 800;
  margin-bottom: 2rem;
  display: flex;
  justify-content: space-between;
}
.official_main_wrap article.dark {
  background: #000;
  color: #fff;
}
.official_main_wrap article.dark .contents_wrap {
  gap: 4rem;
}
.official_main_wrap article.blue {
  background: #f7f9fa;
}
.official_main_wrap .contents_wrap {
  max-width: 1200px;
  margin: 0 auto;
}
@media (max-width: 1200px) {
  .official_main_wrap .contents_wrap {
    padding: 0 1rem;
  }
}
.official_main_wrap .official_main {
  width: 100%;
  height: 65rem;
  position: relative;
}
.official_main_wrap .official_main .visual_wrap {
  padding: 0;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 65rem;
}
.official_main_wrap .official_main .visual_wrap .main_img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.official_main_wrap .official_main .login_wrap {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  width: 100%;
  background: rgba(23, 18, 47, 0.5);
  height: 17rem;
  -webkit-backdrop-filter: blur(8.9px);
  backdrop-filter: blur(8.9px);
  padding: 0;
}
.official_main_wrap .official_main .login_wrap > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.official_main_wrap .official_main .login_wrap .login {
  width: 86rem;
  height: 17rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: auto;
}
.official_main_wrap .official_main .login_wrap .login input {
  width: 29.5rem;
}
.official_main_wrap .official_main .login_wrap .login .welcome_wrap {
  color: #fff;
}
.official_main_wrap .official_main .login_wrap .login .welcome_wrap h3 {
  font-size: 3rem;
}
.official_main_wrap .official_main .login_wrap .login .welcome_wrap p {
  font-size: 2rem;
  font-weight: 500;
}
.official_main_wrap .official_main .login_wrap .login .welcome_wrap .j_id {
  font-size: 3rem;
  font-weight: bold;
  margin-top: 1.3rem;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
}
.official_main_wrap .official_main .login_wrap .login .welcome_wrap .j_id .tooltip {
  margin-left: 2rem;
  width: 2.4rem;
  height: 2.4rem;
  text-indent: -9999px;
  background: url(../assets/images/icon_tooltip_w.svg) no-repeat center;
}
.official_main_wrap .official_main .login_wrap .login .welcome_wrap .j_id .tooltip_content {
  position: absolute;
  top: -122px;
  left: 246px;
  display: block;
  width: 24.8rem;
  height: 11rem;
  background: #fff;
  padding: 2rem;
  color: #000;
  cursor: default;
  display: none;
  transition: all 0.5s;
}
.official_main_wrap .official_main .login_wrap .login .welcome_wrap .j_id .tooltip_content b {
  font-size: 1.6rem;
  font-weight: 600;
  margin-bottom: 1rem;
  display: block;
}
.official_main_wrap .official_main .login_wrap .login .welcome_wrap .j_id .tooltip_content span {
  display: block;
  font-size: 1.4rem;
  line-height: 2rem;
  font-weight: 400;
}
.official_main_wrap .official_main .login_wrap .login .welcome_wrap .j_id .tooltip_content::before {
  content: "";
  display: block;
  position: absolute;
  width: 1.6rem;
  height: 1.6rem;
  background: #e60013;
  top: -6px;
  right: -6px;
}
.official_main_wrap .official_main .login_wrap .login .welcome_wrap .j_id .tooltip_content::after {
  content: "";
  display: block;
  position: absolute;
  width: 1.2rem;
  height: 1.2rem;
  background: #fff;
  transform: rotate(45deg);
  top: 102px;
  left: 16px;
}
.official_main_wrap .official_main .login_wrap .login .welcome_wrap .j_id:hover .tooltip_content {
  display: block;
}
.official_main_wrap .official_main .login_wrap .login .welcome_wrap .btn_wrap {
  margin-top: 2rem;
  gap: 2rem;
  display: flex;
}
@media (max-width: 1500px) {
  .official_main_wrap .official_main .login_wrap .login {
    justify-content: space-around;
  }
}
@media (max-width: 1200px) {
  .official_main_wrap .official_main .login_wrap .login {
    justify-content: flex-start;
    padding-left: 2rem;
  }
  .official_main_wrap .official_main .login_wrap .login input {
    width: 18rem;
  }
}
.official_main_wrap .official_main .login_wrap .my_page_wrap {
  height: 17rem;
  display: flex;
  width: 36%;
  background: #000000;
}
.official_main_wrap .official_main .login_wrap .my_page_wrap p {
  width: 17rem;
  text-align: center;
  color: #fff;
  font-size: 1.6rem;
  font-weight: bold;
  padding-top: 10.8rem;
  cursor: pointer;
}
.official_main_wrap .official_main .login_wrap .my_page_wrap p.cs {
  background: #1c1c1c url(../assets/images/icon_cs.svg) no-repeat center top 4.8rem;
}
.official_main_wrap .official_main .login_wrap .my_page_wrap p.as {
  background: url(../assets/images/icon_as.svg) no-repeat center top 4.8rem;
}
.official_main_wrap .tournament_list li {
  height: 8rem;
  line-height: 8rem;
  background: #f7f9fa;
  margin-top: 1rem;
  border-left: 8px solid #e8e86b;
  padding: 0 3.5rem;
  font-weight: bold;
  font-size: 2rem;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.official_main_wrap .tournament_list li:first-child {
  margin-top: 0;
}
.official_main_wrap .tournament_list li.type_a {
  border-color: #e8e86b;
}
.official_main_wrap .tournament_list li.type_b {
  border-color: #a1d969;
}
.official_main_wrap .tournament_list li.type_c {
  border-color: #e8a06b;
}
.official_main_wrap .tournament_list li.type_d {
  border-color: #7fb1f5;
}
.official_main_wrap .tournament_list li .title {
  font-size: 1.6rem;
  width: 258px;
  display: inline-block;
}
.official_main_wrap .tournament_list li .name {
  max-width: 50%;
}
.official_main_wrap .tournament_list li .location {
  font-size: 1.6rem;
  font-weight: 400;
  margin-left: auto;
}
.official_main_wrap .tournament_list li .location i {
  display: inline-block;
  margin-left: 1rem;
  font-size: 1.4rem;
  font-weight: 600;
  min-width: 4rem;
  height: 2.2rem;
  line-height: 2rem;
  border: 1px solid #000;
  border-radius: 3px;
  padding: 0 0.5rem;
  text-align: center;
}
.official_main_wrap .tournament_list li .title,
.official_main_wrap .tournament_list li .name {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
@media (max-width: 1000px) {
  .official_main_wrap .tournament_list {
    gap: 1rem;
  }
  .official_main_wrap .tournament_list li {
    padding: 0 2rem;
  }
  .official_main_wrap .tournament_list li .title {
    width: 150px;
  }
}
.official_main_wrap .contents_slider .content {
  padding: 0 10px;
}
.official_main_wrap .contents_slider .content img {
  width: 100%;
  height: 20rem;
  background: lightgray;
}
.official_main_wrap .contents_slider .content span {
  margin-top: 2rem;
  display: block;
  font-size: 1.6rem;
  font-weight: bold;
  color: #3e21d9;
}
.official_main_wrap .contents_slider .content p {
  margin-top: 1rem;
  font-size: 2rem;
  font-weight: 600;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.official_main_wrap .contents_slider :global .slick-prev,
.official_main_wrap .contents_slider :global .slick-next {
  width: 5rem;
  height: 5rem;
  top: 10rem;
}
.official_main_wrap .contents_slider :global .slick-prev::before,
.official_main_wrap .contents_slider :global .slick-next::before {
  display: none;
}
.official_main_wrap .contents_slider :global .slick-prev {
  background: url(../assets/images/btn_default_l.svg) no-repeat;
  left: -7rem !important;
}
.official_main_wrap .contents_slider :global .slick-prev:hover {
  background: url(../assets/images/btn_default_l_b.svg) no-repeat;
}
.official_main_wrap .contents_slider :global .slick-next {
  background: url(../assets/images/btn_default_r.svg) no-repeat;
  right: -7rem !important;
}
.official_main_wrap .contents_slider :global .slick-next:hover {
  background: url(../assets/images/btn_default_r_b.svg) no-repeat;
}
@media (max-width: 1360px) {
  .official_main_wrap .contents_slider :global .slick-prev {
    left: 3rem !important;
    z-index: 1;
    opacity: 0.5;
  }
  .official_main_wrap .contents_slider :global .slick-next {
    right: 3rem !important;
    z-index: 1;
    opacity: 0.5;
  }
}
.official_main_wrap .official_main_slider {
  width: 100%;
  height: 65rem;
}
.official_main_wrap .official_main_slider .main_img {
  width: 100%;
  height: 65rem;
  -o-object-fit: cover;
     object-fit: cover;
}
.official_main_wrap .official_main_slider :global .slick-dots {
  max-width: 1200px;
  left: 50%;
  transform: translateX(-50%);
  text-align: left;
  bottom: 20.4rem;
}
.official_main_wrap .official_main_slider :global .slick-dots li {
  width: 10px;
}
.official_main_wrap .official_main_slider :global .slick-dots li button::before {
  content: "";
  border: 1px solid #fff;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  opacity: 1;
}
.official_main_wrap .official_main_slider :global .slick-dots li.slick-active {
  width: 28px;
}
.official_main_wrap .official_main_slider :global .slick-dots li.slick-active button::before {
  content: "";
  border: 1px solid #fff;
  background: #fff;
  width: 24px;
  height: 8px;
  border-radius: 5px;
}
@media (max-width: 1200px) {
  .official_main_wrap .official_main_slider :global .slick-dots {
    width: 100%;
    padding: 0 2rem;
  }
}

.recommend_product_wrap {
  position: relative;
  overflow: hidden;
  padding-bottom: 0 !important;
}
.recommend_product_wrap .slider_wrap {
  width: 100%;
  position: absolute;
}
.recommend_product_wrap .slider_wrap .contents_wrap {
  width: 100%;
  height: 26rem;
  position: relative;
}
.recommend_product_wrap .slider_wrap .product_slider {
  position: absolute;
  left: 42rem;
  top: 0;
  width: 1200px;
  height: 38rem;
}
.recommend_product_wrap .slider_wrap .product_slider .item {
  cursor: pointer;
  width: 38rem !important;
  height: 38rem;
  padding-right: 3rem;
  position: relative;
  background: #fff;
  border: 1px solid #eee;
}
.recommend_product_wrap .slider_wrap .product_slider .item p {
  position: absolute;
  font-size: 2.4rem;
  font-weight: bold;
  text-transform: uppercase;
  width: 100%;
  padding: 0 3.5rem;
  top: 2.5rem;
  left: 0;
}
.recommend_product_wrap .slider_wrap .product_slider .item p span {
  display: block;
  font-size: 1.2rem;
  font-weight: bold;
  color: #e60013;
  margin-bottom: 4px;
}
.recommend_product_wrap .slider_wrap .product_slider .item img {
  width: 21rem;
  height: 21rem;
  position: absolute;
  bottom: 5rem;
  left: 50%;
  transform: translateX(-50%);
}
.recommend_product_wrap .slider_wrap .product_slider :global .slick-arrow.slick-prev,
.recommend_product_wrap .slider_wrap .product_slider :global .slick-arrow.slick-next {
  background: #000;
  top: 117px;
  left: -419px;
  width: 5rem;
  height: 5rem;
  background: url(../assets/images/btn_default_l.svg) no-repeat;
}
.recommend_product_wrap .slider_wrap .product_slider :global .slick-arrow.slick-prev::before,
.recommend_product_wrap .slider_wrap .product_slider :global .slick-arrow.slick-next::before {
  display: none;
}
.recommend_product_wrap .slider_wrap .product_slider :global .slick-arrow.slick-prev:hover {
  background: url(../assets/images/btn_default_l_b.svg) no-repeat;
}
.recommend_product_wrap .slider_wrap .product_slider :global .slick-arrow.slick-next {
  left: -359px;
  background: url(../assets/images/btn_default_r.svg) no-repeat;
}
.recommend_product_wrap .slider_wrap .product_slider :global .slick-arrow.slick-next:hover {
  background: url(../assets/images/btn_default_r_b.svg) no-repeat;
}
.recommend_product_wrap .category_wrap {
  background: #e60013;
  width: 100%;
  height: 26rem;
  margin-top: 26rem;
}
.recommend_product_wrap .category_wrap ul {
  padding-top: 3.5rem;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
}
.recommend_product_wrap .category_wrap ul li {
  color: #fff;
  font-size: 2rem;
  font-weight: bold;
  height: 3rem;
  line-height: 3rem;
  margin-bottom: 1rem;
  cursor: pointer;
  padding: 0 7px;
  display: inline-block;
  width: auto;
  text-transform: uppercase;
}
.recommend_product_wrap .category_wrap ul li.on {
  background: #000;
}

.kegel_wrap {
  display: flex;
  gap: 3rem;
}
.kegel_wrap div {
  width: 38rem;
  cursor: pointer;
}
.kegel_wrap div img {
  width: 38rem;
  height: 35rem;
}
.kegel_wrap div h3 {
  margin: 2rem 0 1.4rem;
  font-size: 2rem;
}
.kegel_wrap div p {
  line-height: 2.8rem;
}

.catalog_wrap {
  display: flex;
  gap: 2rem;
}
.catalog_wrap > div {
  flex: 1;
  height: 36rem;
  padding: 4rem 5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.catalog_wrap > div h2 {
  color: #fff;
}
.catalog_wrap > div.catalog_box {
  background: url(../assets/images/bg_catalog.png) no-repeat center;
  background-size: cover;
}
.catalog_wrap > div.catalog_box li {
  line-height: 2.4rem;
  margin-top: 2rem;
}
.catalog_wrap > div.faq_box {
  background: #e60013 url(../assets/images/bowling_pin.svg) no-repeat left 327px top 102px;
}

.notice {
  padding-top: 3rem !important;
}
.notice .notice_wrap .list {
  border-top: 3px solid #000;
}
.notice .notice_wrap .list li {
  cursor: pointer;
  height: 8rem;
  line-height: 8rem;
  border-bottom: 1px solid #dedede;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2rem;
  gap: 1rem;
  transition: all 0.3s;
  position: relative;
}
.notice .notice_wrap .list li::after {
  content: "";
  display: none;
  width: 3.4rem;
  height: 3.4rem;
  background: none;
  position: absolute;
  top: 2.3rem;
  right: 2.5rem;
  transition: all 0.3s;
}
.notice .notice_wrap .list li b {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 2rem;
  font-weight: 600;
}
.notice .notice_wrap .list li .date {
  font-size: 1.4rem;
  color: #999;
}
.notice .notice_wrap .list li:hover {
  color: #fff;
  padding-right: 9rem;
  background: #e60013;
}
.notice .notice_wrap .list li:hover::after {
  display: block;
  background: url(../assets/images/btn_default_r_b.svg) no-repeat center;
  background-size: 3.4rem 3.4rem;
}
.notice .notice_wrap .list li:hover .date {
  color: #fff;
}

.sns_article {
  background: #f7f9fa;
  margin: 5rem auto 0 !important;
  padding: 3rem 0 !important;
}
.sns_article .sns_wrap {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 4rem;
}
.sns_article .sns_wrap h3 {
  font-size: 2rem;
  font-weight: 800;
}
.sns_article .sns_wrap ul {
  display: flex;
  align-items: center;
  gap: 2rem;
}
.sns_article .sns_wrap ul li {
  cursor: pointer;
  text-indent: -9999px;
  width: 5rem;
  height: 5rem;
}
.sns_article .sns_wrap ul li a {
  display: block;
  width: 100%;
  height: 100%;
}
.sns_article .sns_wrap ul li.kakao {
  background: url(../assets/images/icon_kakao.png) no-repeat center;
  background-size: 100%;
}
.sns_article .sns_wrap ul li.facebook {
  background: url(../assets/images/icon_facebook.png) no-repeat center;
  background-size: 100%;
}
.sns_article .sns_wrap ul li.official_insta a {
  background: url(../assets/images/official_insta.png) no-repeat center;
  background-size: 100%;
}
.sns_article .sns_wrap ul li.mall_insta a {
  background: url(../assets/images/mall_insta.png) no-repeat center;
  background-size: 100%;
}
.sns_article .sns_wrap ul li.youtube a {
  background: url(../assets/images/icon_youtube.png) no-repeat center;
  background-size: 100%;
}

.page_error_container .page_error {
  width: 40.3rem;
  margin: 30rem auto;
  text-align: center;
}
.page_error_container .page_error h2 {
  padding-top: 11rem;
  background: url(../assets/images/ed_icon_01.svg) no-repeat center top;
}
.page_error_container .page_error h2.error_2 {
  background: url(../assets/images/ed_icon_02.svg) no-repeat center top;
}
.page_error_container .page_error h2.error_3 {
  background: url(../assets/images/ed_icon_03.svg) no-repeat center top;
}
.page_error_container .page_error .info {
  margin: 3rem 0 5rem;
}
.page_error_container .page_error .info b {
  display: block;
  margin-bottom: 1.5rem;
}
.page_error_container .page_error .info p {
  line-height: 2.2rem;
}
.page_error_container .page_error .box {
  width: 100%;
  background: #f7f9fa;
  padding: 2rem 0;
  text-align: center;
}
.page_error_container .page_error .box b {
  display: inline-block;
  margin-right: 2rem;
}
.page_error_container .page_error .btn_wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
@media (max-width: 959px) {
  .page_error_container {
    min-width: initial;
  }
  .page_error_container .page_error {
    max-width: 40.3rem;
    width: 100%;
    padding: 0 1.6rem;
  }
  .page_error_container .page_error .box b {
    display: block;
    margin-bottom: 8px;
  }
}/*# sourceMappingURL=UserHome.module.css.map */