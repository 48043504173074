.checkbox input[type=checkbox] {
  display: none;
}
.checkbox label {
  font-size: 1.4rem;
  padding-left: 2rem;
  line-height: 2rem;
  background: url(../../../assets/images/checkbox_admin_n.svg) no-repeat left center;
  cursor: pointer;
}
.checkbox input[type=checkbox]:checked ~ label {
  background: url(../../../assets/images/checkbox_admin_s.svg) no-repeat left center;
}
.checkbox.admin_st label {
  background: url(../../../assets/images/checkbox_admin_n.svg) no-repeat left center;
  padding-left: 1.7rem;
  background-size: 16px;
}
.checkbox.admin_st input[type=checkbox]:checked ~ label {
  background: url(../../../assets/images/checkbox_admin_s.svg) no-repeat left center;
  background-size: 16px;
}
.checkbox.admin_label label {
  background: url(../../../assets/images/checkbox_admin_n.svg) no-repeat left center;
  padding-left: 2.7rem;
  background-size: 16px;
}
.checkbox.admin_label input[type=checkbox]:checked ~ label {
  background: url(../../../assets/images/checkbox_admin_s.svg) no-repeat left center;
  background-size: 16px;
}
.checkbox.toggle_st {
  width: 4.8rem;
  height: 2.7rem;
  margin: 0 auto;
}
.checkbox.toggle_st label {
  display: block;
  width: 4.8rem;
  height: 2.7rem;
  background: url(../../../assets/images/toggle_n.svg) no-repeat;
  transition: all 0.2s;
}
.checkbox.toggle_st input[type=checkbox]:checked ~ label {
  background: url(../../../assets/images/toggle_s.svg) no-repeat;
}
.checkbox.official_st input[type=checkbox]:checked ~ label {
  background: url(../../../assets/images/checkbox_red_s.svg) no-repeat left center;
  background-size: 16px;
}
.checkbox.white label {
  background: none;
  display: flex;
  position: relative;
}
.checkbox.white label::before {
  content: "";
  width: 1.6rem;
  height: 1.6rem;
  border: 1px solid #fff;
  display: block;
  position: absolute;
  box-sizing: border-box;
  top: 2.4px;
  left: 0;
}
.checkbox.white input[type=checkbox]:checked ~ label {
  background: url(../../../assets/images/check_w_on.svg) no-repeat left center;
  background-size: 16px;
}
.checkbox.white input[type=checkbox]:checked ~ label::before {
  display: none;
}