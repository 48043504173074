@charset "UTF-8";
.my_page_container {
  position: relative;
  width: 100%;
  min-height: calc(100vh - 80px);
  min-width: inherit;
  padding-bottom: 3rem;
  padding-top: 9.2rem;
}
.my_page_container .flex_row {
  display: flex;
  align-items: center;
  gap: 2rem;
}
.my_page_container .flex_row.flex_wrap {
  flex-wrap: wrap;
}
.my_page_container .desc_s {
  font-size: 1.4rem;
  color: #999;
  line-height: 2.2rem;
}
.my_page_container .desc_list {
  font-size: 1.4rem;
  line-height: 2.2rem;
}
.my_page_container .desc_list li {
  white-space: normal;
  padding-left: 8px;
  position: relative;
}
.my_page_container .desc_list li::before {
  content: "";
  display: block;
  width: 3px;
  height: 3px;
  background: #000;
  border-radius: 50%;
  margin-right: 5px;
  position: absolute;
  top: 9px;
  left: 0;
}
.my_page_container .desc_list.gray {
  color: #999;
}
.my_page_container .desc_list.gray li::before {
  background: #999;
}
.my_page_container .desc_list.footer_st {
  margin-top: 3rem;
}
.my_page_container .desc_list.footer_st b {
  color: #000;
  display: block;
  margin-bottom: 1rem;
}
.my_page_container .info_title {
  margin-bottom: 2rem;
  line-height: 2.2rem;
}
.my_page_container .birth_wrap {
  display: flex;
  align-items: center;
  gap: 4px;
}
.my_page_container .birth_wrap span {
  margin-right: 1rem;
}
.my_page_container .birth_wrap > div {
  min-width: 6.8rem;
}
.my_page_container .birth_wrap > div:nth-child(1) {
  min-width: 7.5rem;
}
.my_page_container .phone_agree_wrap > div:nth-child(2) {
  margin-top: 1rem;
}
.my_page_container .checkbox_wrap {
  margin: 1rem 0;
}
.my_page_container .input_col3 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.my_page_container .input_col3 > div {
  width: 28%;
}
.my_page_container .img_file_box {
  display: inline-block;
  margin: 1.5rem 1rem 2rem 0;
}
.my_page_container .img_file_box input[type=file] {
  display: none;
}
.my_page_container .img_file_box label {
  display: inline-block;
  height: 4rem;
  line-height: 3.8rem;
  font-size: 1.6rem;
  min-width: 7.8rem;
  padding: 0 1.7rem;
  color: #000;
  border: 1px solid #000;
  text-align: center;
  border-radius: 3.5rem;
  background: #fff;
  font-weight: 500;
}
.my_page_container .detail_table_wrap {
  margin-top: 2rem;
  padding: 0 1.6rem;
}
.my_page_container .detail_table_wrap h3 {
  margin: 12px 0 8px;
  font-size: 1.6rem;
}
.my_page_container .detail_table_wrap table {
  border-top: 2px solid;
}
.my_page_container .detail_table_wrap table tr {
  cursor: default;
}
.my_page_container .detail_table_wrap table tr th,
.my_page_container .detail_table_wrap table tr td {
  overflow: initial;
  text-overflow: initial;
  padding: 5px 14px;
  height: 4.1rem;
  font-weight: 500;
}
.my_page_container .detail_table_wrap table tr th {
  color: #999;
  font-weight: 400;
  padding: 5px 14px;
}
.my_page_container .detail_table_wrap.mobile_type table colgroup {
  display: none;
}
.my_page_container .detail_table_wrap.mobile_type table tr {
  border-bottom: 1px solid #dedede;
  display: block;
  padding: 1.2rem 0.8rem;
}
.my_page_container .detail_table_wrap.mobile_type table tr th {
  margin-bottom: 5px;
  font-size: 1.2rem;
}
.my_page_container .detail_table_wrap.mobile_type table th,
.my_page_container .detail_table_wrap.mobile_type table td {
  padding: 0;
  display: block;
  border-bottom: none;
  height: inherit;
  line-height: 1.7rem;
  white-space: break-spaces;
  font-weight: 600;
}
.my_page_container .detail_table_wrap.mobile_type .answer_box {
  padding-top: 2.4rem;
  border-bottom: 0;
}
.my_page_container .detail_table_wrap.mobile_type .answer_box th {
  color: #000;
  font-size: 1.6rem;
  font-weight: 600;
  margin-bottom: 8px;
}
.my_page_container .detail_table_wrap.mobile_type .answer_box td {
  border: 1px solid #dedede;
  padding: 1.6rem;
  min-height: 14rem;
}
.my_page_container .detail_table_wrap.mobile_type .table_img_box {
  width: 100%;
  height: auto;
  margin-top: 1.2rem;
}
.my_page_container .detail_table_wrap.mobile_type.edit_st table {
  border-top: 0;
}
.my_page_container .detail_table_wrap.mobile_type.edit_st table tr {
  border-bottom: 0;
}
.my_page_container .detail_table_wrap.mobile_type.edit_st table th {
  color: #000;
}
.my_page_container .detail_table_wrap.mobile_type.edit_st table td {
  font-weight: 400;
}
.my_page_container .pop_mobile_table_wrap table {
  border-top: none;
}
.my_page_container .pop_mobile_table_wrap table colgroup {
  display: none;
}
.my_page_container .pop_mobile_table_wrap table tr {
  cursor: default;
  display: block;
  margin-bottom: 2rem;
}
.my_page_container .pop_mobile_table_wrap table tr th {
  margin-bottom: 5px;
  font-size: 1.2rem;
  font-weight: 600;
}
.my_page_container .pop_mobile_table_wrap table th,
.my_page_container .pop_mobile_table_wrap table td {
  padding: 0 !important;
  display: block;
  border-bottom: none;
  height: inherit !important;
  line-height: 1.7rem;
  white-space: break-spaces;
  overflow: initial;
  text-overflow: initial;
  padding: 5px 14px;
  height: 4.1rem;
}
.my_page_container .radio_wrap {
  display: flex;
  align-items: center;
  gap: 1.8rem 2.5rem;
  flex-wrap: wrap;
}
.my_page_container .phone_wrap {
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: space-between;
}
.my_page_container .phone_wrap > div {
  width: 28%;
}
.my_page_container .address_wrap {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.my_page_container .input_btn_wrap {
  border: 1px solid #dedede;
  display: flex;
  align-items: center;
  height: 4.5rem;
  padding: 0 1.5rem;
}
.my_page_container .input_btn_wrap > div {
  flex: 1;
}
.my_page_container .input_btn_wrap > div input {
  border: none;
  padding: 0;
  background: none;
}
.my_page_container .input_btn_wrap button {
  padding-right: 1.2rem;
  border: 0;
  background: none;
  font-size: 1.4rem;
  text-decoration: underline;
  text-underline-position: under;
}
.my_page_container .input_btn_wrap.readOnly_st input {
  color: #000;
  background: #fff;
}
.my_page_container .email_wrap {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
}
.my_page_container .email_wrap div {
  width: 45%;
}
.my_page_container .email_wrap div:last-child {
  display: block;
  width: 100%;
  margin-top: 1rem;
}
.my_page_container .table_img_box {
  width: 27rem;
  height: 16.8rem;
}
.my_page_container .table_img_box img {
  width: 100%;
  height: 100%;
}
.my_page_container .btn_bottom_wrap {
  margin: 3rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  min-width: initial;
  padding: 0 1rem;
  gap: 8px;
}
.my_page_container .btn_bottom_wrap button {
  flex: 1;
  min-width: 15rem;
}
.my_page_container footer .btn_bottom_wrap {
  margin: 2.4rem 0 1.4rem;
}
.my_page_container .thumbnail_box {
  display: flex;
  align-items: flex-end;
  gap: 1rem;
  margin-bottom: 1rem;
}
.my_page_container .thumbnail_box img {
  width: 14rem;
  height: 8.5rem;
  -o-object-fit: cover;
     object-fit: cover;
}
.my_page_container .mypage_sub_nav {
  padding: 0.8rem 1.6rem 0;
  border-bottom: 1px solid #dedede;
  position: fixed;
  top: 4.3rem;
  left: 0;
  background: #fff;
  z-index: 10;
  width: 100%;
}
.my_page_container .mypage_sub_nav .depth_1_link {
  height: 4.2rem;
}
.my_page_container .mypage_sub_nav .depth_1_link a {
  padding-top: 1.6rem;
  padding: 16px 4px 0;
  margin-right: 1.2rem;
  height: 100%;
  display: block;
  color: #999;
}
.my_page_container .mypage_sub_nav .depth_1_link :global .active {
  color: #000;
  font-weight: bold;
  border-bottom: 2px solid #000;
}
.my_page_container .mypage_sub_nav nav ul {
  display: flex;
  gap: 1.2rem;
  width: 100%;
  position: relative;
}
.my_page_container .mypage_sub_nav nav li {
  height: 4.2rem;
  color: #999;
  padding-top: 1.6rem;
}
.my_page_container .mypage_sub_nav nav li a {
  display: block;
  padding: 0 4px;
  height: 100%;
}
.my_page_container .mypage_sub_nav nav :global .active {
  color: #000;
  font-weight: bold;
  border-bottom: 2px solid #000;
}
.my_page_container .mypage_sub_nav :global .header_sub_nav {
  width: 51rem;
}
.my_page_container section .mypage_contents {
  width: 100%;
  height: 100%;
  position: relative;
}
.my_page_container .mypage_sub_header {
  height: 10.5rem;
  background: #f8fafa;
  border-bottom: 1px solid #dedede;
}
.my_page_container .mypage_sub_header .inner {
  width: 130rem;
  padding: 0 0 0 10rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.my_page_container .mypage_sub_header .user {
  padding: 2.5rem 0;
}
.my_page_container .mypage_sub_header .user p {
  font-size: 2.2rem;
  font-weight: 500;
}
.my_page_container .mypage_sub_header .user .j_id {
  font-size: 2.2rem;
  font-weight: bold;
  margin-top: 8px;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
}
.my_page_container .mypage_sub_header .user .j_id .tooltip {
  margin-left: 2rem;
  width: 2.4rem;
  height: 2.4rem;
  text-indent: -9999px;
  background: url(../../assets/images/icon_tooltip.svg) no-repeat center;
}
.my_page_container .mypage_sub_header .user .j_id .tooltip_content {
  position: absolute;
  top: -44px;
  left: 192px;
  width: 24.8rem;
  height: 11rem;
  background: #fff;
  padding: 2rem;
  color: #000;
  cursor: default;
  display: none;
  transition: all 0.5s;
  box-shadow: 1px 1px 5px 3px rgba(0, 0, 0, 0.03);
}
.my_page_container .mypage_sub_header .user .j_id .tooltip_content b {
  font-size: 1.6rem;
  font-weight: 600;
  margin-bottom: 1rem;
  display: block;
}
.my_page_container .mypage_sub_header .user .j_id .tooltip_content span {
  display: block;
  font-size: 1.4rem;
  line-height: 2rem;
  font-weight: 400;
}
.my_page_container .mypage_sub_header .user .j_id .tooltip_content::before {
  content: "";
  display: block;
  position: absolute;
  width: 1.6rem;
  height: 1.6rem;
  background: #e60013;
  top: -6px;
  right: -6px;
}
.my_page_container .mypage_sub_header .user .j_id .tooltip_content::after {
  content: "";
  display: block;
  position: absolute;
  width: 1.2rem;
  height: 1.2rem;
  background: #fff;
  transform: rotate(45deg);
  top: 50px;
  left: -6px;
}
.my_page_container .mypage_sub_header .user .j_id:hover .tooltip_content {
  display: block;
}
.my_page_container .mypage_sub_header .shortcut {
  height: 10.5rem;
  display: flex;
}
.my_page_container .mypage_sub_header .shortcut li {
  width: 10.5rem;
  height: 100%;
  padding-top: 6.8rem;
  text-align: center;
  font-size: 1.4rem;
  font-weight: bold;
  cursor: pointer;
}
.my_page_container .mypage_sub_header .shortcut li.cs {
  background: url(../../assets/images/icon_cs_40.svg) no-repeat center top 2rem;
}
.my_page_container .mypage_sub_header .shortcut li.as {
  background: url(../../assets/images/icon_as_40.svg) no-repeat center top 2rem;
}
.my_page_container .mypage_sub_header .shortcut li.logout {
  background: url(../../assets/images/icon_logout_40.svg) no-repeat center top 2rem;
}
.my_page_container .contents_wrap {
  width: 100%;
}
.my_page_container .contents_wrap .contents_title h3 {
  font-size: 2rem;
  margin: 24px 0 8px;
  padding: 0 1.6rem;
}
.my_page_container .contents_wrap .contents_title .search_wrap {
  width: 100%;
  position: relative;
}
.my_page_container .contents_wrap .contents_title .search_wrap input {
  height: 5rem;
  padding: 0 5rem 0 2rem;
  background: #f7f9fa;
  background-position: center right 24px;
  border-top: 0;
  border-left: 0;
  border-right: 0;
}
.my_page_container .contents_wrap .contents_title .search_wrap button {
  position: absolute;
  top: 12px;
  right: 24px;
}
.my_page_container .contents_wrap .contents_title .desc {
  width: 100%;
  margin-top: 1rem;
  padding: 0 1.6rem;
  font-size: 1.2rem;
  color: #999;
}
.my_page_container .contents_wrap .contents_title .info {
  padding: 1.6rem 1.6rem 0;
}
.my_page_container .contents_wrap .more_btn {
  margin-top: 2rem;
  padding: 0 1.6rem;
}
.my_page_container .contents_wrap .more_btn button {
  font-size: 1.4rem;
  font-weight: 500;
  width: 100%;
  text-align: center;
  padding-right: 4rem;
  background-position: center right calc(50% - 30px);
  background-size: 1.5rem auto;
}
.my_page_container .contents_wrap .mobile_table_wrap table {
  border-top: none;
}
.my_page_container .contents_wrap .mobile_table_wrap table thead,
.my_page_container .contents_wrap .mobile_table_wrap table colgroup {
  display: none;
}
.my_page_container .contents_wrap .mobile_table_wrap table tr {
  border-bottom: 1px solid #dedede;
  display: block;
  padding: 2rem;
  position: relative;
}
.my_page_container .contents_wrap .mobile_table_wrap table td {
  display: block;
  border-bottom: none;
  padding: 0;
  height: initial;
  line-height: 1.8rem;
}
.my_page_container .contents_wrap .mobile_table_wrap table td:first-child {
  display: none;
}
.my_page_container .contents_wrap .mobile_table_wrap.main_type table td:nth-child(2) {
  font-size: 1.6rem;
  font-weight: 600;
  margin-top: 3rem;
  margin-bottom: 1.2rem;
}
.my_page_container .contents_wrap .mobile_table_wrap.main_type table td:nth-child(5) {
  position: absolute;
  top: 2.2rem;
  left: 2rem;
  font-size: 1.2rem;
  color: #999;
}
.my_page_container .contents_wrap .mobile_table_wrap.main_type table td:nth-child(5)::after {
  display: inline-block;
  content: "접수";
  padding-left: 5px;
}
.my_page_container .contents_wrap .mobile_table_wrap.main_type table td:nth-child(6) {
  position: absolute;
  top: 2rem;
  right: 2rem;
  font-size: 1.2rem;
  border: 1px solid;
  height: 1.9rem;
  line-height: 1.7rem;
  padding: 0 4px;
  border-radius: 4px;
}
.my_page_container .contents_wrap .mobile_table_wrap.main_type table td:nth-child(7) span {
  color: #999;
  padding-right: 1rem;
}
.my_page_container .contents_wrap .mobile_table_wrap.main_type table td:nth-child(8) {
  margin-top: 1.2rem;
}
.my_page_container .contents_wrap .mobile_table_wrap.event_type table td:nth-child(2) {
  font-size: 1.6rem;
  font-weight: 600;
  margin-top: 3rem;
  margin-bottom: 1.2rem;
}
.my_page_container .contents_wrap .mobile_table_wrap.event_type table td:nth-child(3)::before {
  display: inline-block;
  content: "대회기간";
  padding-right: 5px;
}
.my_page_container .contents_wrap .mobile_table_wrap.event_type table td:nth-child(4) {
  position: absolute;
  top: 2.2rem;
  left: 2rem;
  font-size: 1.2rem;
  color: #999;
}
.my_page_container .contents_wrap .mobile_table_wrap.event_type table td:nth-child(4)::after {
  display: inline-block;
  content: "접수";
  padding-left: 5px;
}
.my_page_container .contents_wrap .mobile_table_wrap.event_type table td:nth-child(5) {
  position: absolute;
  top: 2rem;
  right: 2rem;
  font-size: 1.2rem;
  border: 1px solid;
  height: 1.9rem;
  line-height: 1.7rem;
  padding: 0 4px;
  border-radius: 4px;
}
.my_page_container .contents_wrap .mobile_table_wrap.event_type table td:nth-child(6) {
  margin-top: 1.2rem;
}
.my_page_container .contents_wrap .mobile_table_wrap.cs_type table tr {
  padding: 2rem 2.4rem;
}
.my_page_container .contents_wrap .mobile_table_wrap.cs_type table tr.waiting_answer {
  background: #f7f9fa;
}
.my_page_container .contents_wrap .mobile_table_wrap.cs_type table tr.waiting_answer td {
  color: #000 !important;
}
.my_page_container .contents_wrap .mobile_table_wrap.cs_type table tr.waiting_answer td:nth-child(2) {
  font-weight: 600;
}
.my_page_container .contents_wrap .mobile_table_wrap.cs_type table td {
  font-size: 1.2rem;
}
.my_page_container .contents_wrap .mobile_table_wrap.cs_type table td:nth-child(2) {
  font-size: 1.6rem;
  font-weight: 500;
  white-space: break-spaces;
  margin-bottom: 8px;
  color: #999;
}
.my_page_container .contents_wrap .mobile_table_wrap.cs_type table td:nth-child(3), .my_page_container .contents_wrap .mobile_table_wrap.cs_type table td:nth-child(4) {
  display: inline-block;
  color: #999;
}
.my_page_container .contents_wrap .mobile_table_wrap.cs_type table td:nth-child(3) {
  font-weight: 500;
  padding-right: 1.7rem;
  position: relative;
}
.my_page_container .contents_wrap .mobile_table_wrap.cs_type table td:nth-child(3)::after {
  content: "";
  position: absolute;
  top: 3px;
  right: 8px;
  width: 1px;
  height: 10px;
  background: #dedede;
}
.my_page_container .contents_wrap .mobile_table_wrap.as_type table tr {
  padding: 2rem 2.4rem;
}
.my_page_container .contents_wrap .mobile_table_wrap.as_type table tr.waiting_answer td {
  color: #000 !important;
}
.my_page_container .contents_wrap .mobile_table_wrap.as_type table tr.waiting_answer td:nth-child(2) {
  font-weight: 600;
}
.my_page_container .contents_wrap .mobile_table_wrap.as_type table td {
  font-size: 1.2rem;
}
.my_page_container .contents_wrap .mobile_table_wrap.as_type table td:nth-child(2) {
  font-size: 1.6rem;
  font-weight: 500;
  white-space: break-spaces;
  margin-bottom: 8px;
  color: #999;
}
.my_page_container .contents_wrap .mobile_table_wrap.as_type table td:nth-child(3), .my_page_container .contents_wrap .mobile_table_wrap.as_type table td:nth-child(4) {
  display: inline-block;
  color: #999;
}
.my_page_container .contents_wrap .mobile_table_wrap.as_type table td:nth-child(3) {
  font-weight: 500;
  padding-right: 1.7rem;
  position: relative;
}
.my_page_container .contents_wrap .mobile_table_wrap.as_type table td:nth-child(3)::after {
  content: "";
  position: absolute;
  top: 3px;
  right: 8px;
  width: 1px;
  height: 10px;
  background: #dedede;
}
.my_page_container .password_wrap {
  padding: 1.6rem;
}
.my_page_container .password_wrap p {
  font-weight: bold;
  font-size: 1.4rem;
  margin: 15px 0 5px 0;
  text-align: left;
}
.my_page_container .password_wrap button {
  width: 100%;
  margin-top: 6rem;
}
.my_page_container .empty_data {
  padding: 13rem 0 24rem 0;
  text-align: center;
}
.my_page_container .empty_data p {
  padding: 8.4rem 0 1.6rem;
  font-size: 2rem;
  font-weight: bold;
  background: url(../../assets/images/ed_icon_07.svg) no-repeat center top;
  background-size: 6.4rem auto;
}
.my_page_container .empty_data.event_st p {
  background: url(../../assets/images/ed_icon_08.svg) no-repeat center top;
  background-size: 6.4rem auto;
}
.my_page_container .empty_data.cs_st p {
  background: url(../../assets/images/ed_icon_09.svg) no-repeat center top;
  background-size: 6.4rem auto;
}
.my_page_container .empty_data.as_st p {
  background: url(../../assets/images/ed_icon_10.svg) no-repeat center top;
  background-size: 6.4rem auto;
}
.my_page_container .tab_menu {
  width: 100%;
  display: flex;
}
.my_page_container .tab_menu li {
  width: 50%;
  height: 5rem;
  line-height: 5rem;
  border-bottom: 1px solid #dedede;
  color: #999;
  font-weight: 500;
  text-align: center;
  cursor: pointer;
}
.my_page_container .tab_menu li.on {
  background: #e60013;
  color: #fff;
  font-weight: bold;
  border-bottom: 1px solid #e60013;
}

.use_ball_td span {
  display: block;
  white-space: break-spaces;
  margin-bottom: 0.4rem;
}

.register_ball_table_wrap > button {
  display: block;
  margin-left: auto;
  margin-bottom: 1rem;
  margin-top: 2rem;
}
.register_ball_table_wrap .pop_mobile_table_wrap table thead {
  display: none;
}
.register_ball_table_wrap .pop_mobile_table_wrap table .flex_row {
  gap: 1rem;
  align-items: center;
}
.register_ball_table_wrap .pop_mobile_table_wrap .serial_num {
  display: flex;
  gap: 0.4rem;
}
.register_ball_table_wrap .pop_mobile_table_wrap .serial_num > div {
  flex: 1;
  min-width: initial;
}
.register_ball_table_wrap .pop_mobile_table_wrap table tr {
  position: relative;
  border-bottom: 1px solid #dedede;
  padding: 2rem 0;
  margin-bottom: 0;
  line-height: 2.1rem;
}
.register_ball_table_wrap .pop_mobile_table_wrap table tr td {
  line-height: 2rem;
}
.register_ball_table_wrap .pop_mobile_table_wrap table tr td:nth-child(4) {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  width: 5rem;
  text-align: center;
}
.register_ball_table_wrap .pop_mobile_table_wrap table tr td:nth-child(5) {
  margin-top: 1rem;
}
.register_ball_table_wrap .pop_mobile_table_wrap table tr td[colspan] {
  position: initial;
  text-align: center;
  transform: initial;
}
.register_ball_table_wrap .pop_mobile_table_wrap table tr:last-child {
  border-bottom: 0;
}
.register_ball_table_wrap .pop_mobile_table_wrap table tr.register_ball {
  padding: 2rem 0;
}
.register_ball_table_wrap .pop_mobile_table_wrap table tr.register_ball td {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 0.4rem;
}
.register_ball_table_wrap .pop_mobile_table_wrap table tr.register_ball td > div {
  flex: 1;
}
.register_ball_table_wrap .pop_mobile_table_wrap table tr.register_ball td::before {
  content: "";
  display: block;
  width: 8rem;
}
.register_ball_table_wrap .pop_mobile_table_wrap table tr.register_ball td:nth-child(1)::before {
  content: "브랜드";
}
.register_ball_table_wrap .pop_mobile_table_wrap table tr.register_ball td:nth-child(2)::before {
  content: "모델명";
}
.register_ball_table_wrap .pop_mobile_table_wrap table tr.register_ball td:nth-child(3)::before {
  content: "시리얼넘버";
}
.register_ball_table_wrap .pop_mobile_table_wrap table tr.register_ball td:nth-child(4) {
  display: none;
}
.register_ball_table_wrap .pop_mobile_table_wrap table tr.register_ball td:nth-child(5) {
  position: relative;
  margin-top: 1rem;
  transform: initial;
  width: 100%;
  justify-content: flex-end;
}