.official_main_wrap {
  width: 100%;
  min-width: initial;
  padding-top: 0;
}
.official_main_wrap article {
  padding: 6rem 0;
  margin: 0 auto;
}
.official_main_wrap article h2 {
  font-size: 2rem;
  font-weight: 800;
  margin-bottom: 1.2rem;
  display: flex;
  justify-content: space-between;
}
.official_main_wrap article.dark {
  background: #000;
  color: #fff;
}
.official_main_wrap article.dark .contents_wrap {
  gap: 4rem;
}
.official_main_wrap article.blue {
  background: #f7f9fa;
}
.official_main_wrap .contents_wrap {
  margin: 0 auto;
}
.official_main_wrap .band_banner {
  width: 100%;
  /* height: 8rem; */
  padding: 1.4rem 0;
  background: #3e21d9;
  color: #fff;
  text-align: center;
  /* margin-top: 6rem; */
}
.official_main_wrap .band_banner h4 {
  display: flex;
  align-items: center;
  font-size: 1.8rem;
  font-weight: 600;
  cursor: pointer;
  justify-content: center;
  margin-bottom: 1rem;
}
.official_main_wrap .band_banner h4::before {
  display: inline-block;
  content: "";
  width: 2rem;
  height: 2rem;
  background: url(../assets/images/icon_tournament_cup.svg) no-repeat center;
  background-size: 2rem auto;
  padding-right: 0.5rem;
}
.official_main_wrap .band_banner p {
  display: inline-block;
  padding: 0 1rem;
  height: 2.5rem;
  border-radius: 1.3rem;
  border: 1px solid #fff;
  line-height: 2.3rem;
  font-size: 1.4rem;
  font-weight: 500;
  cursor: pointer;
}
.official_main_wrap .official_main {
  width: 100%;
  aspect-ratio: 36/53;
  position: relative;
}
.official_main_wrap .official_main .visual_wrap {
  padding: 0;
  width: 100%;
  height: auto;
}
.official_main_wrap .official_main .visual_wrap .main_img img {
  width: 100%;
  height: auto;
  -o-object-fit: cover;
     object-fit: cover;
}
.official_main_wrap .tournament_list_wrap h2 {
  padding: 0 1.6rem;
}
.official_main_wrap .tournament_list_wrap .btn_center {
  padding: 0 1.6rem;
}
.official_main_wrap .tournament_list_wrap .btn_center button {
  width: 100%;
}
.official_main_wrap .tournament_list {
  border-top: 1px solid #dedede;
  margin-bottom: 2.4rem;
}
.official_main_wrap .tournament_list li {
  min-height: 12.3rem;
  padding: 2.4rem 1.6rem;
  cursor: pointer;
  border-bottom: 1px solid #dedede;
}
.official_main_wrap .tournament_list li span {
  display: block;
  width: 100%;
}
.official_main_wrap .tournament_list li.type_a .title {
  color: #cbb300;
}
.official_main_wrap .tournament_list li.type_b .title {
  color: #45b752;
}
.official_main_wrap .tournament_list li.type_c .title {
  color: #d37a39;
}
.official_main_wrap .tournament_list li.type_d .title {
  color: #528ee0;
}
.official_main_wrap .tournament_list li .title {
  font-weight: 500;
}
.official_main_wrap .tournament_list li .name {
  margin: 12px 0 6px;
  font-size: 1.6rem;
  font-weight: 600;
}
.official_main_wrap .tournament_list li .location i {
  display: inline-block;
  margin-right: 1rem;
  font-size: 1.1 0.6rem;
  min-width: 4rem;
  height: 2.1 0.6rem;
  line-height: 1.6rem;
  border: 1px solid #000;
  border-radius: 3px;
  padding: 0 0.5rem;
  text-align: center;
}
.official_main_wrap .tournament_list li .title,
.official_main_wrap .tournament_list li .name {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.official_main_wrap .result_slider_wrap .contents_wrap {
  position: relative;
  overflow: hidden;
  padding-bottom: 22.2rem;
}
.official_main_wrap .result_slider_wrap .contents_wrap h2 {
  padding: 0 1.6rem;
}
.official_main_wrap .result_slider_wrap .contents_wrap h2 button {
  text-indent: -9999px;
  width: 3.4rem;
  height: 3.4rem;
  background: url(../assets/images/add_circle_b.svg) no-repeat center;
  background-size: 2.4rem;
}
.official_main_wrap .contents_slider {
  width: 1040px;
  position: absolute;
  left: 50%;
  transform: translate(-50%);
}
.official_main_wrap .contents_slider .content {
  padding: 0 10px;
}
.official_main_wrap .contents_slider .content img {
  width: 100%;
  height: 16.8rem;
}
.official_main_wrap .contents_slider .content span {
  margin-top: 1.2rem;
  display: block;
  font-size: 1.2rem;
  font-weight: bold;
  color: #3e21d9;
}
.official_main_wrap .contents_slider .content p {
  margin-top: 4px;
  font-size: 1.6rem;
  font-weight: 600;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
@media (max-width: 1100px) {
  .official_main_wrap .contents_slider {
    left: 1.6rem;
    transform: translateX(0);
  }
}
.official_main_wrap .official_main_slider {
  width: 100%;
  height: 100%;
}
.official_main_wrap .official_main_slider .main_img {
  width: 100%;
  height: auto;
  -o-object-fit: cover;
     object-fit: cover;
}
.official_main_wrap .official_main_slider :global .slick-dots {
  max-width: 1200px;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  bottom: 0.5rem;
}
.official_main_wrap .official_main_slider :global .slick-dots li {
  width: 10px;
}
.official_main_wrap .official_main_slider :global .slick-dots li button::before {
  content: "";
  border: 1px solid #fff;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  opacity: 1;
}
.official_main_wrap .official_main_slider :global .slick-dots li.slick-active {
  width: 28px;
}
.official_main_wrap .official_main_slider :global .slick-dots li.slick-active button::before {
  content: "";
  border: 1px solid #fff;
  background: #fff;
  width: 24px;
  height: 8px;
  border-radius: 5px;
}

.recommend_product_wrap .slider_wrap {
  width: 100%;
}
.recommend_product_wrap .slider_wrap .contents_wrap {
  width: 100%;
  position: relative;
  padding-bottom: 21rem;
  position: relative;
  overflow: hidden;
}
.recommend_product_wrap .slider_wrap h2 {
  padding: 0 2rem;
}
.recommend_product_wrap .slider_wrap .product_slider {
  width: 662px;
  height: 21rem;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.recommend_product_wrap .slider_wrap .product_slider .item {
  width: 21rem !important;
  height: 21rem;
  position: relative;
  border: 1px solid #eee;
}
.recommend_product_wrap .slider_wrap .product_slider .item p {
  position: absolute;
  font-size: 1.6rem;
  font-weight: 600;
  text-transform: uppercase;
  width: 100%;
  bottom: 1rem;
  left: 0;
  padding: 0 5px;
  text-align: center;
}
.recommend_product_wrap .slider_wrap .product_slider .item p span {
  display: block;
  font-size: 1.2rem;
  font-weight: bold;
  color: #e60013;
}
.recommend_product_wrap .slider_wrap .product_slider .item img {
  width: 13rem;
  height: 15.8rem;
  -o-object-fit: contain;
     object-fit: contain;
  margin: 0 auto;
}
@media (max-width: 660px) {
  .recommend_product_wrap .slider_wrap .product_slider {
    left: 1.6rem;
    transform: translateX(0);
  }
}
.recommend_product_wrap .category_wrap {
  width: 100%;
  border-bottom: 2px solid #dedede;
  margin-bottom: 1.6rem;
  padding: 0 1.6rem;
}
.recommend_product_wrap .category_wrap ul {
  display: flex;
  align-items: flex-start;
}
.recommend_product_wrap .category_wrap ul li {
  flex: 1;
  text-align: center;
  color: #999;
  font-size: 1.4rem;
  height: 4.2rem;
  line-height: 4rem;
  cursor: pointer;
  display: inline-block;
  width: auto;
  text-transform: uppercase;
  position: relative;
}
.recommend_product_wrap .category_wrap ul li.on {
  color: #000;
  font-weight: 600;
}
.recommend_product_wrap .category_wrap ul li.on::before {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 2px;
  background: #000;
  bottom: -2px;
  left: 0;
}

.kegel_slide_wrap {
  position: relative;
  padding: 6rem 1.6rem 44rem !important;
  overflow: hidden;
}
.kegel_slide_wrap .kegel_wrap {
  width: 1000px;
  position: absolute;
  left: 1.6rem;
  transform: translateX(0);
}
.kegel_slide_wrap .kegel_wrap .item {
  width: 26rem;
  padding: 0 5px;
  cursor: pointer;
}
.kegel_slide_wrap .kegel_wrap .item img {
  width: 100%;
  height: auto;
  max-height: 32rem;
}
.kegel_slide_wrap .kegel_wrap .item h3 {
  margin: 1.2rem 0 0.4rem;
  font-size: 1.6rem;
}
.kegel_slide_wrap .kegel_wrap .item p {
  line-height: 2.2rem;
}
@media (max-width: 790px) {
  .kegel_slide_wrap .kegel_wrap {
    width: 790px;
    position: absolute;
    left: 1.6rem;
    transform: translateX(0);
  }
  .kegel_slide_wrap .kegel_wrap .item {
    width: 38rem;
  }
  .kegel_slide_wrap .kegel_wrap .item img {
    width: 38rem;
    height: auto;
  }
  .kegel_slide_wrap .kegel_wrap .item h3 {
    margin: 1.2rem 0 0.4rem;
    font-size: 1.6rem;
  }
  .kegel_slide_wrap .kegel_wrap .item p {
    line-height: 2.2rem;
  }
}
@media (max-width: 600px) {
  .kegel_slide_wrap .kegel_wrap {
    width: 660px;
    position: absolute;
    left: 1.6rem;
  }
  .kegel_slide_wrap .kegel_wrap .item {
    width: 32.8rem;
  }
  .kegel_slide_wrap .kegel_wrap .item img {
    width: 32.8rem;
    height: auto;
  }
  .kegel_slide_wrap .kegel_wrap .item h3 {
    margin: 1.2rem 0 0.4rem;
    font-size: 1.6rem;
  }
  .kegel_slide_wrap .kegel_wrap .item p {
    line-height: 2.2rem;
  }
}
@media (max-width: 400px) {
  .kegel_slide_wrap .kegel_wrap {
    width: 610px;
  }
  .kegel_slide_wrap .kegel_wrap .item {
    width: 30rem;
  }
  .kegel_slide_wrap .kegel_wrap .item img {
    width: 30rem;
    height: auto;
  }
}
@media (max-width: 320px) {
  .kegel_slide_wrap .kegel_wrap {
    width: 410px;
  }
  .kegel_slide_wrap .kegel_wrap .item {
    width: 20rem;
  }
  .kegel_slide_wrap .kegel_wrap .item img {
    width: 25rem;
    height: auto;
  }
}

.catalog_wrap {
  padding: 0 1.6rem;
}
.catalog_wrap .catalog_box {
  margin-bottom: 3rem;
}
.catalog_wrap .catalog_box ul {
  width: 100%;
  height: 20rem;
  padding: 4.2rem 2rem;
  background: url(../assets/images/bg_catalog.png) no-repeat center;
  background-size: cover;
}
.catalog_wrap .catalog_box li {
  line-height: 2.4rem;
  margin-bottom: 2.3rem;
}
.catalog_wrap .catalog_box button {
  font-size: 1.6rem !important;
  background-size: 1.5rem 1.5rem !important;
  padding-right: 2rem !important;
}
.catalog_wrap .faq_box div {
  width: 100%;
  height: 20rem;
  padding: 2.4rem 2rem;
  background: #e60013 url(../assets/images/bowling_pin.svg) no-repeat right 2rem top 3rem;
  background-size: 14.8rem 14.8rem;
}
.catalog_wrap .faq_box div button {
  font-size: 1.6rem !important;
  background-size: 1.5rem 1.5rem !important;
  padding-right: 2rem !important;
}

.notice {
  padding: 0 1.6rem 6rem !important;
}
.notice .notice_wrap h2 button {
  text-indent: -9999px;
  width: 3.4rem;
  height: 3.4rem;
  background: url(../assets/images/add_circle_b.svg) no-repeat center;
  background-size: 2.4rem;
  min-width: initial;
  padding: 0;
  border: none;
}
.notice .notice_wrap .list {
  border-top: 3px solid #000;
}
.notice .notice_wrap .list li {
  border-bottom: 1px solid #dedede;
  padding: 20px 4px;
}
.notice .notice_wrap .list li b {
  display: block;
  width: 100%;
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 1.6rem;
  font-weight: 500;
  margin-bottom: 4px;
}
.notice .notice_wrap .list li .date {
  display: block;
  width: 100%;
  font-size: 1.2rem;
  color: #999;
}

.sns_article {
  background: #f7f9fa;
  padding: 3rem 0 !important;
}
.sns_article .sns_wrap {
  padding: 0 1.6rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 4rem;
}
.sns_article .sns_wrap h3 {
  font-size: 1.6rem;
  font-weight: 800;
}
.sns_article .sns_wrap ul {
  display: flex;
  align-items: center;
  gap: 1.6rem;
}
.sns_article .sns_wrap ul li {
  text-indent: -9999px;
  width: 5rem;
  height: 5rem;
}
.sns_article .sns_wrap ul li a {
  display: block;
  width: 100%;
  height: 100%;
}
.sns_article .sns_wrap ul li.kakao {
  background: url(../assets/images/icon_kakao.png) no-repeat center;
  background-size: 100%;
}
.sns_article .sns_wrap ul li.facebook {
  background: url(../assets/images/icon_facebook.png) no-repeat center;
  background-size: 100%;
}
.sns_article .sns_wrap ul li.official_insta a {
  background: url(../assets/images/official_insta.png) no-repeat center;
  background-size: 100%;
}
.sns_article .sns_wrap ul li.mall_insta a {
  background: url(../assets/images/mall_insta.png) no-repeat center;
  background-size: 100%;
}
.sns_article .sns_wrap ul li.youtube a {
  background: url(../assets/images/icon_youtube.png) no-repeat center;
  background-size: 100%;
}
@media (max-width: 340px) {
  .sns_article .sns_wrap h3 {
    display: none;
  }
}

.page_error {
  width: 40.3rem;
  margin: 30rem auto;
  text-align: center;
}
.page_error h2 {
  padding-top: 11rem;
  background: url(../assets/images/ed_icon_01.svg) no-repeat center top;
}
.page_error h2.error_2 {
  background: url(../assets/images/ed_icon_02.svg) no-repeat center top;
}
.page_error h2.error_3 {
  background: url(../assets/images/ed_icon_03.svg) no-repeat center top;
}
.page_error .info {
  margin: 3rem 0 5rem;
}
.page_error .info b {
  display: block;
  margin-bottom: 1.5rem;
}
.page_error .info p {
  line-height: 2.1 0.6rem;
}
.page_error .box {
  width: 100%;
  background: #f7f9fa;
  padding: 1.6rem 0;
  text-align: center;
}
.page_error .box b {
  display: inline-block;
  margin-right: 1.6rem;
}
.page_error .btn_wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}