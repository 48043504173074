.admin_sign_wrap {
  width: 100%;
  height: 100vh;
  max-width: 1920px;
  margin: 0 auto;
  background: #f0f2f4;
  font-size: 10px;
  position: relative;
}
.admin_sign_wrap .sign_in_wrap {
  width: 69rem;
  height: 35rem;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.admin_sign_wrap .sign_in_wrap .sign_in_visual {
  width: 40rem;
  height: 100%;
  background: url(../../../assets/images/kv_01.jpg) no-repeat;
  background-size: cover;
  border-radius: 1.5rem 0 0 1.5rem;
  overflow: hidden;
  padding: 3.2rem 0 0 2.6rem;
}
.admin_sign_wrap .sign_in_wrap .sign_in_visual h1 {
  color: #fff;
  font-size: 2rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 1rem;
}
.admin_sign_wrap .sign_in_wrap .sign_in_visual h1 .logo {
  text-indent: -9999px;
  font-size: 0;
  width: 3.1rem;
  height: 3.6rem;
  display: inline-block;
  background: url(../../../assets/images/logo_admin_sm.png) no-repeat;
  background-size: 100%;
}
.admin_sign_wrap .sign_in_wrap .sign_in_bx {
  width: 35rem;
  height: 100%;
  background: #fff;
  border-radius: 1.5rem;
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  padding: 6rem 5rem;
}
.admin_sign_wrap .sign_in_wrap .sign_in_bx form label {
  font-size: 1.4rem;
  font-weight: bold;
  display: block;
  margin-bottom: 0.6rem;
}
.admin_sign_wrap .sign_in_wrap .sign_in_bx form input {
  margin-bottom: 1.5rem;
}
.admin_sign_wrap .sign_in_wrap .sign_in_bx form button {
  display: block;
  margin: 2rem auto 0;
  width: auto;
}
.admin_sign_wrap footer {
  color: #999;
  font-size: 1.4rem;
  margin: 0 auto;
  padding-bottom: 2rem;
  text-align: center;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}