.accordion_warp .accordion {
  background: #f7f9fa;
  padding: 0 4rem;
  margin-bottom: 1.5rem;
  transition: all 0.3s ease;
}
.accordion_warp .accordion h3 {
  padding: 3.5rem 6rem 3.5rem 4rem;
  font-size: 2rem;
  font-weight: 500;
  cursor: pointer;
  position: relative;
}
.accordion_warp .accordion h3::before {
  content: "Q.";
  position: absolute;
  display: block;
  font-weight: bold;
  font-size: 2.4rem;
  top: 3rem;
  left: 0;
}
.accordion_warp .accordion h3::after {
  content: "";
  display: block;
  position: absolute;
  width: 3rem;
  height: 3rem;
  background: url(../../../assets/images/icon_accordion_explosed.svg) no-repeat center;
  top: 3.5rem;
  right: 0rem;
  transition: all 0.2s ease;
}
.accordion_warp .accordion .answer {
  font-size: 1.6rem;
  height: 0;
  overflow: hidden;
  transition: all 0.2s ease;
  padding: 0 0 0 4rem;
  position: relative;
}
.accordion_warp .accordion .answer::before {
  content: "A.";
  display: inline-block;
  font-weight: bold;
  margin-right: 1.5rem;
  font-size: 2.4rem;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.2s ease;
}
.accordion_warp .accordion .answer p {
  word-break: break-all;
}
.accordion_warp .accordion .answer.show {
  height: auto;
  padding: 3.5rem 0 3.5rem 4rem;
  border-top: 1px dashed #999999;
}
.accordion_warp .accordion .answer.show::before {
  top: 3rem;
}
.accordion_warp .accordion.expanded h3 {
  font-weight: bold;
}
.accordion_warp .accordion.expanded h3::after {
  transform: rotate(180deg);
}
.accordion_warp .accordion em {
  color: #e60013;
}
@media (max-width: 959px) {
  .accordion_warp .accordion {
    padding: 0 2.4rem 0 1.6rem;
  }
  .accordion_warp .accordion h3 {
    font-size: 1.6rem;
    font-weight: 500 !important;
    padding: 2rem 4rem 2rem 2.6rem;
  }
  .accordion_warp .accordion h3::before {
    font-size: 1.8rem;
    top: 2rem;
  }
  .accordion_warp .accordion h3::after {
    top: 50%;
    transform: translateY(-50%);
    background-size: 2rem auto;
  }
  .accordion_warp .accordion .answer {
    font-size: 1.6rem;
    padding: 0 0 0 2.6rem;
    font-weight: 600;
  }
  .accordion_warp .accordion .answer::before {
    font-size: 1.8rem;
  }
  .accordion_warp .accordion .answer.show {
    border-top: 1px dashed #dedede;
    padding: 2rem 0 2rem 2.6rem;
  }
  .accordion_warp .accordion .answer.show::before {
    top: 2rem;
  }
  .accordion_warp .accordion.expanded h3::after {
    transform: rotate(180deg) translateY(50%);
  }
}
.accordion_warp.main_faq .accordion {
  background: #f7f9fa;
  padding: 0;
  margin-bottom: 1rem;
}
.accordion_warp.main_faq .accordion h3 {
  padding: 2.2rem 2.5rem;
  font-size: 1.6rem;
  font-weight: 500;
  cursor: pointer;
  position: relative;
}
.accordion_warp.main_faq .accordion h3 span {
  display: inline-block;
  width: 12rem;
}
.accordion_warp.main_faq .accordion h3::before {
  display: none;
}
.accordion_warp.main_faq .accordion h3::after {
  content: "";
  display: block;
  position: absolute;
  width: 2.3rem;
  height: 2.3rem;
  background: url(../../../assets/images/circle_arrow_b.svg) no-repeat center;
  top: 1.9rem;
  right: 2.5rem;
  transition: all 0.2s ease;
}
.accordion_warp.main_faq .accordion .answer {
  font-size: 1.6rem;
  height: 0;
  overflow: hidden;
  transition: all 0.3s ease;
  padding: 0 2.5rem 0 14.5rem;
}
.accordion_warp.main_faq .accordion .answer::before {
  display: none;
}
.accordion_warp.main_faq .accordion .answer.show {
  height: auto;
  padding: 3rem 2.5rem 3rem 14.5rem;
  border-top: 1px dashed #707070;
}
.accordion_warp.main_faq .accordion.expanded h3 {
  font-weight: bold;
}
.accordion_warp.main_faq .accordion.expanded h3::after {
  transform: rotate(180deg);
}
@media (max-width: 959px) {
  .accordion_warp.main_faq .accordion {
    margin-bottom: 0.8rem;
  }
  .accordion_warp.main_faq .accordion h3 {
    padding: 2rem 4rem 2rem 1.6rem;
    font-weight: 600 !important;
  }
  .accordion_warp.main_faq .accordion h3 span {
    display: none;
  }
  .accordion_warp.main_faq .accordion h3::before {
    display: none;
  }
  .accordion_warp.main_faq .accordion h3::after {
    content: "";
    display: block;
    position: absolute;
    width: 2.3rem;
    height: 2.3rem;
    background: url(../../../assets/images/circle_arrow_b.svg) no-repeat center;
    top: 50%;
    transform: translateY(-50%);
    right: 1.6rem;
    transition: all 0.2s ease;
  }
  .accordion_warp.main_faq .accordion .answer {
    font-size: 1.6rem;
    height: 0;
    overflow: hidden;
    transition: all 0.3s ease;
    padding: 0 1.6rem;
    font-weight: 400;
  }
  .accordion_warp.main_faq .accordion .answer::before {
    display: none;
  }
  .accordion_warp.main_faq .accordion .answer.show {
    height: auto;
    padding: 2rem 1.6rem;
    border-top: 1px dashed #707070;
  }
  .accordion_warp.main_faq .accordion.expanded h3 {
    font-weight: bold;
  }
  .accordion_warp.main_faq .accordion.expanded h3::after {
    transform: translateY(-50%) rotate(180deg);
  }
}