.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 100;
  /* @media (max-width: 300px) {
      section {
          .modal_body {
              main {
                  max-height: calc(100vh - 100px);
                  overflow-y: auto;
              }
          }
      }
  } */
}
.modal section {
  width: 700px;
  min-height: 500px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  margin: 0 !important;
  padding: 0;
}
.modal section header {
  height: 5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #000;
  padding: 0 4rem;
}
.modal section header h2 {
  color: #fff;
  font-size: 2rem;
  font-weight: bold;
}
.modal section .modal_body {
  padding: 4rem 2rem 4rem 4rem;
}
.modal section .modal_body h3 {
  text-align: center;
  font-size: 2rem;
}
.modal section .modal_body table tr {
  cursor: default;
}
.modal section .modal_body table tr th,
.modal section .modal_body table tr td {
  height: 4.9rem;
}
.modal section .modal_body :global .scroll_bx,
.modal section .modal_body :global .pd_2 {
  padding-right: 2rem;
}
.modal section footer {
  text-align: center;
  display: flex;
  justify-content: center;
  gap: 0.5rem;
}
.modal.popupModal {
  background: none;
  width: auto;
  height: auto;
}
.modal.popupModal section {
  transform: translate(0, 0);
  top: 0;
  left: 0;
  background: #fff;
  width: auto;
}
.modal.popupModal section header {
  display: flex;
  justify-content: flex-end;
  padding: 0 2rem;
}
.modal.popupModal section header button {
  background: rgba(0, 0, 0, 0.1) url(../../../assets/images/pop_close_16_w.svg) no-repeat center;
}
.modal.popupModal section .modal_body {
  background: #fff;
  padding: 0;
}
.modal.popupModal section .modal_body img {
  width: 100%;
}
@media (max-width: 600px) {
  .modal.popupModal section {
    min-height: initial;
  }
}
.modal.none_title header {
  justify-content: flex-end;
  background: none;
  border-top: 10px solid #000;
  padding: 0 1.5rem;
}
.modal.none_title header h2 {
  display: none;
}
.modal.none_title header button {
  background: url(../../../assets/images/pop_close_16_b.svg) no-repeat center;
}
.modal.modal_body_scroll .modal_body {
  max-height: calc(90vh - 50px);
  overflow-y: auto;
}
.modal.admin_st section {
  border-radius: 1.5rem;
  padding-left: 0;
  background: #3e21d9;
  min-height: 30rem;
  height: auto;
  width: 90rem;
}
.modal.admin_st section header {
  background: #3e21d9;
  border-radius: 1.5rem 1.5rem 0 0;
  padding: 0 3rem;
  height: 5.6rem;
}
.modal.admin_st section header h2 {
  font-size: 1.8rem;
}
.modal.admin_st section header h2 span {
  font-size: 1.4rem;
  font-weight: 400;
  margin-left: 1.5rem;
}
.modal.admin_st section .modal_body {
  background: #fff;
  border-radius: 1.5rem;
  width: 100%;
  height: calc(100% - 56px);
  padding: 3rem;
  position: relative;
}
.modal.admin_st section .modal_body h4 {
  text-align: left;
  font-size: 1.6rem;
  margin-bottom: 1rem;
}
.modal.admin_st section .modal_body h5 {
  font-size: 1.6rem;
  font-weight: 400;
  margin-bottom: 1rem;
}
.modal.admin_st section .modal_body table {
  margin-bottom: 2rem;
}
.modal.admin_st section .modal_body table th {
  border-bottom: 1px solid #dedede;
  vertical-align: middle;
  background-color: #f7f9fa;
}
.modal.admin_st section .modal_body table th,
.modal.admin_st section .modal_body table td {
  padding: 3px 7px;
  font-size: 1.4rem;
  height: 4.1rem;
}
.modal.admin_st section .modal_body table tbody th {
  border-right: 1px solid #dedede;
  border-left: 1px solid #dedede;
}
.modal.admin_st section .modal_body table tbody th:nth-child(1) {
  border-left: 0;
}
.modal.admin_st section .modal_body table tbody td {
  overflow: initial;
  text-overflow: initial;
  white-space: initial;
  word-break: break-all;
}
.modal.admin_st section .modal_body footer {
  margin-top: 2rem;
  gap: 1rem;
}
.modal.w_360 section {
  width: 36rem;
}
.modal.w_1000 section {
  width: 100rem;
}
.modal.w_1200 section {
  width: 120rem;
}
.modal.video_modal section {
  max-width: 1080px;
  width: 97%;
  height: auto;
  min-height: initial;
  position: relative;
  background: #000;
}
.modal.video_modal section header {
  background: #000;
  border-top: 0;
}
.modal.video_modal section header button {
  background: url(../../../assets/images/pop_close_16_w.svg) no-repeat center;
}
.modal.video_modal section .modal_body {
  padding: 0;
  background: #000;
  width: 100%;
  height: auto;
  max-width: 1080px;
  height: auto;
}
.modal.video_modal section .modal_body iframe {
  width: 100%;
  height: 100%;
  aspect-ratio: 16/9;
}
@media (max-width: 959px) {
  .modal.video_modal section {
    width: 100%;
    left: 0;
    transform: translate(0, -50%);
  }
}
.modal .today_close {
  width: 100%;
  height: 5rem;
  line-height: 5rem;
  background: #000;
  padding: 0 2rem;
}
.modal .today_close label {
  font-size: 1.6rem;
  font-weight: 600;
  color: #fff;
  padding-left: 2.5rem;
}
@media (max-width: 600px) {
  .modal .today_close label {
    font-size: 1.4rem;
  }
}
@media (max-width: 959px) {
  .modal {
    width: 100vw;
    max-height: 100vh;
  }
  .modal section {
    width: calc(100% - 30px);
  }
  .modal section header {
    height: 4.8rem;
    padding: 0 1.6rem;
  }
  .modal section header h2 {
    font-size: 1.6rem;
  }
  .modal section .modal_body {
    padding: 2.4rem 1.6rem;
  }
  .modal section .modal_body h3 {
    font-size: 1.6rem;
    font-weight: 600;
    margin-top: 2rem !important;
  }
  .modal section .modal_body main {
    max-height: calc(100vh - 300px);
    overflow-y: auto;
  }
  .modal section .modal_body footer {
    padding: 2.4rem 0 1.4rem;
  }
  .modal section .modal_body footer button {
    height: 4.5rem;
    font-size: 1.6rem;
  }
  .modal section .modal_body footer div {
    width: 100%;
  }
  .modal section .modal_body footer div button {
    flex: 1;
  }
}
@media (max-width: 425px) {
  .modal section .modal_body main {
    max-height: calc(100vh - 250px);
    overflow-y: auto;
  }
}