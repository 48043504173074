.radio_box input[type=radio] {
  display: none;
}
.radio_box label {
  color: #999999;
  font-weight: 500;
  padding-left: 2.1rem;
  background: url(../../../assets/images/radio_n.svg) no-repeat center left;
  cursor: pointer;
}
.radio_box input[type=radio]:checked ~ label {
  color: #000;
  background: url(../../../assets/images/radio_s.svg) no-repeat center left;
}
.radio_box.official_st label {
  background: url(../../../assets/images/radio_n.svg) no-repeat center left;
}
.radio_box.official_st input[type=radio]:checked ~ label {
  background: url(../../../assets/images/radio_btn_red_s.svg) no-repeat center left;
}